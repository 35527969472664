<template>
    <el-dialog v-model="localVisible" title="下载中">
        <el-progress :percentage="progress"></el-progress>
    </el-dialog>
</template>

<script setup>
    import { ref, watchEffect,watch, defineProps,defineEmits} from 'vue';
    import { ElDialog, ElProgress,ElMessageBox} from 'element-plus';
    import {get_endpoint} from '@/plugins/axios.js';
    const props = defineProps({
        dialogVisible: Boolean,
        downloadUrl: String,
        fileName: String,
        server: {
            type: String,
            default: '数据服务器'
        }
    });


    const emit = defineEmits(['update:dialogVisible']);
    const progress = ref(0);
    const localVisible = ref(props.dialogVisible);
    watch(() => props.dialogVisible, (newVal) => {
      localVisible.value = newVal;
    });
    watch(localVisible, (newVal) => {
      emit('update:dialogVisible', newVal);
    });


    watchEffect(async () => {
        if (props.dialogVisible && props.downloadUrl) {
            progress.value = 0; // 重置进度条
            await downloadFile(props.downloadUrl, props.fileName, props.server);
            emit('update:dialogVisible', false); // 关闭对话框
        }
    });

    async function downloadFile(downloadurl, fileName, server='数据服务器') {
        try {
            const base = get_endpoint(server)
            const response = await fetch(base + downloadurl);

            // 检查响应状态
            if (!response.ok) {
                throw new Error('网络响应错误: ' + response.statusText);
            }

            const reader = response.body.getReader();
            const contentLength = +response.headers.get('Content-Length');
            let receivedLength = 0;

            // 创建一个stream来构建blob
            const stream = new ReadableStream({
                async start(controller) {
                    let over = true;
                    while (over) {
                        const { done, value } = await reader.read();
                        if (done) {
                            over = false;
                            break;
                        }
                        receivedLength += value.length;
                        // 假设progress是响应式的，更新其值以反映下载进度
                        progress.value = (receivedLength / contentLength) * 100;
                        controller.enqueue(value);
                    }
                    controller.close();
                    reader.releaseLock();
                }
            });

            const blob = await new Response(stream).blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName || 'download';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('下载失败:', error);
            ElMessageBox.alert('下载失败: ' + error.message, '下载错误', { type: 'error' });

            // 如果你的项目使用Element UI，可以替换为ElMessageBox.alert等更复杂的UI组件
        }
    }

</script>
