<template>
  <div class="layout-container">
    <div class="card-content">
      <TreeViewObj :data="endData"/>
    </div>
    <p v-if="data['stage_details']">2409v2</p>
    <div v-if="data['stage_details']" :id="chartId" style='width:100%;height:200px;background-color:white'>
    </div>
    <p v-if="data['stage_detail1']">2409v1</p>

    <div v-if="data['stage_detail1']" :id="chartId1" style='width:100%;height:200px;background-color:white'>2409v1</div>
    <p v-if="data['stage_detail2']">2401</p>

    <div v-if="data['stage_detail2']" :id="chartId2" style='width:100%;height:200px;background-color:white'>2401</div>
    <div v-if="data['stage_detail3']" :id="chartId3" style='width:100%;height:200px;background-color:white'></div>
    <div v-if="data['artifact_series'] || data['artifact_events']" :id="ArtChartId"
         style='width:100%;height:100px;background-color:white'></div>
    <div v-if="data['hrv_rmssd']|| data['hrv_rmssd_mean']" :id="HrvChartId"
         style='width:100%;height:200px;background-color:white'></div>
    <div v-if="data['resp_series'] && data['avg_resp']" :id="RrChartId"
         style='width:100%;height:200px;background-color:white'></div>
    <div v-if="data['ahi_series']||data['ahi_events']" :id="AhChartId"
         style='width:100%;height:200px;background-color:white'></div>
    <div v-if="data['bpm_avg']&& data['bpm_series']" :id="HrChartId"
         style='width:100%;height:200px;background-color:white'></div>
    <div v-if="data['spo2_data'] &&data['stage_details']" :id="spo2ChartId"
         style='width:100%;height:200px;background-color:white'></div>
    <div v-if="data['thi_series']" :id="EnvChartId" style='width:100%;height:200px;background-color:white'></div>
    <div v-if="data['spo2_data_local']" :id="spo21ChartId" style='width:100%;height:200px;background-color:white'></div>
    <div v-if="data['hours']" :id="dayHourChartId" style='width:100%;height:200px;background-color:white'></div>

  </div>
</template>

<script setup>
import { defineProps, onMounted, ref, watch } from 'vue';
import TreeViewObj from '@/common/TreeViewObj.vue';
// import {setStageChartOption} from '/public/js/index_sleep'
import * as echarts from 'echarts';

import { setStageChartOption } from '@/plugins/chart/stage_chart'
import { setSpO2ChartOption } from '@/plugins/chart/spo2_chart';
import { setArtChartOption } from '@/plugins/chart/day_art_chart';
import { setArt2ChartOption } from '@/plugins/chart/day_art_chart2';
import { setDayEnvChartOption } from '@/plugins/chart/day_env_chart';
import { setDayHRVChartOption } from '@/plugins/chart/day_hrv_chart';
import { setDayRRChartOption } from '@/plugins/chart/day_rr_chart';
import { setStageAHChartOption } from '@/plugins/chart/stage_ah_chart';
import { setDayHRChartOption } from '@/plugins/chart/day_hr_chart';
import { setStageAH2ChartOption } from '@/plugins/chart/stage_ah_chart2';
import {setDayHoursChartOption} from "@/plugins/chart/day_12periods_chart";

const art_data = {}
const env_data = {}
const hrv_data = {}
const rr_data = {}
const hr_data = {}
const spo21_data = {}
const chartId = ref(`stage_chart_${Math.random().toString(36).substr(2, 9)}`);
const chartId1 = ref(`stage_chart_${Math.random().toString(36).substr(2, 9)}`);
const chartId2 = ref(`stage_chart_${Math.random().toString(36).substr(2, 9)}`);
const chartId3 = ref(`stage_chart_${Math.random().toString(36).substr(2, 9)}`);
const ArtChartId = ref(`art_chart_${Math.random().toString(36).substr(2, 9)}`);
const EnvChartId = ref(`env_chart_${Math.random().toString(36).substr(2, 9)}`);
const HrvChartId = ref(`hrv_chart_${Math.random().toString(36).substr(2, 9)}`);
const RrChartId = ref(`rr_chart_${Math.random().toString(36).substr(2, 9)}`);
const AhChartId = ref(`ah_chart_${Math.random().toString(36).substr(2, 9)}`);
const HrChartId = ref(`hr_chart_${Math.random().toString(36).substr(2, 9)}`);
const spo21ChartId = ref(`spo21_chart_${Math.random().toString(36).substr(2, 9)}`);

const spo2ChartId = ref(`spo2_chart_${Math.random().toString(36).substr(2, 9)}`);
const dayHourChartId = ref(`day_hour_chart_${Math.random().toString(36).substr(2, 9)}`);

const props = defineProps({
  data: Object
});
const stage_data = {}
const spo2_data = {}
const data = ref(props.data)

function doRender () {
  const canvas = document.getElementById(chartId.value)

  if (canvas) {
    stage_data.start = data.value.starttime; // starttime 字段
    stage_data.end = data.value.endtime; // endtime 字段
    stage_data.stages = data.value.stage_details// stage_details
    stage_data.nointention = (data.value.intention_middle && data.value.intention_middle.length > 0) ? data.value.intention_middle : null
    stage_data.leavebed = (data.value.leavebed && data.value.leavebed.length > 0) ? data.value.leavebed : null
    const chart = echarts.init(canvas);
    setStageChartOption(chart, stage_data);
    return chart;
  }
}

function doRender1 () {
  const canvas = document.getElementById(chartId1.value)
  if (canvas) {
    stage_data.start = data.value.starttime; // starttime 字段
    stage_data.end = data.value.endtime; // endtime 字段
    stage_data.stages = data.value.stage_detail1// stage_details
    stage_data.nointention = (data.value.intention_middle && data.value.intention_middle.length > 0) ? data.value.intention_middle : null
    stage_data.leavebed = (data.value.leavebed && data.value.leavebed.length > 0) ? data.value.leavebed : null
    const chart = echarts.init(canvas);
    setStageChartOption(chart, stage_data);
    return chart;
  }
}

function doRender2 () {
  const canvas = document.getElementById(chartId2.value)

  if (canvas) {
    stage_data.start = data.value.starttime; // starttime 字段
    stage_data.end = data.value.endtime; // endtime 字段
    stage_data.stages = data.value.stage_detail2// stage_details
    stage_data.nointention = (data.value.intention_middle && data.value.intention_middle.length > 0) ? data.value.intention_middle : null
    stage_data.leavebed = (data.value.leavebed && data.value.leavebed.length > 0) ? data.value.leavebed : null
    const chart = echarts.init(canvas);
    setStageChartOption(chart, stage_data);
    return chart;
  }
}

function doRender3 () {
  const canvas = document.getElementById(chartId3.value)

  if (canvas) {
    stage_data.start = data.value.starttime; // starttime 字段
    stage_data.end = data.value.endtime; // endtime 字段
    stage_data.stages = data.value.stage_detail3// stage_details
    stage_data.nointention = (data.value.intention_middle && data.value.intention_middle.length > 0) ? data.value.intention_middle : null
    stage_data.leavebed = (data.value.leavebed && data.value.leavebed.length > 0) ? data.value.leavebed : null
    const chart = echarts.init(canvas);
    setStageChartOption(chart, stage_data);
    return chart;
  }
}

function doArtChart () {
  const art_canvas = document.getElementById(ArtChartId.value)
  if (art_canvas) {
    const art_chart = echarts.init(art_canvas);
    art_data.start = data.value.starttime; // starttime 字段
    art_data.end = data.value.endtime; // endtime 字段
    art_data.leavebed = (data.value.leavebed && data.value.leavebed.length > 0) ? data.value.leavebed : null

    if (data.value.artifact_series) {
      art_data.segments = data.value.artifact_series
      art_data.stages = data.value.stage_details// stage_details
      setArt2ChartOption(art_chart, art_data)
    } else if (data.value.artifact_events) {
      console.log(data.value.artifact_events)
      art_data.segments = data.value.artifact_events
      setArtChartOption(art_chart, art_data);
    }
    return art_chart;
  }
}

// 环境
function doEnvChart () {
  const env_canvas = document.getElementById(EnvChartId.value)

  if (env_canvas

  ) {
    env_data.start = data.value.starttime; // starttime 字段
    env_data.end = data.value.endtime; // endtime 字段
    // env_data.intention = data.value['sleep_intention']// stage_details
    env_data.nointention = data.value.intention_middle// stage_details
    env_data.leavebed = data.value.leavebed
    // env_data.leave = data.value['sleep_leave']
    env_data.segments = data.value.thi_series
    const env_chart = echarts.init(env_canvas);
    setDayEnvChartOption(env_chart, env_data);
    return env_chart;
  }
}

function doSpo2Chart () {
  const spo2_canvas = document.getElementById(spo2ChartId.value)

  if (spo2_canvas) {
    spo2_data.start = data.value.starttime; // starttime 字段
    spo2_data.end = data.value.endtime; // endtime 字段
    spo2_data.stage = data.value.stage_details// stage_details
    spo2_data.spo2 = data.value.spo2_data
    const spo2_chart = echarts.init(spo2_canvas);
    setSpO2ChartOption(spo2_chart, spo2_data);
    return spo2_chart;
  }
}

function doSpo21Chart () {
  const spo21_canvas = document.getElementById(spo21ChartId.value)
  if (spo21_canvas) {
    spo21_data.start = data.value.starttime; // starttime 字段
    spo21_data.end = data.value.endtime; // endtime 字段
    spo21_data.stage = data.value.stage_details// stage_details
    spo21_data.spo2 = data.value.spo2_data_local

    const spo21_chart = echarts.init(spo21_canvas);
    setSpO2ChartOption(spo21_chart, spo21_data);
    return spo21_chart;
  }
}

function doHrvChart () {
  const hrv_canvas = document.getElementById(HrvChartId.value)

  if (hrv_canvas) {
    hrv_data.start = data.value.starttime; // starttime 字段
    hrv_data.end = data.value.endtime; // endtime 字段
    hrv_data.nointention = data.value.intention_middle
    hrv_data.leavebed = data.value.leavebed
    // hrv_data.intention = data.value['sleep_intention']
    // hrv_data.leave = data.value['sleep_leave']
    hrv_data.segments = data.value.hrv_rmssd
    hrv_data.avg = data.value.hrv_rmssd_mean
    const hrv_chart = echarts.init(hrv_canvas);
    setDayHRVChartOption(hrv_chart, hrv_data);
    return hrv_chart;
  }
}

function doRrChart () {
  const rr_canvas = document.getElementById(RrChartId.value)

  if (rr_canvas) {
    rr_data.start = data.value.starttime; // starttime 字段
    rr_data.end = data.value.endtime; // endtime 字段
    // rr_data.intention = data.value['sleep_intention']// stage_details
    rr_data.nointention = data.value.intention_middle// stage_details
    // rr_data.leave = data.value['sleep_leave']
    rr_data.leavebed = data.value.leavebed
    rr_data.segments = data.value.resp_series
    rr_data.avg = data.value.avg_resp
    const rr_chart = echarts.init(rr_canvas);
    setDayRRChartOption(rr_chart, rr_data);
    return rr_chart;
  }
}

// 呼吸
function doAhChart () {
  const ah_canvas = document.getElementById(AhChartId.value)

  if (ah_canvas) {
    const ah_data = {}
    const ah_chart = echarts.init(ah_canvas);
    ah_data.nointention = (data.value.intention_middle && data.value.intention_middle.length > 0) ? data.value.intention_middle : null

    ah_data.start = data.value.starttime; // starttime 字段
    ah_data.end = data.value.endtime; // endtime 字段
    ah_data.leavebed = (data.value.leavebed && data.value.leavebed.length > 0) ? data.value.leavebed : null
    ah_data.stages = data.value.stage_details

    if (data.value.ahi_series) {
      ah_data.ah = data.value.ahi_series
      setStageAH2ChartOption(ah_chart, ah_data);
    } else {
      ah_data.ah = data.value.ahi_events
      setStageAHChartOption(ah_chart, ah_data);
    }
    return ah_chart;
  }
}

// 心率
function doHrChart () {
  const hr_canvas = document.getElementById(HrChartId.value)

  if (hr_canvas) {
    hr_data.start = data.value.starttime; // starttime 字段
    hr_data.end = data.value.endtime; // endtime 字段
    hr_data.nointention = (data.value.intention_middle && data.value.intention_middle.length > 0) ? data.value.intention_middle : null
    hr_data.leavebed = data.value.leavebed
    hr_data.avg = data.value.bpm_avg
    hr_data.segments = data.value.bpm_series
    const hr_chart = echarts.init(hr_canvas);
    setDayHRChartOption(hr_chart, hr_data);
    return hr_chart;
  }
}
function doDayHourChart(){
    const dayh_canvas = document.getElementById(dayHourChartId.value)

    if (dayh_canvas) {
      const hours_data = {
        start: data.value.starttime,
        hours: data.value.hours,
        chours: data.value.continuous_hours,

      };
      const hr_chart = echarts.init(dayh_canvas);

      setDayHoursChartOption(hr_chart, hours_data);
    }


}

const endData = ref({})
const keyMap = {
  reporttime: '报告开始计算时间',
  startc: '数据开始时间',
  endc: '数据结束时间',
  dur: '数据总时长',
  snore_index: '打鼾指数',
  snore_index_onback: '仰卧打鼾指数',
  snore_index_onside: '侧卧打鼾指数',
  posion_onback_time: '仰卧时长',
  posion_onback_rate: '仰卧占比',
  posion_onside_time: '侧卧时长',
  posion_onside_rate: '侧卧占比',
  new_ranks: '质量评分（新）',
  sigtype_0: '无信号',
  sigtype_2: '正常信号',
  sigtype_4: '强体动信号',
  onbedlevel: '在床阈值',
  err_log: '错误信息',
  avg_temp: '平均温度',
  avg_humi: '平均湿度',
  avg_illu: '睡眠时平均光照',
  high_illu: '强光照睡眠',
  sleep_time: '有效睡眠总时间',
  ahi_count: '呼吸事件总数',
  ahi: '综合AHI',
  long_artifact: '体动次数',
  sri: 'SRI',
  sri_old: 'SRI_old',
  sri_new: 'SRI_测试版',
  quality: '睡眠质量分',
  qualitylogs: '睡眠质量细则',
  hrv_rmssd_mean: 'hrv_rmssd',
  hrv_morning: 'hrv_morning',
  hrv_evening: 'hrv_evening',
  hrv_delta: 'hrv_delta',
  hrv_ratio_mean: 'hrv_ratio',
  sleep_range: '睡眠区间调试信息',
  push_message: 'push消息',
  sleep_intention: '睡眠意图',
  sleep_leave: '睡眠意图离床',
  sleep_middle: '中途无意图',
  starttime: '开始时间',
  endtime: '结束时间',
  leavebed: 'leavebed',
  gender_old:'gender_old',
gender:'gender'

};

function updateEndData () {
  const mappedData = {};
  const otherData = {};

  // 分配映射的数据
  Object.keys(keyMap).forEach(key => {
    if (key in data.value) {
      mappedData[keyMap[key]] = data.value[key];
    }
  });

  // 收集未映射的数据
  Object.keys(data.value).forEach(key => {
    if (!(key in keyMap)) {
      otherData[key] = data.value[key];
    }
  });

  // 添加'其他'分类
  mappedData['其他'] = otherData;

  endData.value = mappedData;
}

onMounted(() => {
  updateEndData()
  doRender()
  doRender1()
  doRender2()
  doRender3()
  doAhChart()
  doArtChart()
  doEnvChart()
  doHrvChart()
  doRrChart()
  doHrChart()
  doSpo2Chart()
  doSpo21Chart()
  doDayHourChart()
});
watch(() => props.data, (newVal) => {
  // 当数据变化时，重新渲染图表
  data.value = newVal

  updateEndData()
  doRender()
  doRender1()
  doRender2()
  doRender3()
  doAhChart()
  doArtChart()
  doEnvChart()
  doHrvChart()
  doRrChart()
  doHrChart()

  doSpo2Chart()
  doSpo21Chart()
  doDayHourChart()
}, { deep: true });
</script>

<style scoped>
.layout-container {
  display: flex;
  flex-direction: column; /* 子元素垂直排列 */
  justify-content: space-between; /* 子元素之间的间隔均匀分布 */
  align-items: stretch; /* 子元素拉伸以填充交叉轴 */
  height: 100%; /* 根据需要设置高度 */
}

.card-content {
  flex: 1; /* 分配剩余空间 */
  overflow: auto; /* 添加滚动条 */
}
</style>
