<template>
  <el-card shadow="always">
    <div style="position: relative;">

      <div v-show="current_detail==''">
        <CustomFilter item="device" @filter-changed="handleFilterChange"/>
        <div style="margin-bottom: 20px;margin-top: 20px">
          <UploadExcel
              :http-request="customUpload"
              :on-success="handleUploadSuccess"
              :on-error="handleUploadError"
              :uploadPath="uploadPath"
              :serverType="数据服务器"
          />
        </div>
        <el-table :data="rows" table-layout="auto">
          <el-table-column type="selection" width="30"/>

          <el-table-column prop="device" label="编号" resizable sortable :sort-method="sortNames">
            <template #default="scope">
                        <span v-if="scope.row['设备和网络']['在线']" :style="{ fontWeight: 'bold' }">
                            {{ scope.row.device }} | 在线
                        </span>
              <span v-else>
                            {{ scope.row.device }}
                        </span>
              <span>
  <el-tag v-if="scope.row['report_status']['active_task'] !== 0 && !!scope.row['report_status']['active_task']"
          type="success"
          size="small">正在运行任务：{{ scope.row['report_status']['active_task'] }}</el-tag>
</span>
              <el-tooltip placement="right" effect="light" trigger="click">
                <template #content>
                  <div class="tooltip-content">
                    <TreeViewObj :data="scope.row"></TreeViewObj>
                  </div>
                </template>
                <el-tag type="info" size="small" effect="dark">动态</el-tag>

              </el-tooltip>
              <el-tag @click="navigateToDeviceReport(scope.row.device)">详情</el-tag>
              <!-- 当workstate不是“可用”时显示标签，否则不显示 -->
              <el-tag v-if="scope.row.deviceinfo.workstate !== '可用'" size="small">
                {{ scope.row.deviceinfo.workstate }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="基础信息">
            <template #default="scope">
              <DeviceSummary :info="scope.row"/>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="设备和网络">
            <template #default="scope">
              <DeviceTag :info="{device:scope.row['taginfo']['设备和网络']}"/>
            </template>
          </el-table-column>
          <el-table-column prop="rules" label="数据">
            <template #default="scope">
              <DeviceTag :info="{device:scope.row['taginfo']['数据']}"/>
            </template>
          </el-table-column>
          <el-table-column prop="rules" label="报告">
            <template #default="scope">
              <DeviceTag
                  :info="{device:scope.row['taginfo']['报告']}"/>
            </template>
          </el-table-column>
          <el-table-column prop="rules" label="规则">
            <template #default="scope">
              <DeviceTag :info="{info:scope.row['规则']}"/>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="250">
            <template #default="scope">
              <!-- 当未加入收藏夹时显示加入收藏夹按钮 -->
              <el-button
                  class="favorite-button add-to-favorites"
                  size="small"
                  type="success"
                  style="width: 70px;"
                  v-if="!isFavorite(scope.row.device)"
                  @click="addToFavorites(scope.row)"
              >加入收藏夹
              </el-button>
              <!-- 当已加入收藏夹时显示移出收藏夹按钮 -->
              <el-button
                  class="favorite-button remove-from-favorites"
                  size="small"
                  type="danger"
                  style="width: 70px;"
                  v-else
                  @click="removeFromFavorites(scope.row)"
              >移出收藏夹
              </el-button>
              <el-button
                  type="primary"
                  size="small"
                  style="width: 60px;"
                  @click="openEditDialog(scope.row)">修改信息
              </el-button>
              <el-button
                  type="primary"
                  size="small"
                  style="width: 60px;"
                  @click="toggleDrawer(scope.row)">标签管理
              </el-button>

            </template>
          </el-table-column>
        </el-table>
        <DeviceEditDialog v-model="editDialogVisible" :editDialogVisible="editDialogVisible" :data="deviceInfo"
                          @update:editDialogVisible="handleEditDialogClose"/>
        <el-pagination v-model:current-page="currentPage"
                       :page-size="10"
                       layout="total, prev, pager, next"
                       :total="total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange">
        </el-pagination>
      </div>
      <div v-if="current_detail!=''">
        <DeviceReportView v-model:device="current_detail" :selections="rows"/>
      </div>
      <!-- 标签管理按钮 -->
      <!-- 标签管理按钮 -->

      <el-drawer direction="rtl" v-model="drawerVisible" :size="sideBarSize">
        <div style="padding: 20px;">
          <h2 style="text-align: center; margin-bottom: 20px; font-size: 24px;">
            标签管理页面 -- {{ dialog_data.device }}
          </h2>

          <template v-if="dialog_data.taginfo && Object.keys(dialog_data.taginfo).length">
            <template v-for="(tags, category) in dialog_data.taginfo" :key="category">
              <div v-if="Object.keys(tags).length > 0" style="margin-bottom: 30px;">
                <h3 style="background-color: #f0f0f0; padding: 10px; border-radius: 5px; font-size: 20px; width: 100%;">
                  {{ category }}</h3>

                <div style="width: 100%; margin-top: 20px;">
                  <el-table :data="Object.entries(tags).map(([tag_n, tags_]) => ({ tag_n, tags_ }))"
                            style="width: 100%;">
                    <el-table-column prop="tag_n" label="标签分类" width="150">
                      <template #default="scope">
                        <h4>{{ scope.row.tag_n }}</h4>

                      </template>
                    </el-table-column>

                    <el-table-column label="标签名" width="150px">
                      <template #default="scope">
                        <div v-for="(tag, index) in scope.row.tags_" :key="index" style="margin-bottom: 10px;">
                          <template v-if="tag && category === '待确认'">
                            <template v-if="tag.modify !== '1' &&tag.state==='0'">
                              {{ tag.tag_name }}
                            </template>
                          </template>
                          <template v-else-if="tag &&tag.state==='0'">
                            <span>{{ tag.tag_name }}</span><span v-if="tag.expiry_time==='0'">(永久)</span>
                          </template>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="标签信息" width="250px">
                      <template #default="scope">
                        <div v-for="(tag, index) in scope.row.tags_" :key="index" style="margin-bottom: 10px;">
                          <template v-if="tag &&tag.state==='0'">
                            <template v-if="category === '待确认'">
                              <template v-if="tag.modify !== '1'">
                                {{ tag.info }}
                              </template>
                            </template>
                            <template v-else>
                              {{ tag.info }}
                            </template>
                          </template>
                        </div>
                      </template>
                    </el-table-column>

                    <el-table-column label="操作" width="">
                      <template #default="scope">
                        <div v-for="(tag, index) in scope.row.tags_" :key="index" style="margin-bottom: 5px;">
                          <div v-if="category === '待确认'" style="display: flex; gap: 10px;">

                            <el-button v-if="tag.modify!=='1' &&tag.state==='0'" type="primary"
                                       @click="modifyTag(dialog_data.device, scope.row.tag_n, index, tag, 'confirm')">确认
                            </el-button>
                            <el-button v-if="tag.modify!=='1'&&tag.state==='0'" type="primary"
                                       @click="modifyTag(dialog_data.device, scope.row.tag_n, index, tag, 'confirm',0)">
                              永久确认
                            </el-button>
                            <el-button v-if="tag.modify!=='1'&&tag.state==='0'" type="warning"
                                       @click="modifyTag(dialog_data.device, scope.row.tag_n, index, tag, 'ignore')">忽略
                            </el-button>

                            <el-button v-if="tag.modify!=='1'&&tag.state==='0'" type="warning"
                                       @click="modifyTag(dialog_data.device, scope.row.tag_n, index, tag, 'ignore',0)">
                              永久忽略
                            </el-button>
                          </div>

                          <div v-else-if="category === '忽略'" style="display: flex; gap: 10px;">
                            <el-button type="warning"
                                       @click="modifyTag(dialog_data.device, scope.row.tag_n, index, tag, 'reduction')">
                              还原
                            </el-button>
                          </div>

                          <div v-else style="display: flex; gap: 10px;">
                            <el-button type="warning"
                                       @click="modifyTag(dialog_data.device, scope.row.tag_n, index, tag, 'cancel')">取消
                            </el-button>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </template>
          </template>
        </div>
      </el-drawer>
    </div>
  </el-card>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, onUnmounted, computed, watch } from 'vue';
import { createAxiosInstance, wdb_get } from '@/plugins/axios.js'; // 获取组件实例的代理对象
import TreeViewObj from '@/common/TreeViewObj.vue'; // 确保路径正确
import { useStore } from 'vuex'
import CustomFilter from '@/common/CustomFilter.vue';
import DeviceSummary from './DeviceSummary.vue';
import DeviceTag from './DeviceTag.vue';

import DeviceReportView from './DeviceReportView.vue'
import DeviceEditDialog from './DeviceEditDialog.vue';
import UploadExcel from '@/components/UploadExcel.vue';
import { useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';
import { eventBus, sendCmd } from '@/plugins/websocket';
const { proxy } = getCurrentInstance();
const current_detail = ref('');

// 定义响应式数据
const currentPage = ref(1);
const pageSize = ref(10);
const total = ref(100);
const rows = ref([]);
let intervalId = null; // 用于存储定时器ID
let encodedFilterStr = '';
const uploadPath = '/device_upload/'
// 获取设备信息，修改设备信息
const editDialogVisible = ref(false);
const deviceInfo = ref({});

const route = useRoute();
const dialog_data = ref({})

// const userRol = "用户" // 获取用户的组织信息

const openEditDialog = async (rowData) => {
  const axiosInstance = createAxiosInstance('数据服务器');

  const response = await axiosInstance(`/deviceinfo/${rowData.device}/`);
  deviceInfo.value = { ...response.data.data, device: rowData.device };
  editDialogVisible.value = true;
};

function customUpload (file) {
  const formData = new FormData();
  formData.append('file', file);
  const axiosInstance = createAxiosInstance('数据服务器');
  return axiosInstance.post(uploadPath, formData);
}
function handleUploadSuccess (response) {
  console.log(response)
  ElMessage.success('上传成功');
  // fetchData();
}
function handleUploadError (error) {
  console.log(error)
}
function handleEditDialogClose (visible) {
  console.log(visible)
  editDialogVisible.value = false;
}

// 收藏夹模块
const store = useStore()
const user = computed(() => store.state.user)
const favorites_device = ref([])

//  检查设备是否已加入收藏夹
const isFavorite = (deviceId) => {
  if (user.value && favorites_device.value && Array.isArray(favorites_device.value)) {
    return favorites_device.value.includes(deviceId);
  } else {
    return false; // 如果 user.value 为 null 或者 favorites 不是数组，则返回 false
  }
};
// 加入收藏夹
const addToFavorites = async (row) => {
  try {
    const axiosInstance = createAxiosInstance('数据服务器');

    const response = await axiosInstance.get('/favorites/add/device/?device=' + row.device);
    console.log(response)
    favorites_device.value.push(row.device); // 更新本地收藏夹列表
  } catch (error) {
    console.error('加入收藏夹失败:', error);
  }
};

// 移出收藏夹
const removeFromFavorites = async (row) => {
  try {
    const axiosInstance = createAxiosInstance('数据服务器');

    const response = await axiosInstance.get('/favorites/remove/device/?device=' + row.device);

    console.log(response.data);
    favorites_device.value = favorites_device.value.filter(deviceId => deviceId !== row.device); // 更新本地收藏夹列表
  } catch (error) {
    console.error('移出收藏夹失败:', error);
  }
};

// 从云端获取数据的方法
/* const fetchData = async () => {
  if (fetching) return;
  if (current_detail.value !== '') return
  try {
    fetching = 1
    const axiosInstance = createAxiosInstance('数据服务器');

    const response = await axiosInstance.get('/device/?filter=' + encodedFilterStr + '&page=' + currentPage.value)
    fetching = 0;
    processResponse(response);
  } catch (error) {
    console.error('获取数据失败:', error);
    fetching = 0;
  }
};
*/

// 从云端获取数据的方法
const fetchData = async () => {
  if (current_detail.value !== '') return
  try {
    sendCmd('listdevice', { filter: encodedFilterStr, page: currentPage.value }).then(() => {
      console.log('send listdevice')
    });
  } catch (error) {
    console.error('获取数据失败:', error);
  }
};

function navigateToDeviceReport (deviceid) {
  current_detail.value = deviceid
}

// 处理响应数据
const processResponse = (response) => {
  try {
    const nodes = response.message.data[0];
    const count = response.message.data[1];

    rows.value = nodes;
    total.value = count;
  } catch (error) {
    console.error('请求数据时发生错误:', error);
  }
};

// 处理过滤器变化
const handleFilterChange = async (filterStr) => {
  encodedFilterStr = filterStr;
  fetchData();
  // store.commit('setFilter', filterStr);
};

// 处理分页大小变化
const handleSizeChange = (newSize) => {
  pageSize.value = newSize;
  fetchData();
};

// 处理当前页变化
const handleCurrentChange = (val) => {
  currentPage.value = val;
  fetchData();
};

// 排序方法
const sortNames = (a, b) => {
  return (a.device + '').localeCompare(b.device + '');
};
onMounted(async () => {
  const result = await wdb_get('收藏夹', user.value.user, 'device');
  favorites_device.value = result !== null ? result : [];
  const jump_data = store.state.jumpData
  const path = jump_data.path
  if (route.path === path) {
    const filter_type = jump_data.filter_type
    const value = jump_data.value
    if (value) {
      if (filter_type === 'input') {
        encodedFilterStr = 'search*' + value + ' AND '
      } else {
        encodedFilterStr = 'deviceinfo.workstate=可用 AND ' + value + ' AND 近期在线'
      }
    }
  } else {
    store.commit('setJumpData', '');
  }
  fetchData()
  intervalId = setInterval(fetchData, 2000);
  eventBus.on('listdevice', (data) => {
    processResponse(data);
  });
});
onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId); // 清除定时器
  }
});

watch(() => store.state.jumpData, (newVal) => {
  if (newVal) {
    const filter_type = newVal.filter_type
    const value = newVal.value
    if (filter_type === 'input') {
      encodedFilterStr = 'search*' + value + ' AND '
    } else {
      encodedFilterStr = newVal.value
    }
  }
});

const drawerVisible = ref(false);
const sideBarSize = ' 70%';

const toggleDrawer = (row) => {
  dialog_data.value = row
  fetchTagData(row.device)
  drawerVisible.value = true
};
// const selectedTags = ref([]);
const fetchTagData = async (device) => {
  try {
    const axiosInstance = createAxiosInstance('数据服务器');

    const response = await axiosInstance.get(`/tags/?device=${device}`)

    dialog_data.value.taginfo = response.data
  } catch (error) {
    console.error('获取数据失败:', error);
  }
};
const modifyTag = async (device, category, index, tag, action, expired = null) => {
  try {
    const params = new URLSearchParams({
      category,
      index,
      tag,
      action,
      expired
    }).toString();

    // eslint-disable-next-line no-unused-vars
    const response = await proxy.$axios(`/tags/modify/${device}/?${params}`);
    fetchTagData(device)
    ElMessage.success('设备信息更新成功');
  } catch (error) {
    console.log(error);
    ElMessage.error('更新失败');
  }
};
</script>

<style>
/* 定义鼠标悬停在单元格上时的样式 */
.el-table .cell:hover {
  background-color: #f5f5f5; /* 鼠标悬停时的背景色 */
  cursor: pointer; /* 将鼠标光标变为手形 */
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.favorite-button {
  color: white;
  transition: all 0.3s ease-in-out;
}

.add-to-favorites {

  border-color: #67c23a;
}

.add-to-favorites:hover {

  background-color: #67c23a;
  border-color: #67c23a;
}

.remove-from-favorites {
  color: white;
  border-color: #f56c6c;
}

.remove-from-favorites:hover {
  color: #fff;
  background-color: #f56c6c;
  border-color: #f56c6c;
}
</style>
