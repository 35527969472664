<template>

    <div v-if="tooltipVisible"
         :style="dynamicStyles">
        {{ tooltipContent }}
    </div>
    <div v-if="selectedVisible"
         :style="selectedStyles">

    </div>
    <canvas ref="channelCanvas" :style="{ opacity: '0.2',width:'100%',height:'100%',cursor:'pointer' }"></canvas>

</template>

<script setup>
import { onMounted, ref, watchEffect, defineEmits, defineProps, onUnmounted } from 'vue';
const props = defineProps({
  channelData: Array,
  color: String,
  seltimelen: Number
});
const valueMap = [60, 720, 1440];
const marks = {
    0: '60分(高清)',
    1: '12时',
    2: '24时',
  };
const emit = defineEmits(['click-position']);
const channelCanvas = ref(null);
const tooltipVisible = ref(false);
const selectedVisible = ref(false);
const tooltipContent = ref('');
let rectWidth = 0;
let rectHeight = 0;
const totalItems = 1440+360;
const drawChannel = () => {
  const canvas = channelCanvas.value;
  if (!canvas) return;
  rectWidth = canvas.width / 60;
  rectHeight = canvas.height / (totalItems/60);
  const ctx = canvas.getContext('2d');
  ctx.fillStyle = 'black';

  ctx.fillRect(0, 0, canvas.width, canvas.height);
  if (props.channelData) {
    for (let i = 0; i < props.channelData.length; i++) {
      const value = props.channelData[i];

      const x = (value % 60) * rectWidth;
      const y = Math.floor(value / 60) * rectHeight;
      ctx.fillStyle = props.color;
      ctx.fillRect(x, y, rectWidth, rectHeight);
    }
  }
  //从第6行开始画一根白色的横线
  ctx.fillStyle = 'white';
  ctx.fillRect(0, 6*rectHeight, canvas.width, 1);
};

watchEffect(drawChannel);
const resizeObserver = new ResizeObserver(entries => {
  for (const entry of entries) {
    const { width, height } = entry.contentRect;
    const canvas = channelCanvas.value;
    if (canvas) {
      canvas.width = width;
      canvas.height = height;
      drawChannel();
    }
  }
});

onUnmounted(() => {
  const devicePanelElement = channelCanvas.value
  if (devicePanelElement) { resizeObserver.unobserve(devicePanelElement); }
});

onMounted(() => {
  const canvas = channelCanvas.value;
  if (canvas) {
    const style = window.getComputedStyle(canvas);
    canvas.width = parseInt(style.width, 10);
    canvas.height = parseInt(style.height, 10);
  }

  canvas.addEventListener('mousemove', (event) => {
    handleMouseMove(event, canvas);
  });
  canvas.addEventListener('mouseleave', () => {
    tooltipVisible.value = false;
  });
  canvas.addEventListener('click', () => {
    handleClick();
  });
  drawChannel();
  resizeObserver.observe(canvas);
});

const dynamicStyles = ref({}); // 响应式的样式对象
const selectedStyles = ref({})
let rowIndex = 0;
let columnIndex = 0;
function handleMouseMove (event, canvas) {
  const rowItemsCount = 60;
  const mousePos = getMousePos(canvas, event);
  const itemSize = canvas.width / rowItemsCount;
  const itemHeightSize = canvas.height / (totalItems / rowItemsCount)
  rowIndex = Math.floor(mousePos.y / itemHeightSize);
  columnIndex = Math.floor(mousePos.x / itemSize);
  const hour = rowIndex>=6?rowIndex-6:rowIndex+18;
  tooltipContent.value = `${hour}时${columnIndex}分 时长:${marks[props.seltimelen]}`;
  const x = 0;
  const y = rowIndex * itemHeightSize;
  const w = itemSize * rowItemsCount;
  const h = itemHeightSize*valueMap[props.seltimelen]/60;

  dynamicStyles.value = {
    left: `${x}px`,
    top: `${y}px`,
    width: `${w}px`,
    height: `${h}px`,
    backgroundColor: 'rgb(255, 255, 255, 0.3)',
    color: 'white',
    position: 'absolute',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    pointerEvents: 'none',
    fontSize: '0.8em' /* 或者使用具体的像素值，如 '12px' */
  }

  tooltipVisible.value = true;
}

function handleClick () {
  selectedVisible.value = true;
  selectedStyles.value = dynamicStyles.value
  emit('click-position', { rowIndex, columnIndex });
}

// 获取鼠标相对于Canvas的位置
function getMousePos (canvas, event) {
  const rect = canvas.getBoundingClientRect();
  return {
    x: event.clientX - rect.left,
    y: event.clientY - rect.top
  };
}
</script>

<style scoped>
    canvas {
        position: absolute;
        top: 0;
        left: 0;
    }

</style>
