<template>
  <el-dialog :title="formTitle"
             v-model="editDialogVisible"
             width="30%"
             @close="resetEditForm">

    <el-form :model="editForm"
             label-position="right"
             label-width="100px"
             :rules="rules">
      <el-form-item label="手机号："
                    prop="phoneNumber"
                    required v-if="props.formType === 'add'" :rules="rules.phoneNumber">
        <el-input v-model="editForm.phoneNumber" style="width: 350px;"></el-input>
      </el-form-item>
      <el-form-item label="用户名："
                    prop="username"  :rules="rules.username">
        <el-input v-model="editForm.username" style="width: 400px;"></el-input>
      </el-form-item>

      <el-form-item label="组："
                    prop="group">
        <el-select v-model="editForm.role"
                   placeholder="请选择"
                   style="width: 400px;">
          <el-option v-for="role in selects"
                     :key="role"
                     :label="role"
                     :value="role">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="机构："
                    prop="org">
        <el-input v-model="editForm.org" style="width: 400px;"></el-input>
      </el-form-item>
      <el-form-item label="OpenID："
                    prop="openid" v-if="props.formType === 'add'">
        <el-input v-model="editForm.oppenid" style="width: 350px;"></el-input>
      </el-form-item>
      <el-form-item label="干预枕"
                    prop="pillowID">
        <el-input v-model="editForm.pillowID" style="width: 350px;"></el-input>
      </el-form-item>
      <el-form-item label="监测垫"
                    prop="deviceID">
        <el-input v-model="editForm.deviceID" style="width: 350px;"></el-input>
      </el-form-item>
      <el-form-item label="附加信息："
                    prop="info">
        <el-input v-model="editForm.info" style="width: 400px;"></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary"
                 @click="submitFormEdit">确认
      </el-button>
    </template>
  </el-dialog>

</template>
<script setup>

import {ElButton, ElForm, ElFormItem, ElInput, ElMessage} from "element-plus";
import {defineEmits, defineProps, onMounted, ref, watch} from "vue";
import {createAxiosInstance, request_data} from "@/plugins/axios";

const selects = ref({})
const emit = defineEmits(['editDialogVisible']);
const editDialogVisible = ref(false);
const props = defineProps({
      data: Object,
      formType: String,
      formTitle: String,
      serverType:String

    }
);
const editForm = ref(props.data);
const path = ref('')

async function fetchSelects() {
  try {
    const data = await request_data(`/sysfilter/user/`,'数据服务器');
    selects.value = data.selects['info.role=角色'];
  } catch (error) {
    console.error('Error fetching filters:', error);
  }
}
let message; // 声明 params 变量

const submitFormEdit = async () => {
  try {
    let params; // 声明 params 变量

    if (props.formType === 'add') {
      path.value = '/user/add'
      message = '新增用户成功'
      params = new URLSearchParams({
        info: editForm.value.info || '',
        username: editForm.value.username || '',
        org: editForm.value.org || '',
        role: editForm.value.role || '',
        pillowID: editForm.value.pillowID || '',
        deviceID: editForm.value.deviceID || '',
        phoneNumber: editForm.value.phoneNumber || '',
        oppenid: editForm.value.oppenid || '',
        user: editForm.value.phoneNumber || '',

      }).toString();
    } else {
      path.value = '/updateuser/'+editForm.value.user
      message = '用户更新成功'

      params = new URLSearchParams({
        info: editForm.value.info || '',
        username: editForm.value.username || '',
        org: editForm.value.org || '',
        role: editForm.value.role || '',
        pillowID: editForm.value.pillowID || '',
        deviceID: editForm.value.deviceID || '',
      }).toString();
    }
    const axiosInstance = createAxiosInstance(props.serverType);

    const response = await axiosInstance.get(`${path.value}/?${params}`);
    console.log(response)
    editDialogVisible.value = false;
    ElMessage.success(message);
    emit('update:editDialogVisible', false);

  } catch (error) {
    console.log(error);
    ElMessage.error('更新失败');
  }
};
const rules = {
  phoneNumber: [
    {required: true, message: '用户/手机号不能为空', trigger: 'blur'}
  ],
  username: [
    {max: 60, message: '用户名长度不能超过60个字符', trigger: 'blur'}
  ],
  // 省略其他规则
};
const cancel = () => {
  emit('update:editDialogVisible', false);
};
const resetEditForm = () => {
  // 重置表单逻辑
};
onMounted(() => {
  fetchSelects();

});
watch(() => props.data, (newVal) => {
  editForm.value = newVal;
  console.log(newVal)
});
</script>
<style scoped>

</style>