<template>
  <el-card class="header-card">
    <div class="header-content">

      选择设备：
      <el-select v-model="selectedDevice" placeholder="选择设备" style="width:200px">
        <el-option v-for="item in props.selections"
                   :key="item.device"
                   :label="item.device"
                   :value="item.device">
        </el-option>
      </el-select>
      选择日期：
      <el-date-picker
        v-model="selectedReportDate"
        type="date"
        :key="datePickerKey"
        placeholder="选择报告日期"
        format="YYYY-MM-DD"
        value-format="YYYY-MM-DD"
        style="width:200px"
      >
      <template #default="cell">
        <div class="cell"  :class="{ 'current': cell.isCurrent, 'has-report': hasReport(cell) }">
          <span class="text">{{ cell.text }}</span>
        </div>
      </template>
    </el-date-picker>
      <el-button @click="handleClose" type="primary" v-if="sys!==1"> {{ sys }}返回列表页</el-button>
    </div>
  </el-card>

</template>

<script setup>
import { ref, onMounted, defineProps, watch, defineEmits } from 'vue';

import { request_data } from '@/plugins/axios.js';

const props = defineProps({
  selectedDevice: String,
  selectedReportDate: String,
  selections: Array,
  sys: String
});
const selectedDevice = ref(props.selectedDevice);
const selectedReportDate = ref(props.selectedReportDate);
const emit = defineEmits(['update:selectedDevice', 'update:selectedReportDate']);

const datePickerKey = ref(0);
const hasReport = ({ dayjs }) => {
  return reportDates.value.includes(dayjs.format('YYYY-MM-DD'))
}

const reportDates = ref([]); // 模拟的报告日期列表

// 监听selectedDevice和selectedReportDate的变化并通知父组件
watch(selectedDevice, () => {
  selectedReportDate.value = ''
  emit('update:selectedReportDate', selectedReportDate.value);
  emit('update:selectedDevice', selectedDevice.value);
  changeDate();
});
watch(selectedReportDate, (newValue) => {
  emit('update:selectedReportDate', newValue);
  emit('update:selectedDevice', selectedDevice.value);
});

// 定义 emit 函数，用于触发事件
const handleClose = () => {
  emit('update:selectedDevice', '')
};

async function changeDate () {
  reportDates.value = []
  selectedReportDate.value = ''
  const response = await request_data(`/device/${selectedDevice.value}/`, '计算服务器1');

  reportDates.value = response.nodes;
  if (response.nodes.length > 0) {
    selectedReportDate.value = response.nodes[0];
  }
  datePickerKey.value += 1;

  return response
}

onMounted(async () => {
  try {
    await changeDate();
  } catch (error) {
    console.error('获取数据失败', error);
  }
});

</script>

<style scoped>
.header-content {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.cell {
  height: 30px;
  padding: 3px 0;
  box-sizing: border-box;
}

.cell .text {
  width: 24px;
  height: 24px;
  display: block;
  margin: 0 auto;
  line-height: 24px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
}

.cell.current .text {
  background: #626aef;
  color: #fff;
}
.cell.has-report .text {
  background-color: #003366; /* 深蓝色背景 */
  color: #ffffff; /* 文字颜色为白色，提升可读性 */
}
</style>
