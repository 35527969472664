<template>
  <div shadow="always" style="width:200px">

    <div class="info">

      <div v-if="info.userinfo.user" class="user-jump-tag" @click="handleJump('user',info.userinfo.phoneNumber)">
        <strong>用户：</strong>{{ info.userinfo.username }} {{ maskPhoneNumber(info.userinfo.phoneNumber) }}
      </div>
      <div v-if="info.deviceinfo.orgbind" class="org-jump-tag">
        <strong>机构：</strong>{{ info.deviceinfo.orgbind }}
      </div>
      <div v-if="info.deviceinfo.level">
        <strong>等级：</strong>{{ info.deviceinfo.level }}
      </div>
      <div v-if="info.deviceinfo.psgid">
        <strong>平行设备：</strong>{{ info.deviceinfo.psgid }}
      </div>
      <div v-if="info.deviceinfo.info">
        <strong>备注：</strong>{{ info.deviceinfo.info }}
      </div>
<!--      <div v-if="info.userinfo.info">-->
<!--        <strong>其他：</strong>{{ info.userinfo.info }}-->
<!--      </div>-->
    </div>


  </div>
</template>

<script setup>
import {useRouter} from 'vue-router';

import {computed, defineProps} from 'vue';
import {useStore} from "vuex";


defineProps({
  info: {
    type: Object,
    required: true,
  }

});

function maskPhoneNumber(phoneNumber) {
  // 使用正则表达式替换中间四位数字为星号
  return phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
}


const router = useRouter();
const store = useStore();

// 使用 computed 来创建响应式的 user 引用
const user = computed(() => store.state.user)
const isAdmin = computed(() => user.value && user.value.role === "管理")

function handleJump(type, value) {
  if (isAdmin.value && type === 'user' && value) {
    store.commit('setJumpData', {'type': 'user', 'value': value, 'filter_type': 'input', 'path': '/user-management'})

    router.push(`/user-management`);
  } else if (type === 'org') {
    router.push(`/org-management`);
  }
}
</script>

<style scoped>
.card {
  display: flex;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgb(128, 128, 128);
  padding: 5px;
  border-radius: 8px;
  white-space: nowrap; /* 防止内部文本换行 */
  height: 100px; /* 固定高度 */
}

.dashboard, .info {
  display: flex;
  flex-direction: column;
  justify-content: center; /* 使内容在垂直方向上居中 */
  /* 对于.dashboard，你可能需要调整或移除padding，或者确保它不会影响到内部元素的垂直居中 */
}

.dashboard {
  background-color: black;
  color: lime;
  padding: 5px 20px; /* 增加左右padding使其看起来更宽 */
  border-radius: 8px;
  align-items: flex-start;
  box-shadow: revert 0 0 10px rgb(128, 128, 128); /* 内凹阴影效果 */
  margin-bottom: 8px;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center; /* 垂直居中 */

}


</style>
