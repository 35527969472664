<template>
  <div class="control-panel">
    <span>选择服务器：</span>

    <select v-model="serverType" class="server-select" @change="handleServerChange">
      <option value="计算服务器1">计算服务器1</option>
      <option value="计算服务器2">计算服务器2</option>
    </select>

    <span>选择日期：</span>
    <el-date-picker
        v-model="selectedDate"
        type="date"
        placeholder="选择日期"
        class="date-picker"
        :clearable="true"
        @clear="handleDateClear"
    ></el-date-picker>
    <input
        v-model="selectedDevice"
        type="text"
        placeholder="输入设备"
        class="device-input"
    />
    <select v-model="selectedOrg" class="org-select">
      <option value="">选择机构</option>
      <option v-for="(org, index) in orgs" :key="index" :value="org">{{ org }}</option>
    </select>
    <select v-model="selectedField" class="field-select">
      <option value="">选择字段</option>
      <option value="rri">RRI</option>
      <option value="rrv">RRV</option>
      <option value="eeg">EEG</option>
      <option value="report_stage_chart">----报告图表----</option>
      <option v-for="(chart, key) in chartOptions"
              :key="key"
              :value="key">
        {{ chart }}
      </option>
      <option value="report_stage_chart">----报告字段----</option>
      <option v-for="(label, key) in keyMap"
              :key="key"
              :value="key">
        {{ label }}
      </option>
    </select>
    <el-button
        type="primary"
        @click="fetchData"
        :loading="loading"
        class="query-button">
      {{ loading ? '查询中...' : '查询' }}
    </el-button>
  </div>

  <!-- 数据显示区域 -->
  <div v-if="isImageType" class="image-container">
    <!-- 选择了日期时的显示 -->
    <template v-if="selectedDate">
      <!-- RRI 显示 -->
      <template v-if="activeField === 'rri'">
        <div v-for="(images, device) in fieldData"
             :key="device"
             class="device-section">
          <h3 class="device-title">设备: {{ device }}</h3>
          <div class="image-grid">
            <div v-for="(url, type) in images"
                 :key="`${device}-${type}`"
                 class="image-wrapper">
              <img :src="getImageUrl(url)"
                   :alt="`${device}-${type}`"
                   @click="handleImageClick(url)"/>
            </div>
          </div>
        </div>
      </template>
      <!-- RRV 显示 -->
      <template v-else>
        <div class="rrv-grid">
          <div v-for="(url, device) in fieldData"
               :key="device"
               class="image-wrapper">
            <h3 class="device-title">设备: {{ device }}</h3>
            <img :src="getImageUrl(url)"
                 :alt="`${device}`"
                 @click="handleImageClick(url)"/>
          </div>
        </div>
      </template>
    </template>
    <!-- 未选择日期时的显示 -->
    <template v-else>
      <div v-for="(dateData, device) in fieldData" :key="device">
        <h3 class="device-title">设备: {{ device }}</h3>
        <div class="device-grid">
          <div v-for="(url, date) in dateData"
               :key="`${device}-${date}`"
               class="device-item">
            <h4 class="date-title">{{ formatDate(date) }}</h4>
            <div class="image-wrapper">
              <img :src="getImageUrl(url)"
                   :alt="`${device} ${activeField}`"
                   @click="handleImageClick(url)"/>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>

  <!-- 字段值显示区域 -->
  <div v-if="!isImageType && !isChartType" class="field-data">
    <!-- 添加折线图显示 -->
    <template v-if="showLineChart">
      <div v-for="(deviceData, device) in fieldData" :key="device" class="device-data">
        <h3 class="device-title">设备: {{ device }}</h3>
        <div :id="`line-chart-${device}`" class="line-chart"></div>
      </div>
    </template>

    <!-- 原有的数据显示逻辑 -->
    <template v-else>
      <!-- 选择了日期时，按日期分组显示 -->
      <template v-if="selectedDate">
        <div v-for="date in Object.keys(groupedFieldData)" :key="date">
          <div class="device-grid">
            <div v-for="(data, device) in groupedFieldData[date]"
                 :key="`${date}-${device}`"
                 class="device-item">
              <h4 class="device-title">设备: {{ device }}</h4>
              <div class="field-value">{{ formatValue(data) }}</div>
            </div>
          </div>
        </div>
      </template>
      <!-- 未选择日期时的显示 -->
      <template v-else>
        <div v-for="(deviceData, device) in fieldData" :key="device" class="device-data">
          <h3 class="device-title">设备: {{ device }}</h3>
          <div class="field-grid">
            <div v-for="(value, date) in deviceData" :key="date" class="field-item">
              <h4 class="date-title">{{ formatDate(date) }}</h4>
              <div class="field-value">{{ formatValue(value) }}</div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>

  <!-- 添加图表显示区域 -->
  <div v-if="isChartType"
       class="chart-container"
       v-loading="loading"
       element-loading-text="图表加载中..."
       element-loading-background="rgba(255, 255, 255, 0.9)">
    <!-- 有选择日期时，按日期分组显示 -->
    <template v-if="selectedDate">
      <div v-for="date in Object.keys(groupedData)" :key="date">
        <div class="device-grid">
          <div v-for="(data, device) in groupedData[date]"
               :key="`${date}-${device}`"
               class="device-item">
            <h4 class="device-title">设备: {{ device }}</h4>
            <div :id="`chart-${device}-${date}`" class="chart"></div>
          </div>
        </div>
      </div>
    </template>
    <!-- 未选择日期时，按设备分组显示 -->
    <template v-else>
      <div v-for="(chartData, device) in fieldData" :key="device">
        <h3 class="device-title">设备: {{ device }}</h3>
        <div class="chart-grid">
          <div v-for="(data, date) in chartData" :key="date" class="chart-item">
            <h4 class="date-title">{{ formatDate(date) }}</h4>
            <div :id="`chart-${device}-${date}`" class="chart"></div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, nextTick, onMounted, computed, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import router from '@/router';
import dayjs from 'dayjs';
import { createAxiosInstance, wdb_get_new } from '@/plugins/axios';
import * as echarts from 'echarts';
import { setStageChartOption } from '@/plugins/chart/stage_chart';
import { setArtChartOption } from '@/plugins/chart/day_art_chart';
import { setArt2ChartOption } from '@/plugins/chart/day_art_chart2';
import { setDayEnvChartOption } from '@/plugins/chart/day_env_chart';
import { setDayHRVChartOption } from '@/plugins/chart/day_hrv_chart';
import { setDayRRChartOption } from '@/plugins/chart/day_rr_chart';
import { setStageAHChartOption } from '@/plugins/chart/stage_ah_chart';
import { setDayHRChartOption } from '@/plugins/chart/day_hr_chart';
import { setStageAH2ChartOption } from '@/plugins/chart/stage_ah_chart2';
import { setSpO2ChartOption } from '@/plugins/chart/spo2_chart';
import { setDayHoursChartOption } from '@/plugins/chart/day_12periods_chart';
import { setDayCPCChartOption } from '@/plugins/chart/day_cpc_chart';
import { setDayProbChartOption } from '@/plugins/chart/day_sleepprob';
import { ElButton, ElLoading, ElDatePicker } from 'element-plus';

const selectedDate = ref(dayjs().format('YYYY-MM-DD'));
const selectedDevice = ref('');
const selectedOrg = ref('');
const images = ref([]);
const emptySlots = ref(0);
const serverType = ref('计算服务器1');
const getAxiosInstance = () => createAxiosInstance(serverType.value);
const orgs = ref({});
const selectedField = ref('');
const activeField = ref('');
const fieldData = ref({});
const isImageType = computed(() => ['rri', 'rrv', 'eeg'].includes(activeField.value));
const isChartType = computed(() => {
  return activeField.value === 'report_stage_chart' ||
      Object.keys(chartOptions).includes(activeField.value);
});
// 添加服务器切换处理函数
const handleServerChange = async () => {
  try {
    // 不需要在这里调用 fetchData，因为 select 的 @change 事件会触发查询
    // 只需要清除之前的缓存数据
    dataCache.value.clear();
    fieldData.value = {};
  } catch (error) {
    console.error('Error changing server:', error);
  }
};
// 添加 baseUrl 定义
const baseUrl = import.meta.env.VITE_API_BASE_URL || '';

// 修改 formatDate 函数，确保输入是字符串类型
const formatDate = (date) => {
  if (!date) return '';

  // 确保 date 是字符串类型
  const dateStr = String(date);

  // 如果日期已经是 "MM/DD" 格式，直接返回
  if (dateStr.includes('/')) return dateStr;

  try {
    // 如果是 "YYYY-MM-DD" 格式，转换为 "MM/DD"
    const parts = dateStr.split('-');
    if (parts.length === 3) {
      return `${parts[1]}/${parts[2]}`;
    }
    return dateStr;
  } catch (error) {
    console.error('Error formatting date:', error);
    return dateStr;
  }
};

// 添加图表渲染相关的数据
const charts = ref([]);

// 表类型与渲染函数的映射
const chartRenderFunctions = {
  sleep_structure: renderSleepStructure,
  body_movement: renderBodyMovement,
  ahi_chart: renderAHI,
  sleep_heart_rate: renderHeartRate,
  sleep_hrv: renderHRV,
  sleep_breathing_rate: renderBreathingRate,
  blood_oxygen: renderBloodOxygen,
  sleep_environment: renderEnvironment,
  day_12periods: renderDayHours,
  sleep_prob: renderDayProb,
  cpc_chart: renderDayCPC
};

// 添加加载状态
const loading = ref(false);

// 添加新的缓存相关代码
const dataCache = ref(new Map());

// 生成缓存key的函数
const generateCacheKey = (params) => {
  return `${params.date}-${params.device}-${params.org}-${params.field}`;
};

// 修改 fetchData 函数
const fetchData = async () => {
  try {
    // 获取机构数据
    orgs.value = await wdb_get_new('数据服务器', 'org');
    // 更新 activeField 为当前选择的字段
    activeField.value = selectedField.value;
    // 如果没有选择字段，直接返回
    if (!activeField.value) return;

    const params = {
      date: selectedDate.value ? dayjs(selectedDate.value).format('YYYY-MM-DD') : '',
      device: selectedDevice.value,
      org: selectedOrg.value,
      field: isChartType.value ? 'report_stage_chart' : activeField.value
    };

    const cacheKey = `${serverType.value}-${generateCacheKey(params)}`; // 修改缓存键，加入服务器类型

    // 检查缓存
    if (dataCache.value.has(cacheKey)) {
      const cachedData = dataCache.value.get(cacheKey);
      handleFetchedData(cachedData);
      return;
    }

    loading.value = true;

    // 获取新的 axios 实例
    const axiosInstance = getAxiosInstance();
    const response = await axiosInstance.get('/device_filter/list/', { params });

    // 存储数据到缓存
    dataCache.value.set(cacheKey, response.data);

    // 处理获取的数据
    handleFetchedData(response.data);
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    loading.value = false;
  }
};

// 新增数据处理函数
const handleFetchedData = async (data) => {
  if (!data) return;

  if (isImageType.value) {
    handleImageData(data);
  } else {
    fieldData.value = data || {};
    if (isChartType.value) {
      await nextTick();
      await renderCharts();
    }

    // 如果需要显示折线图
    if (showLineChart.value) {
      await nextTick();
      Object.entries(fieldData.value).forEach(([device, data]) => {
        const chartDom = document.getElementById(`line-chart-${device}`);
        if (chartDom) {
          renderLineChart(chartDom, data);
        }
      });
    }
  }
};

// 修改处理图片数据的函数
const handleImageData = (data) => {
  if (!data) return;

  const processedData = {};

  if (selectedField.value === 'rrv' || selectedField.value === 'eeg') {
    // 处理 RRV 数据结构
    Object.entries(data).forEach(([device, deviceData]) => {
      if (!deviceData || typeof deviceData !== 'object') return;

      if (selectedDate.value) {
        // 处理日期格式
        let formattedDate = selectedDate.value;
        // 确保日期值是字符串类型并检查格式是否为 yyyy-mm-dd
        if (typeof formattedDate !== 'string' || !String(formattedDate).match(/^\d{4}-\d{2}-\d{2}$/)) {
          // 如果不是，使用 dayjs 转换为正确格式
          formattedDate = dayjs(formattedDate).format('YYYY-MM-DD');
        }

        // 使用格式化后的日期获取数据
        const dateData = deviceData[formattedDate];
        if (dateData && dateData[`${selectedField.value}.png`]) {
          // 直接存储对应的图片 URL
          processedData[device] = dateData[`${selectedField.value}.png`];
        }
      } else {
        // 未选择日期时的处理
        processedData[device] = {};
        Object.entries(deviceData).forEach(([date, dateData]) => {
          if (date.startsWith('1970')) return;
          if (dateData && dateData[`${selectedField.value}.png`]) {
            processedData[device][date] = dateData[`${selectedField.value}.png`];
          }
        });
      }
    });
  } else if (selectedField.value === 'rri') {
    // 处理 RRI 数据结构
    Object.entries(data).forEach(([device, deviceData]) => {
      if (!deviceData || typeof deviceData !== 'object') return;

      if (selectedDate.value) {
        const dateData = Object.values(deviceData)[0];
        if (dateData && typeof dateData === 'object') {
          processedData[device] = {
            core: dateData['core.png'],
            deep: dateData['deep.png'],
            rem: dateData['rem.png'],
            rri: dateData['rri.png']
          };
        }
      } else {
        // 未选择日期的处理逻辑,过滤掉1970年的数据
        processedData[device] = {};
        Object.entries(deviceData).forEach(([date, dateData]) => {
          if (date.startsWith('1970')) return;
          if (dateData && typeof dateData === 'object' && 'rri.png' in dateData) {
            processedData[device][date] = dateData['rri.png'];
          }
        });
      }
    });
  }

  fieldData.value = processedData;
};

// 修改图片显示的���板部分
const getImageUrl = (url) => {
  if (!url) return '';
  // 如果是完整的 URL 就直接返回
  if (typeof url === 'string' && url.startsWith('http')) {
    return url;
  }
  // 否则拼接 baseUrl
  return `${baseUrl}${url}`;
};

onMounted(async () => {
  await nextTick();
  fetchData();
});

const store = useStore();

function handleJump (type, value) {
  if (type === 'device' && value) {
    store.commit('setJumpData', {
      type: 'device',
      value,
      filter_type: 'input',
      path: '/device-management'
    });
    router.push('/device-management');
  } else if (type === 'org') {
    router.push('/organization');
  }
}

const keyMap = {
  reporttime: '报告开始计算时间',
  dur: '数据总时长',
  bcgsnore_index: '打鼾指数',
  sigtype_0: '无信号',
  sigtype_1: '低信号',
  sigtype_2: '正常信号',
  sigtype_3: '微体动信号',
  sigtype_4: '强体动信号',
  avg_temp: '平均温度',
  avg_humi: '平均湿度',
  avg_illu: '睡眠时平均光照',
  high_illu: '强光照睡眠',
  sleep_time: '有效睡眠总时间',
  ahi_count: '呼吸事件总数',
  ahi: '综合AHI',
  sri: 'SRI',
  quality: '睡眠质量分',
  ahi_count_2: 'REM期呼吸事件次数',
  sleep_dur_3: '睡眠分期_N1时长',
  sleep_dur_4: '睡眠分期_N2时长',
  sleep_dur_5: '睡眠分期_N3时长',
  ahi_345: 'NREM期呼吸指数',
  odi: 'ODI指数',
  hrv_rmssd_mean: 'hrv_rmssd',
  hrv_morning: 'hrv_morning',
  hrv_evening: 'hrv_evening',
  hrv_delta: 'hrv_delta',
  hrv_ratio_mean: 'hrv_ratio',
  gender_old: 'gender_old',
  gender: 'gender'
};

const fieldOptions = computed(() => {
  return Object.entries(keyMap).map(([key, label]) => ({
    value: key,
    label
  }));
});

// 添加图表类型选项
const chartOptions = {
  sleep_structure: '睡眠结构-chart',
  body_movement: '体动-chart',
  ahi_chart: '呼吸事件-chart',
  sleep_heart_rate: '睡眠心率-chart',
  sleep_hrv: '睡眠心率变异性-chart',
  sleep_breathing_rate: '睡眠呼吸率-chart',
  blood_oxygen: '血氧-chart',
  sleep_environment: '睡眠环境-chart',
  day_12periods: '时辰-chart',
  sleep_prob: '入睡速度-chart',
  cpc_chart: '心肺协同-chart'
};

// 添加数据组的计算属性
const groupedData = computed(() => {
  if (!selectedDate.value || !fieldData.value) return {};

  const result = {};
  Object.entries(fieldData.value).forEach(([device, dateData]) => {
    Object.entries(dateData).forEach(([date, data]) => {
      if (!result[date]) {
        result[date] = {};
      }
      result[date][device] = data;
    });
  });
  return result;
});

// 添加字段数据分组的计算属性
const groupedFieldData = computed(() => {
  if (!selectedDate.value || !fieldData.value) return {};

  const result = {};
  Object.entries(fieldData.value).forEach(([device, dateData]) => {
    Object.entries(dateData).forEach(([date, value]) => {
      if (!result[date]) {
        result[date] = {};
      }
      result[date][device] = value;
    });
  });
  return result;
});

// 修改 renderCharts 函数，使其返回 Promise
const renderCharts = async () => {
  // 清除之前的图表实例
  charts.value.forEach(chart => chart.dispose());
  charts.value = [];

  const renderPromises = [];

  const renderChart = (device, date, data) => {
    return new Promise((resolve) => {
      const chartId = `chart-${device}-${date}`;
      nextTick(() => {
        const chartDom = document.getElementById(chartId);
        if (chartDom) {
          const chart = echarts.init(chartDom);
          charts.value.push(chart);

          const renderFunction = chartRenderFunctions[selectedField.value];
          if (renderFunction) {
            renderFunction(chart, data);
          }

          window.addEventListener('resize', () => {
            chart.resize();
          });
        }
        resolve();
      });
    });
  };

  if (selectedDate.value) {
    // 选择了日期时，按日期分组渲染
    Object.entries(groupedData.value).forEach(([date, devices]) => {
      Object.entries(devices).forEach(([device, data]) => {
        renderPromises.push(renderChart(device, date, data));
      });
    });
  } else {
    // 未选择日期时，按设备分组渲染
    Object.entries(fieldData.value).forEach(([device, dateData]) => {
      Object.entries(dateData).forEach(([date, data]) => {
        renderPromises.push(renderChart(device, date, data));
      });
    });
  }

  // 等待所有图表渲染完成
  await Promise.all(renderPromises);
};

// 睡眠结构图表渲染函数
function renderSleepStructure (chart, data) {
  const stage_data = {
    start: data.starttime,
    end: data.endtime,
    stages: data.stage_details,
    nointention: data.intention_middle?.length > 0 ? data.intention_middle : null,
    leavebed: data.leavebed?.length > 0 ? data.leavebed : null
  };
  setStageChartOption(chart, stage_data);
}

// 体动图表渲染函数
function renderBodyMovement (chart, data) {
  const art_data = {
    start: data.starttime,
    end: data.endtime,
    leavebed: (data.leavebed && data.leavebed.length > 0) ? data.leavebed : null
  };

  if (data.artifact_series) {
    art_data.segments = data.artifact_series;
    art_data.stages = data.stage_details;
    setArt2ChartOption(chart, art_data);
  } else {
    art_data.segments = data.artifact_events;
    setArtChartOption(chart, art_data);
  }
}

// 心率图表渲染函数
function renderHeartRate (chart, data) {
  const hr_data = {
    start: data.starttime,
    end: data.endtime,
    nointention: data.intention_middle,
    leavebed: data.leavebed,
    avg: data.bpm_avg,
    segments: data.bpm_series
  };
  setDayHRChartOption(chart, hr_data);
}

// HRV图表渲染函数
function renderHRV (chart, data) {
  const hrv_data = {
    start: data.starttime,
    end: data.endtime,
    stages: data.stage_details,
    segments: data.hrv_rmssd,
    nointention: data.intention_middle,
    leavebed: data.leavebed,
    avg: data.hrv_rmssd_mean

  };
  setDayHRVChartOption(chart, hrv_data);
}

// 呼吸率图渲染函数
function renderBreathingRate (chart, data) {
  const rr_data = {
    start: data.starttime,
    end: data.endtime,
    nointention: data.intention_middle,
    leavebed: data.leavebed,
    segments: data.resp_series,
    avg: data.avg_resp
  };
  setDayRRChartOption(chart, rr_data);
}

// 血氧图表渲染函数
function renderBloodOxygen (chart, data) {
  const spo2_data = {
    start: data.starttime,
    end: data.endtime,
    stage: data.stage_details,
    spo2: data.spo2_data
  };
  setSpO2ChartOption(chart, spo2_data);
}

function renderDayHours (chart, data) {
  const hours_data = {
    start: data.starttime,
    hours: data.hours,
    chours: data.continuous_hours

  };
  setDayHoursChartOption(chart, hours_data);
}

function renderDayProb (chart, data) {
  const prob_data = {
    start: data.starttime,
    segments: data.sleep_prob,
    prob_start: data.sleep_prob_start
  }
  setDayProbChartOption(chart, prob_data);
}

function renderDayCPC (chart, data) {
  const cpc_data = {
    start: data.starttime,
    stages: data.stage_details,
    segments: data.cpc_series,
    end: data.endtime,
    leavebed: data.leavebed,
    nointention: data.intention_middle
  }
  setDayCPCChartOption(chart, cpc_data);
}

// AHI图表渲染函数
function renderAHI (chart, data) {
  const ah_data = {
    start: data.starttime,
    end: data.endtime,
    nointention: (data.intention_middle && data.intention_middle.length > 0) ? data.intention_middle : null,
    leavebed: (data.leavebed && data.leavebed.length > 0) ? data.leavebed : null,
    stages: data.stage_details
  };

  if (data.ahi_series) {
    ah_data.ah = data.ahi_series;
    setStageAH2ChartOption(chart, ah_data);
  } else {
    ah_data.ah = data.ahi_events;
    setStageAHChartOption(chart, ah_data);
  }
}

// 睡眠环境图表渲染函数
function renderEnvironment (chart, data) {
  const env_data = {
    start: data.starttime,
    end: data.endtime,
    nointention: data.intention_middle,
    leavebed: data.leavebed,
    segments: data.thi_series
  };
  setDayEnvChartOption(chart, env_data);
}

// 组件卸载时清理事件监听
onUnmounted(() => {
  charts.value.forEach(chart => {
    chart.dispose();
  });
  window.removeEventListener('resize', () => {
    charts.value.forEach(chart => chart.resize());
  });
  dataCache.value.clear();
});

// 添加图片点击处理函数
const handleImageClick = (url) => {
  if (!url) return;
  // 在新窗口打开图片
  window.open(getImageUrl(url), '_blank');
};

// 添加日期清除处理函数
const handleDateClear = () => {
  selectedDate.value = ''; // 只清除日期值,不触发查询
};

// 添加 formatValue 函数
const formatValue = (value) => {
  // 如果值不存在，返回空字符串
  if (value === null || value === undefined) return '';

  // 直接返回原始值的字符串形式
  return String(value);
};

// 修改 displayDate 计算属性
const displayDate = computed(() => {
  if (!selectedDate.value) return '';
  const parts = selectedDate.value.split('-');
  if (parts.length === 3) {
    return `${parts[1]}/${parts[2]}`;
  }
  return selectedDate.value;
});

// 添加一个判断是否显示折线图的计算属性
const showLineChart = computed(() => {
  return !selectedDate.value && selectedDevice.value && selectedField.value && !isImageType.value && !isChartType.value;
});

// 添加折线图渲染函数
const renderLineChart = (chartDom, data) => {
  const chart = echarts.init(chartDom);

  // 处理数据，按日期排序
  const sortedData = Object.entries(data)
    .map(([date, value]) => ({
      date: formatDate(date),
      value: Number(value) || 0
    }))
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  const option = {
    title: {
      text: keyMap[selectedField.value] || selectedField.value,
      left: 'center'
    },
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        const data = params[0];
        return `${data.name}<br/>${data.value.toFixed(2)}`;
      }
    },
    xAxis: {
      type: 'category',
      data: sortedData.map(item => item.date),
      axisLabel: {
        rotate: 45
      }
    },
    yAxis: {
      type: 'value'
    },
    series: [{
      data: sortedData.map(item => item.value),
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 6,
      lineStyle: {
        width: 2
      }
    }]
  };

  chart.setOption(option);
  charts.value.push(chart);
};
</script>

<style scoped>
.control-panel {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.device-input, .org-select, .query-button {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
}

.device-input:focus, .org-select:focus, .query-button:hover {
  border-color: #409eff;
}

.query-button {
  background-color: #409eff;
  color: white;
  cursor: pointer;
}

.query-button:active {
  background-color: #337ab7;
}

.image-container {
  padding: 20px;
}

.device-section {
  margin-bottom: 40px;
}

.device-title {
  margin: 0 0 20px 0;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
}

/* RRI 的网格布局 - 一行4张图 */
.image-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

/* RRV 的网格布局 - 一行4个设备 */
.rrv-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.image-wrapper {
  width: 100%;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.image-wrapper img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s;
}

.image-wrapper img:hover {
  transform: scale(1.02);
}

/* 响应式布局 */
@media screen and (max-width: 1200px) {
  .image-grid, .rrv-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .image-grid, .rrv-grid {
    grid-template-columns: 1fr;
  }
}

.field-select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
  max-height: 300px;
  min-width: 200px;
}

.field-data {
  padding: 20px;
}

.device-data {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 4px;
  background-color: #fff;
}

.field-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.field-item {
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.field-value {
  margin-top: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  word-break: break-all;
}

.date-title,
.device-title {
  margin: 20px 0;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
}

.device-title {
  font-size: 14px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  margin: 0 0 10px 0;
}

.chart-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.chart-item {
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.chart {
  width: 100%;
  height: 300px;
}

/* 响应式布局 */
@media screen and (max-width: 1600px) {
  .chart-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1200px) {
  .chart-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .chart-grid {
    grid-template-columns: 1fr;
  }
}

/* 修改查询按钮样式 */
.query-button {
  min-width: 80px; /* 设置最小宽度，防止加载时按钮宽度变化 */
}

/* 确保按钮在加载状态下文字居中 */
:deep(.el-button) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* 调整加载图标的大小和位置 */
:deep(.el-button .el-icon) {
  margin-right: 4px;
}

/* 可以添加一些悬停和激活状态的样式 */
.query-button:hover {
  opacity: 0.9;
}

.query-button:active {
  transform: scale(0.98);
}

/* 禁用状态式 */
.query-button[disabled] {
  cursor: not-allowed;
  opacity: 0.7;
}

/* 添加过渡效果 */
.query-button {
  transition: all 0.3s ease;
}

/* 添加加载状态相关样式 */
:deep(.el-loading-mask) {
  z-index: 1000;
}

:deep(.el-loading-text) {
  font-size: 14px;
  color: #409EFF;
}

.chart-container {
  position: relative;
  min-height: 200px; /* 确保加载状态有足够的显示空间 */
}

/* 修改字段显示的样式 */
.field-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.field-item {
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.field-value {
  margin-top: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  word-break: break-all;
}

/* ���应式布局 */
@media screen and (max-width: 1600px) {
  .field-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1200px) {
  .field-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .field-grid {
    grid-template-columns: 1fr;
  }
}

/* 添加设备网格布局样式 */
.device-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.device-item {
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.device-item .device-title {
  margin: 0 0 10px 0;
  padding: 8px;
  font-size: 14px;
  color: #333;
  border-bottom: 1px solid #eee;
}

/* 响应式布局 */
@media screen and (max-width: 1600px) {
  .device-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1200px) {
  .device-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .device-grid {
    grid-template-columns: 1fr;
  }
}

/* 调整图表容器的高度 */
.chart {
  width: 100%;
  height: 300px;
  margin-top: 10px;
}

/* 调整设备标题样式 */
.device-item .device-title {
  margin: 0 0 10px 0;
  padding: 8px;
  font-size: 14px;
  color: #333;
  border-bottom: 1px solid #eee;
}

/* 调整日期标题样式 */
.date-title {
  margin: 20px 0;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
}

.device-group {
  margin-bottom: 30px;
}

.images-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 15px;
}

.image-type {
  margin: 0 0 10px 0;
  font-size: 14px;
  color: #666;
  text-transform: capitalize;
}

.line-chart {
  width: 100%;
  height: 400px;
  margin: 20px 0;
}

.device-data {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* 添加服务器选择下拉框样式 */
.server-select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
  min-width: 120px;
}

.server-select:focus {
  border-color: #409eff;
}
</style>
