<template>
  <div class="header-container">
    <div class="logo1">
      <span v-if="user.isShenzhenHospital === '1'">数字化监测研究平台</span><span v-else>五季医学</span>
    </div>
    <div class="header-right">
      <SystemSummary/>
      <el-dropdown>
        <el-avatar class="avatar-dropdown" :size="50">
          <template v-slot:default>
            <span>{{ user ? user.username : '未登录' }}</span>
          </template>
        </el-avatar>

        <template v-slot:dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>个人信息</el-dropdown-item>
            <el-dropdown-item @click="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script setup>
import {
  computed, onMounted, onUnmounted, ref, watchEffect
} from 'vue'
import { useStore } from 'vuex'
import { ElDropdown, ElAvatar, ElDropdownMenu, ElDropdownItem } from 'element-plus'
import { wdb_get } from '@/plugins/axios.js'
import { useRouter } from 'vue-router';
import SystemSummary from '@/components/SystemSummary.vue';

const store = useStore()
const router = useRouter()
// 使用 computed 来创建响应式的 user 引用
const user = computed(() => store.state.user)

const refresh_user = () => {
  store.dispatch('fetchUser'); // 调用Vuex action更新用户信息
}
// 定义 logout 方法
const logout = () => {
  store.dispatch('logout')
  // 重定向到登录页面或其他操作
  clearCookies()
  clearLocalStorage()
  clearSessionStorage()
  redirectToLogin()
}

function clearCookies () {
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
  }
}

// 清除 Local Storage 的函数
function clearLocalStorage () {
  localStorage.clear()
}

// 清除 Session Storage 的函数
function clearSessionStorage () {
  sessionStorage.clear()
}

// 重定向到登录页面的函数
function redirectToLogin () {
  router.push({ path: '/login' })
}
// let intervalId = null; // 用于存储定时器ID
onMounted(() => {
  refresh_user()
})

onUnmounted(() => {
  // if (intervalId) clearInterval(intervalId); // 组件销毁时清除定时器
})

</script>

<style>
.header-container {
  display: flex;
  justify-content: space-between; /* 将 logo 和右侧内容分别放置在两端 */
  width: 100%;
  align-items: center; /* 垂直居中对齐所有子项目 */
  padding: 0 20px; /* 可选：添加左右内边距 */
}

.logo1 {
  flex-shrink: 0; /* 防止 logo 缩小 */
  font-size: 20px;
  position: relative; /* 使 logo 可以相对父容器定位 */
  left: -8%; /* 将 logo 向左移动 10% */
}

.header-right {
  display: flex;
  align-items: center; /* 垂直居中对齐所有子项目 */
  gap: 10px; /* 根据需要调整 */
}
</style>
