export function formatTime (d) {
  // 获取年月日，时间
  const dat = new Date(d * 1000)
  const hour = dat.getHours() < 10 ? '0' + (dat.getHours()) : dat.getHours();
  const min = dat.getMinutes() < 10 ? '0' + (dat.getMinutes()) : dat.getMinutes();
  const seon = dat.getSeconds() < 10 ? '0' + (dat.getSeconds()) : dat.getSeconds();
  let m = dat.getMilliseconds();
  if (m < 10) m = '00' + m; else if (m < 100) m = '0' + m;
  return hour + ':' + min + ':' + seon + '.' + m;
}

export function formatDateTime (d) {
  // 获取年月日，时间
  const dat = new Date(d * 1000)
  const year = dat.getFullYear();
  let month = dat.getMonth() + 1;
  if (month < 10) month = '0' + month;
  let day = dat.getDate();
  if (day < 10) day = '0' + day;
  const hour = dat.getHours() < 10 ? '0' + (dat.getHours()) : dat.getHours();
  const min = dat.getMinutes() < 10 ? '0' + (dat.getMinutes()) : dat.getMinutes();
  const seon = dat.getSeconds() < 10 ? '0' + (dat.getSeconds()) : dat.getSeconds();
  return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + seon;
}

export function formatDateTime_full (d) {
  // 获取年月日，时间
  const dat = new Date(d * 1000)
  const year = dat.getFullYear();
  let month = dat.getMonth() + 1;
  if (month < 10) month = '0' + month;
  let day = dat.getDate();
  if (day < 10) day = '0' + day;
  const hour = dat.getHours() < 10 ? '0' + (dat.getHours()) : dat.getHours();
  const min = dat.getMinutes() < 10 ? '0' + (dat.getMinutes()) : dat.getMinutes();
  const seon = dat.getSeconds() < 10 ? '0' + (dat.getSeconds()) : dat.getSeconds();
  let m = dat.getMilliseconds();
  if (m < 10) m = '00' + m; else if (m < 100) m = '0' + m;
  return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + seon + '.' + m;
}
export function isToday (dstr) {
  const dat = new Date(dstr);
  const today = new Date();
  return dat.getFullYear() === today.getFullYear() && dat.getMonth() === today.getMonth() && dat.getDate() === today.getDate();
}

export function make_timestamp_from_hour (date, hour) {
  // 构造日期字符串，确保小时为两位数
  const datetimeString = `${date}T${hour.padStart(2, '0')}:00:00`;
  // 将字符串转换为 Date 对象并获取 Unix 时间戳（以秒为单位）
  const timestamp = Math.floor(new Date(datetimeString).getTime() / 1000);
  return timestamp;
}

export function formatTimeDiff (timeDiff) {
  if (isNaN(timeDiff)) {
    return '';
  }
  if (timeDiff < 60) {
    return `${Math.floor(timeDiff) + 1}秒前在线`;
  } else if (timeDiff < 3600) {
    return `${Math.floor(timeDiff / 60)}分钟前在线`;
  } else if (timeDiff < 86400) {
    return `${Math.floor(timeDiff / 3600)}小时前在线`;
  } else {
    return `${Math.floor(timeDiff / 86400)}天前在线`;
  }
}
export function getDateTimestamp (dstr) {
  return new Date(dstr).getTime() / 1000;
}

export function getHourTimestamp (timestampInSeconds) {
  if (!timestampInSeconds) {
    timestampInSeconds = new Date().getTime() / 1000;
  }
  const date = new Date(timestampInSeconds * 1000); // JavaScript 的 Date 对象是基于毫秒的
  date.setHours(date.getHours(), 0, 0, 0);
  return Math.floor(date.getTime() / 1000);
}
