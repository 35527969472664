<template>
    <div class="container">
      <!-- 数据库列表 -->
      <div class="column dbnames">
        <h3>数据库</h3>
        <ul class="scrollable">
          <li v-for="db in filteredDbnames"
              :key="db"
              :class="{ selected: selectedDbname === db }"
              @click="handleDbClick(db)">
            {{ db }}
          </li>
        </ul>
      </div>
  
      <!-- 对象列表 -->
      <div class="column items">
        <h3>对象</h3>
        <ul class="scrollable">
          <li v-for="item in items"
              :key="item"
              @click="fetchData(item)"
              :class="{ selected: selectedItem === item }">
            {{ item }}
          </li>
        </ul>
      </div>
  
      <!-- 值显示 -->
      <div class="column datas">
        <h3>值</h3>
        <div class="scrollable data-content">
          <template v-if="datas">
            <div v-for="(value, key) in datas" :key="key" class="data-item">
              <div class="data-key">{{ key }}:</div>
              <div class="data-value" :class="{ 'long-text': isLongText(value) }">
                {{ formatValue(value) }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, computed } from 'vue';
  import { wdb_get_new, wdb_get_history_new } from '@/plugins/axios';
  import TreeViewObj from '@/common/TreeViewObj.vue';
  
  // 响应式状态
  const dbnames = ref([]);
  const items = ref([]);
  const datas = ref([]);
  const selectedItem = ref('');
  const selectedDbname = ref('');
  const serverType = ref('计算服务器1');
  
  // 过滤数据库列表
  const filteredDbnames = computed(() => {
    return dbnames.value.filter(db => ['encrypted_report', 'encrypted_user'].includes(db));
  });
  
  // 获取数据库列表
  const fetchDbNames = async () => {
    try {
      dbnames.value = await wdb_get_new(serverType.value);
    } catch (error) {
      console.error('Error fetching database names:', error);
      dbnames.value = [];
    }
  };
  
  // 获取项目列表
  const fetchItems = async () => {
    if (!selectedDbname.value) return;
    
    try {
      items.value = await wdb_get_new(serverType.value, selectedDbname.value);
    } catch (error) {
      console.error('Error fetching items:', error);
      items.value = [];
    }
  };
  
  // 获取数据
  const fetchData = async (item) => {
    if (item !== undefined) {
      selectedItem.value = item;
    }
    
    if (selectedItem.value) {
      try {
        datas.value = await wdb_get_history_new(serverType.value, selectedDbname.value, selectedItem.value, 60);
      } catch (error) {
        console.error('Error fetching data:', error);
        datas.value = [];
      }
    }
  };
  
  // 处理数据库点击
  const handleDbClick = async (db) => {
    selectedDbname.value = db;
    await fetchItems();
    datas.value = [];
    selectedItem.value = '';
  };
  
  // 生命周期钩子
  onMounted(() => {
    fetchDbNames();
  });
  
  // 添加新的工具函数
  const isLongText = (value) => {
    return typeof value === 'string' && value.length > 50;
  };
  
  const formatValue = (value) => {
    if (typeof value === 'string' && value.length > 100) {
      // 每100个字符添加换行
      return value.match(/.{1,100}/g).join('\n');
    }
    return value;
  };
  </script>
  
  <style scoped>
  .container {
    display: flex;
    border: 1px solid #ebebeb;
    height: 600px;
  }
  
  .column {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-right: 2px solid #ebebeb;
  }
  
  .dbnames, .items {
    flex: 2.5;
  }
  
  .datas {
    flex: 5;
    border-right: none;
  }
  
  .scrollable {
    overflow-y: auto;
    flex-grow: 1;
  }
  
  .column ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .column li {
    padding: 8px;
    cursor: pointer;
  }
  
  .column li:hover {
    background-color: #f5f7fa;
  }
  
  .column li.selected {
    background-color: #409eff;
    color: white;
  }
  
  h3 {
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .data-content {
    padding: 10px;
  }
  
  .data-item {
    margin-bottom: 15px;
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 4px;
  }
  
  .data-key {
    font-weight: bold;
    margin-bottom: 5px;
    color: #409eff;
  }
  
  .data-value {
    word-break: break-word;
    white-space: pre-wrap;
    font-family: monospace;
    background-color: #fff;
    padding: 8px;
    border: 1px solid #ebeef5;
    border-radius: 4px;
  }
  
  .long-text {
    max-height: 200px;
    overflow-y: auto;
  }
  </style>