<template>
  <div class="report-view">
    <el-card class="header-card"
             v-for="(value, key) in reports"
             :key="key"
             :header="key"
             style="margin-bottom: 20px ;overflow: visible">
      <!-- 条件渲染，检查 value.report 是否等于 'ahireport' -->
      <div class="card-content">
        <AlgorithmReport v-if="value.report === 'ahireport'" :data="value"/>
        <TreeViewObj v-else :data="value"/>

      </div>

    </el-card>
    <img
        :src="reports.online.rri_png"
        alt="未生成 rri.png" width="100%"
        v-if="reports && reports.online"
    >    <img
        :src="reports.online.rri_png_rem"
        alt="未生成 rem.png" width="100%"
        v-if="reports && reports.online"
    >    <img
        :src="reports.online.rri_png_core"
        alt="未生成 core.png" width="100%"
        v-if="reports && reports.online"
    >    <img
        :src="reports.online.rri_png_deep"
        alt="未生成 deep.png" width="100%"
        v-if="reports && reports.online"
    >
      <img
        :src="reports.online.eeg_png"
        alt="未生成 deep.png" width="100%"
        v-if="reports && reports.online"
    >
  </div>
</template>

<script setup>
import {ref, defineProps, onMounted, watch} from 'vue';
import TreeViewObj from "@/common/TreeViewObj.vue";
import {request_data} from '@/plugins/axios.js';
import AlgorithmReport from "@/components/AlgorithmReport.vue";
// 接收prop
const props = defineProps({
  device: String,
  date: String,
  server: {
    type: String,
    default: '计算服务器1'
  }
});

const reports = ref({});

async function getReports() {
  if (!props.device) return
  const reportdate = props.date ? props.date : "_";
  reports.value = await request_data(`/devicereport/${props.device}/${reportdate}/`,props.server);
}

onMounted(() => {
  getReports()
});
watch([() => props.device, () => props.date], async ([newDevice, newDate], [oldDevice, oldDate]) => {
  console.log(oldDate, oldDevice, newDevice, newDate)
  getReports()
});
</script>
<style>
/* 添加到你的组件的<style>标签内 */
.card-content {
  overflow-y: auto;
  font-size: 0.5em; /* 调整为适当的大小，如果0.5em太小 */
}

.el-card {
  overflow: visible !important;
}
</style>
