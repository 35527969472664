import * as echarts from 'echarts';

import {getColor, getRankColor, formatDateString} from '../utils/utils.js';

import {locales} from '../utils/locales';

import {
    chart_dates, // 图表横轴的日期与报告数据之间的映射关系
    getInterval
} from './variables.js';

const L = locales['zh-Hans']

const monthNameArray = [L.jan, L.feb, L.mar, L.apr, L.may, L.jun, L.jul, L.aug, L.sep, L.oct, L.nov, L.dec];

// OPTION1: 不带分期
function renderItem(params, api) {
    if (!api.value(1) || !api.value(2)) {
        return null
    }

    const start = api.coord([api.value(0), api.value(1)]);
    const end = api.coord([api.value(0), api.value(2)]);
    const width = api.size([1, 1])[0] * 0.7;

    const rectShape = echarts.graphic.clipRectByRect(
        {
            x: start[0] - width / 2,
            y: start[1],
            width,
            height: end[1] - start[1]
        }, {
            x: params.coordSys.x,
            y: params.coordSys.y,
            width: params.coordSys.width,
            height: params.coordSys.height
        }
    );
    return (
        rectShape && {
            type: 'rect',
            transition: ['shape'],
            // shape: {...rectShape, r: 4 },
            shape: rectShape,
            style: {
                fill: '#CCC', // #015d5c
                opacity: 0.5
            }
        }
    );
}

// OPTION2: 带分期
/*
function renderItem(params, api) {
    if (!api.value(1)) { return null };
    //const catColor = ['#fe8973', '#59aae0'];
    const catColor = ['#CCC', '#59aae0'];

    var rects = [];
    var stages = dataStages[params.dataIndex];

    for (var i=0; i<stages.length; i++) {
        var start = api.coord([api.value(0), stages[i].start]);
        var end = api.coord([api.value(0), stages[i].end]);
        var width = api.size([1, 1])[0] * 0.7;
        if (stages[i].value<0) { // 空洞
            continue;
        }
        var itemColor = catColor[stages[i].value];

        var rectShape = echarts.graphic.clipRectByRect(
            {
                x: start[0] - width/2,
                y: start[1],
                width: width,
                height: end[1] - start[1],
            }, {
                x: params.coordSys.x,
                y: params.coordSys.y,
                width: params.coordSys.width,
                height: params.coordSys.height
            }
        );
        rectShape = rectShape && {
            type: 'rect',
            transition: ['shape'],
            //shape: {...rectShape, r: 4 },
            shape: rectShape,
            style: {
                fill: itemColor,
            }
        }
        rects.push(rectShape);
    }

    return ({
        type: 'group',
        children: rects,
    });
}
*/

export function setTIBChartOption(reports, tib_chart) {
    if (!tib_chart) {
        return;
    }

    const dataSleep = [];
    const dataSRI = [];
    const dataXAxis = [];

    // 不带分期的直接展示方式
    let weekmode = false;
    if (Object.keys(reports).length > 0) {
        const total = Object.keys(chart_dates).length;
        // 如果周期太长，以周为单位计算均值
        if (total <= 60) {
            for (const key in chart_dates) {
                const [month, day] = key.split('/');
                const abbrKey = month + '-' + day;
                let start = reports[key].start;
                let end = reports[key].end;
                let tst = reports[key].tst;
                let sri = Math.round(reports[key].sri);
                if (sri < 0) sri = null; // 无效的SRI指数
                if (start && end) {
                    let offset = Math.floor(start / 24 / 3600);
                    start = start - offset * (24 * 3600);
                    end = end - offset * (24 * 3600);
                }
                // let c = getRankColor(reports[key], 'sri', sri);
                dataSleep.push({name: abbrKey, value: [0, start, end, tst]});
                dataSRI.push({name: abbrKey, value: sri, itemStyle: {color: '#73c0de'}});
                dataXAxis.push(abbrKey);
            }
            for (let i = 0; i < dataSleep.length; i++) {
                dataSleep[i].value[0] = dataSleep.length - 1 - i;
            }
        } else {
            weekmode = true;
            let [start_avg, end_avg, sri_avg, count, countSri] = [0, 0, 0, 0, 0];
            let valid = false; // 跳过开头的“半周”
            let weekend = '';
            for (let key in chart_dates) {
                let [year, month, day] = key.split('-');
                let d = new Date();
                d.setFullYear(year);
                d.setMonth(month - 1);
                d.setDate(day);
                d.setHours(0, 0, 0, 0);
                let weekday = d.getDay();

                let start = chart_dates[key] >= 0 ? reports[chart_dates[key]].start : null;
                let end = chart_dates[key] >= 0 ? reports[chart_dates[key]].end : null;
                let sri = chart_dates[key] >= 0 ? Math.round(reports[chart_dates[key]].sri) : null;
                if (sri < 0) sri = null; // 无效的SRI指数
                if (start && end) {
                    const offset = Math.floor(start / 24 / 3600);
                    start = start - offset * (24 * 3600);
                    end = end - offset * (24 * 3600);
                }

                if (start != null && end != null && valid) {
                    start_avg += start;
                    end_avg += end;
                    count++;
                }
                if (sri != null && valid) {
                    sri_avg += sri;
                    countSri++;
                }
                if (weekday === 6) { // 每周最后一天
                    weekend = month + '-' + day;
                }
                if (weekday === 0) { // 每周第一天
                    if (valid) {
                        start_avg = count ? Math.round(start_avg / count) : null;
                        end_avg = count ? Math.round(end_avg / count) : null;
                        sri_avg = countSri ? Math.round(sri_avg / countSri) : null;

                        key = month + '-' + day + L.to + weekend;
                        const c = getRankColor(null, 'sri', sri_avg);
                        dataSleep.push({name: key, value: [0, start_avg, end_avg]});
                        dataSRI.push({name: key, value: sri_avg, itemStyle: {color: c}});
                        dataXAxis.push(key);
                        [start_avg, end_avg, sri_avg, count, countSri] = [0, 0, 0, 0, 0];
                        weekend = '';
                    } else {
                        valid = true;
                    }
                }
            }
            for (let i = 0; i < dataSleep.length; i++) {
                dataSleep[i].value[0] = dataSleep.length - 1 - i;
            }
        }
    } else {
        return;
    }
    dataSleep.reverse();
    dataSRI.reverse();
    dataXAxis.reverse();
    const option = {
        backgroundColor: 'transparent',
        animationDuration: 500,
        legend: {
            data: [{name: L.time_in_bed, icon: 'rect', itemStyle: {opacity: 0.5}}, {name: L.sleep_regularity}],
            orient: 'horizontal',
            bottom: -5,
            selectedMode: false,
            icon: 'circle',
            itemHeight: 10,
            itemWidth: 10,
            itemGap: 6,
            textStyle: {
                fontSize: 12,
                padding: [0, 0, 0, -2]
            }
        },
        grid: {
            top: '5%',
            left: '3%',
            right: '3%',
            bottom: '12%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: true,
            axisTick: {
                alignWithLabel: true
            },
            axisLabel: {
                showMinLabel: true,
                showMaxLabel: true,
                fontSize: 10,
                rotate: weekmode ? 0 : 45,
                interval: weekmode ? 0 : getInterval,
                formatter: (value) => {
                    if (weekmode) {
                        const [weekstart, weekend] = value.split(L.to);
                        const [startMonth, startDay] = weekstart.split('-');
                        const [endMonth] = weekend.split('-');
                        if (startMonth !== endMonth || startDay === 1) { // 月初
                            return monthNameArray[parseInt(endMonth) - 1];
                        } else {
                            return ''
                        }
                    } else {
                        return value;
                    }
                }
            },
            data: dataXAxis
        },
        yAxis: [
            {
                type: 'value',
                // min: (value)=>{ return Math.floor(value.min/10)*10 },
                // max: (value)=>{ return Math.ceil(value.max/10)*10 },
                max: 100,
                min: (value) => {
                    if (value.min >= 50) {
                        return 50;
                    } else {
                        return Math.floor(value.min / 10) * 10;
                    }
                },
                minInterval: 5,
                axisLabel: {
                    show: true,
                    formatter: '{value}%'
                }
            },
            {
                type: 'value',
                min: function (value) {
                    return Math.floor(value.min / 1800) * 1800 - 1800;
                },
                max: function (value) {
                    return Math.ceil(value.max / 1800) * 1800 + 1800;
                },
                minInterval: 14400,
                maxInterval: 14400,
                inverse: true,
                // alignTicks: true,
                // splitLine: { show: false },
                axisLabel: {
                    show: true,
                    showMinLabel: false,
                    showMaxLabel: false,
                    formatter: (value) => {
                        return formatDateString((value) * 1000, 'HHmm', true);
                    }
                }
            }
        ],
        tooltip: {
            backgroundColor: getColor('background'),
            borderColor: 'transparent',
            trigger: 'axis',
            confine: true,
            shadowBlur: 4,
            textStyle: {
                fontSize: 12,
                lineHeight: 9,
                textShadowBlur: 2,
                textShadowColor: 'transparent',
                color: getColor('text')
            },
            axisPointer: {
                type: 'line',
                label: {
                    show: true,
                    backgroundColor: '#6a7985'
                }
            },
            formatter: function (params) {
                const tib = params[0].value[2] - params[0].value[1];
                const sri = params[1].value;
                return params[0].name +
                    // + '\n' + params[0].seriesName + '    ' + (tst ? seconds2HHmm(tst, true) : '-')
                    // + '\n' + '卧床时长' + '    ' + (tib ? seconds2HHmm(tib, true) : '-')
                    '\n' + params[1].marker + params[1].seriesName + '    ' + (sri ? sri + '%' : '-') +
                    '\n' + L.in_bed + (tib ? formatDateString(params[0].value[1] * 1000, 'HHmm') : '-') +
                    ' - ' + L.get_up + (tib ? formatDateString(params[0].value[2] * 1000, 'HHmm') : '-');
            }

        },
        series: [{
            name: L.time_in_bed,
            type: 'custom',
            renderItem,
            data: dataSleep,
            itemStyle: {color: '#CCC'},
            encode: {
                x: 0,
                y: [1, 2],
                tooltip: 3
            },
            yAxisIndex: 1
        }, {
            name: L.sleep_regularity,
            type: 'line',
            data: dataSRI,
            yAxisIndex: 0,
            smooth: true,
            // symbol: weekmode ? 'emptyCircle' : (value, params)=>{ return getSymbol(value, params, dataSRI); },
            symbol: 'circle',
            symbolSize: 5,
            lineStyle: {color: getColor('linegray1')},
            itemStyle: {color: getColor('linegray1')}
        }]
    };
    tib_chart.setOption(option);
    // console.log("Sleep chart optionized with: " + reports.length + " reports");
}
