import axios from 'axios';
import { getCurrentInstance } from 'vue';
import { ElMessageBox } from 'element-plus';
import { store } from './store'; // 确保路径正确

const endpoints_production = {
  计算服务器1: '/b/',
  计算服务器2: '/c/',
  数据服务器: '/a/'
};

const axiosInstances = {}

export const clearAxiosInstances = () => {
  for (const key in axiosInstances) {
    delete axiosInstances[key];
  }
}
export function get_endpoint (serverType) {
  return endpoints_production[serverType];
}

export async function request_data (url, serverType = '数据服务器') {
  try {
    const axiosInstance = createAxiosInstance(serverType);
    const response = await axiosInstance.get(url); // 使用新的 axios 实例请求数据
    if (response && response.status === 200 && response.data) {
      try {
        if (response.data.data) {
          return response.data.data;
        } else {
          return response.data
        }
      } catch (e) {
        return response.data;
      }
    }
  } catch (error) {
    console.error('请求数据时发生错误:', error);
  }
  return null; // 如果请求失败或数据不满足条件，返回null
}

export function createAxiosInstance (serverType) {
  if (axiosInstances[serverType]) {
    return axiosInstances[serverType];
  }
  const baseURL = get_endpoint(serverType);
  const token = localStorage.getItem('authToken');
  const a = axios.create({
    baseURL,
    // withCredentials: true
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    }
  });
  a.interceptors.response.use(response => response, error => {
    if (error.response && error.response.status === 401) {
      console.log('expired');
      console.log(store);
      store.commit('setUser', {});
    } else if (!error.response) {
      store.commit('setNetError', '网络或系统故障，请稍后重连');
    } else {
      store.commit('setNetError', '服务器错误，类型:' + error.response.status);
    }
    return Promise.reject(error);
  });
  axiosInstances[serverType] = a;
  return a;
}

export async function do_task (taskurl, args, serverType = '数据服务器') {
  const params = Object.keys(args)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(args[key])}`)
    .join('&');
  if (!taskurl.endsWith('/')) taskurl = taskurl + '/';
  const response = await request_data(taskurl + '?' + params, serverType);

  ElMessageBox.alert(response.message);
  return response.result;
}

export async function wdb_get (db, item, tag, time) {
  // console.log("wdb_get",db,item,tag,time)
  let url = '';
  if (tag) url = '/wdb/' + db + '/' + item + '/' + tag + '/';
  else if (item) url = '/wdb/' + db + '/' + item + '/';
  else if (db) url = '/wdb/' + db + '/';
  else url = '/wdb/';
  if (time) { url += '?time=' + time; }
  return request_data(url);
}

export async function wdb_get_new (serverType, db, item, tag, time) {
  // console.log("wdb_get",db,item,tag,time)
  let url = '';
  if (tag) url = '/wdb/' + db + '/' + item + '/' + tag + '/';
  else if (item) url = '/wdb/' + db + '/' + item + '/';
  else if (db) url = '/wdb/' + db + '/';
  else url = '/wdb/';
  if (time) { url += '?time=' + time; }
  return request_data(url, serverType);
}

export async function wdb_get_history (db, item, history = 0) {
  let url = '';
  if (item) url = '/wdb/' + db + '/' + item + '/';
  else if (db) url = '/wdb/' + db + '/';
  else url = '/wdb/';
  if (history) url += '?history=' + history;
  return request_data(url);
}

export async function wdb_get_history_new (serverType, db, item, history = 0) {
  let url = '';
  if (item) url = '/wdb/' + db + '/' + item + '/';
  else if (db) url = '/wdb/' + db + '/';
  else url = '/wdb/';
  if (history) url += '?history=' + history;
  return request_data(url, serverType);
}
