export const ledColor = ['#00BB35', '#FFBA2F', '#FB8700', '#E92D2E', '#CCC'];
const tipText = [
  {
    id: 'quality',
    title: '睡眠质量',
    text: '睡眠质量得分是基于您每天的睡眠数据在五个关键维度上的评估综合得出的。这包括总睡眠时长、入睡速度、睡眠中断、深睡眠和睡眠规律性。这个综合得分旨在帮助您快速的了解您睡眠质量的表现和变化。\n\n虽然我们为每天提供了一个得分，我们强烈建议您更加关注这五个维度的周、月甚至更长时间段的趋势，帮助您更全面的了解您的睡眠。'
  }, {
    id: 'tst',
    title: '总睡眠时长',
    text: 'Total Sleep Time（TST），总记录时间内的实际睡眠时间，所有快速眼动期和非快速眼动期睡眠时间的总和。合适的睡眠时长取决于多种因素，尤其是年龄。一般18-65岁成年人建议的睡眠时长为7-9小时，65岁以上建议为7-8小时。请注意，这些建议是一般性的，因为每个人的生活方式和身体状况都有所不同，实际所需睡眠时长也会不同。\n\n建立规律的作息时间、创造适宜的睡眠环境、睡前避免咖啡因和刺激性物质、午睡尽量控制在20-30分钟以内，这些建议可帮助维持健康的睡眠时长。'
  }, {
    id: 'se',
    title: '睡眠效率',
    text: 'Sleep Efficiency（SE），睡眠期间实际用于睡眠的时间比例，睡眠效率 = 总睡眠时间 / 卧床时间。\n\n睡眠效率为 85% 或更高被认为是正常的，而睡眠效率高于 90% 则被认为是非常好的。较长的入睡速度和睡眠中断是导致睡眠效率低的主要原因。'
  }, {
    id: 'sol',
    title: '入睡速度',
    text: '入睡速度 Sleep Onset Latency（SOL）测量入睡用时，也称为睡眠潜伏期 ，是指上床睡觉后到入睡所需的时间。理想情况下，睡眠潜伏期应在 10 到 20 分钟之间。即使没有疲倦的主观感觉，人在需要睡眠时一般也会更快入睡。睡眠潜伏期很短的人有可能有睡眠不足的情况；过长（大于20-30分钟）意味着失眠或发生了其他影响入睡能力的健康问题。\n\n限制睡前屏幕使用时间、制定规律的睡前习惯、限制入睡前的运动、使用冥想或呼吸练习等心理放松技巧，可帮助缩短入睡时间并提高睡眠质量。'
  }, {
    id: 'waso',
    title: '睡眠中断',
    text: '睡眠中断 Wake after Sleep Onset（WASO）测量入睡后清醒的总分钟数，一定程度的 WASO 是正常的，但夜间清醒时间过长或碎片化会大大降低睡眠质量，影响次日白天的精力，工作和学习表现。\n\n导致入睡后清醒时间增加的原因有很多，包括焦虑和压力、睡眠障碍、不规律的作息、过度饮食或饮酒，及其他健康问题或疾病。了解这些因素并采取相应的改善措施，如维持规律的作息时间、改善睡眠环境、减少压力等，有助于改善睡眠质量，减少夜间醒来的次数和时间。'
  }, {
    id: 'sri',
    title: '睡眠规律性',
    text: '睡眠规律性由睡眠规律指数 (SRI) 计算，测量您在最近7天内睡眠开始与结束时间的稳定性。睡眠规律性对睡眠质量有着显著的影响，维持规律的睡眠时间和作息习惯可以帮助调整生物钟，促进更健康的睡眠模式，对睡眠质量有积极的作用，同时对健康、安全和工作表现也很重要。研究发现，不规律的睡眠与多种不良后果有关，包括心脏自主调节、炎症、新陈代谢、心理健康以及表现和认知功能等方面。\n\n调整睡眠规律性的第一步是保证充足的睡眠的前提下选择固定的就寝和起床时间，即使是周末也要保持，虽然可能需要一些时间适应，这是正常的；同时养成良好的睡眠卫生习惯，逐渐适应期望的就寝时间，发展为稳定健康的睡眠模式。'
  }, {
    id: 'stage',
    title: '睡眠结构',
    text: '睡眠周期是由非快速眼动睡眠（NREM sleep）和快速眼动睡眠（REM sleep）组成。\n\n快速眼动睡眠是大脑活动最高的睡眠阶段，大部分梦境发生在这期间，除了控制呼吸、心率和眼球运动的肌肉外，身体的大部分肌肉都处于麻痹状态。\n\n非快速眼动睡眠包含浅睡眠和深睡眠。浅睡眠在睡眠阶段中占主导地位，在此阶段心跳和呼吸减缓，体温降低；深睡眠也称慢波睡眠，在此阶段，呼吸和心率降至最低水平，大脑活动减慢，是身体与大脑修复、代谢调节的关键时期。深睡眠一般占总睡眠时间的10-25%，统计表明深睡眠可能随年龄增长每10年减少2%，年轻成年人每晚可能经历80-100分钟的深度睡眠，而60岁以上老年人深度睡眠时间可能少于20分钟。'
  }, {
    id: 'ahi',
    title: '呼吸暂停低通气指数AHI',
    text: `阻塞性睡眠呼吸暂停（OSA）是一种以睡眠打鼾伴呼吸暂停和日间思睡为主要临床表现的睡眠呼吸疾病，患病率为2%～4%。该病可引起间歇性低氧、高碳酸血症以及睡眠结构紊乱，并可导致高血压、冠心病、心律失常、脑血管病、认知功能障碍、2型糖尿病等多器官多系统损害。研究表明，未经治疗的重度OSA患者病死率比普通人群高3.8倍。\n
        OSA的诊断通常参考呼吸暂停-低通气指数（AHI）数值。AHI表示睡眠中每小时发生的呼吸暂停和低通气的平均次数，通过呼吸暂停和低通气事件（持续10秒以上）的总数除以睡眠的总时间来计算。成人的AHI数值分为三类，分别对应不同的 OSA 严重程度：\n轻度：5≤AHI<15\n中度：15≤AHI<30\n重度：AHI≥30`,
    note: '*参考：中国医师协会睡眠医学专业委员会. 成人阻塞性睡眠呼吸暂停多学科诊疗指南 [J] . 中华医学杂志,2018,98 (24): 1902-1914. DOI: 10.3760 cma.j.issn.0376-249. 2018.24.003'
  }, {
    id: 'ahi2345',
    title: '睡眠阶段与AHI',
    text: '上呼吸道塌陷和阻塞性睡眠呼吸暂停（OSA）的严重程度在睡眠不同阶段会有所变化。其中快速眼动（REM）睡眠占总睡眠时间的20%-25%，与影响上气道功能的生理变化密切相关，在此期间全身肌张力降低，从而增加了上气道塌陷的可能性。\n\n此外，与非快速眼动（NREM）睡眠相比，REM睡眠期间的OSA事件通常更长、更频繁，并且血氧降低的幅度可能更大。实际上，有10%-37%的被诊断为OSA的患者仅在REM睡眠中出现呼吸暂停和/或低通气事件。因此，了解不同睡眠阶段中的AHI情况对于OSA的评估和治疗提供了一定的帮助。'
  }, {
    id: 'hr',
    title: '睡眠最低心率',
    text: `睡眠时，人的心率通常会低于日间静息心率，平均可能低至每分钟 40 至 50 次。大多数成年人的静息心率在每分钟 40 至 100 次之间，进入深度睡眠时的心率可能比静息心率低约 20% 至 30%。\n
    跟踪睡眠状态下心率的长期趋势和是否超出个人基线区间，可以了解身体适应性、整体健康表现的异常与波动，或其他需引起注意的健康状况。稳定的保持在个人基线范围内是最好的，虽然更低的睡眠最低心率通常意味着更好的心血管健康水平和身体素质，但低于基线区间太多也可能反映异常的健康状况；高出基线区间的情况更加需要引起注意，可以反映低质量的睡眠、疾病，或睡前不适合的饮食及锻炼习惯。`,
    note: '*睡眠最低心率个人基线区间范围取自过去14天积累的数据。'
  }, {
    id: 'hrv',
    title: '睡眠心率变异性',
    text: `心率变异性（HRV）测量心跳间隔波动，与自主神经系统功能相关，反映神经心脏功能和身体适应性。HRV RMSSD是心跳间隔波动的主要指标，通常高值与良好的自我调节和适应性有关，低值则与抑郁、焦虑及心血管疾病风险增加相关。\n
    跟踪心率变异性（HRV）是否超出个人基线区间范围中，可以评估潜在生理压力水平的异常与波动，对身体对压力/疲劳的适应性和心血管健康进行追踪与评估；稳定的保持在个人基线范围内是最好的，同时，比起高出历史基线的情况，低于历史基线的情况更加需要引起关注，但短期内超出基线区间太多也可能反映异常的健康状况。`,
    note: '*HRV个人基线区间范围取自过去2个月内积累的数据。'
  }, {
    id: 'hrvlh',
    title: 'HRV LF/HF',
    text: '自律神经系统调节心率、血压、呼吸和消化，分为交感神经（应激）和副交感神经（放松）系统。LF/HF比值衡量交感神经和副交感神经活动比例，低比值表示副交感神经主导，高比值表示交感神经主导。'
  }, {
    id: 'rr',
    title: '睡眠呼吸频率',
    text: `健康成年人呼吸频率（每分钟呼吸的次数）一般为 12-20 次/分钟，睡眠时的呼吸频率通常会低于日间呼吸频率。\n
    与心率一样，呼吸频率是一种生命体征指标，可以洞察一个人的整体健康状况。健康成年人的呼吸频率在夜间变化不大，因此与个人基线相比突然的变化可能暗示身体受到压力、睡眠质量下降或出现其他需引起注意的健康状况，对于发现异常情况十分重要。同时，比起低于基线区间的情况，高出基线区间的情况更加需要引起关注，如在发烧或焦虑等健康异常状况下，呼吸频率可能会升高，但短期内低于基线区间太多也可能反映异常的健康状况。`,
    note: '*睡眠呼吸频率个人基线区间范围取自过去14天积累的数据。'
  }, {
    id: 'env',
    title: '睡眠环境',
    text: `合适的睡眠环境对于获得良好的睡眠质量至关重要。睡眠环境监测您睡眠期间环境中的温度、湿度、光照指标，帮助您了解您的环境是否处于最适宜睡眠的条件。\n
        研究表明，适宜的睡眠温度范围为 15-23°C，湿度范围为 40-60%；尽可能在光照强度小于 1lx 的黑暗环境中入睡，开灯睡觉会干扰睡眠周期，导致睡眠更加碎片化。`
  }
];

function getBaseComment (type, value, lower, upper, lowest) {
  if (isNaN(lower) || isNaN(upper) || isNaN(value)) { return null; }
  if (type === 'hrv') {
    if (value > upper) {
      return { msg: '波动', color: ledColor[1], ok: false }
    } else if (value >= lower) {
      return { msg: '正常', color: ledColor[0], ok: true }
    } else if (value >= lowest) {
      return { msg: '波动', color: ledColor[1], ok: false }
    } else {
      // return { msg: '需注意', color: ledColor[3], ok: false }
      return { msg: '波动', color: ledColor[1], ok: false }
    }
  } else if (type === 'rr') {
    if (value > upper) {
      return { msg: '波动', color: ledColor[1], ok: false }
    } else if (value >= lower) {
      return { msg: '正常', color: ledColor[0], ok: true }
    } else if (value < lower) {
      return { msg: '波动', color: ledColor[1], ok: false }
    }
  } else if (type === 'hr') {
    // TODO: 心率对超出基线下限，如何表示颜色？
    /* if (value>upper) {
            return { msg: '波动', color: ledColor[1], ok: false }
        } else {
            return { msg: '正常', color: ledColor[0], ok: true }
        } */
    if (value > upper) {
      return { msg: '波动', color: ledColor[1], ok: false }
    } else if (value >= lower) {
      return { msg: '正常', color: ledColor[0], ok: true }
    } else if (value < lower) {
      return { msg: '波动', color: ledColor[1], ok: false }
    }
  } else if (type === 'illu') {
    if (value > 15) {
      return { msg: '偏多', color: ledColor[1], ok: false }
    } else {
      return { msg: '适宜', color: ledColor[0], ok: true }
    }
  } else if (type === 'temp') {
    if (value < 15) {
      return { msg: '偏低', color: ledColor[1], ok: false }
    } else if (value > 23) {
      return { msg: '偏高', color: ledColor[1], ok: false }
    } else if (value) {
      return { msg: '适宜', color: ledColor[0], ok: true }
    } else {
      return { msg: '', color: ledColor[0], ok: true }
    }
  } else if (type === 'humi') {
    if (value < 40) {
      return { msg: '偏低', color: ledColor[1], ok: false }
    } else if (value > 60) {
      return { msg: '偏高', color: ledColor[1], ok: false }
    } else if (value) {
      return { msg: '适宜', color: ledColor[0], ok: true }
    } else {
      return { msg: '', color: ledColor[0], ok: true }
    }
  }
  return null;
}

function versionCompare (version1, version2) {
  const v11 = parseFloat(version1);
  const v12 = parseInt(version1.replace(v11 + '.', ''));
  const v21 = parseFloat(version2);
  const v22 = parseInt(version2.replace(v21 + '.', ''));
  if (v11 > v21) { return true; } else if (v11 < v21) { return false; } else {
    if (v12 > v22) { return true; } else { return false; }
  }
}

function validWxVersion () {
  const platform = '';
  const version = '';
  if (platform === 'android' && versionCompare('6.5.7', version)) {
    return false;
  } else if (platform === 'ios' && versionCompare('6.5.6', version)) {
    return false;
  }
  return true;
}

function isIOS () {
  const platform = '';
  if (platform === 'ios') {
    return true;
  }
  return false;
}

export function getColor (item) {
  const theme = null;

  if (theme === 'dark') { // 夜间模式
    switch (item) {
      case 'background': return '#111';
      case 'title': return '#969799';
      case 'text': return '#d1d1d1';
      case 'tag': return '#5a5a5a';
      case 'linegray1': return '#c8c9cc';
      case 'linegray2': return '#969799';
      case 'ring': return '#444';
      default: return '#FFF'
    }
  } else { // 日间模式
    switch (item) {
      case 'background': return '#FEFEFE';
      case 'title': return '#969799';
      case 'text': return '#323233';
      case 'tag': return '#d9d9d9';
      case 'linegray1': return '#c8c9cc';
      case 'linegray2': return '#969799';
      case 'ring': return '#DDD';
      default: return '#000'
    }
  }
}
export function seconds2HHmm (seconds, cn) {
  let hour = Math.floor(seconds / 3600);
  let minute = Math.round((seconds - hour * 3600) / 60);
  if (minute >= 60) {
    hour++;
    minute -= 60;
  }
  if (cn) {
    return (hour ? hour + '时' : '') + (minute ? minute + (hour ? '分' : '分钟') : (hour ? '' : (seconds ? '<1分钟' : (seconds === 0 ? '0分钟' : '-'))));
  } else {
    return hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0');
  }
}

function dateID (d) {
  if (d instanceof Date) {
    return formatDateString(d.getTime(), 'yyyyMMdd');
  } else if (d > 1000000000 * 1000) {
    return formatDateString(d, 'yyyyMMdd');
  } else {
    return formatDateString(d * 1000, 'yyyyMMdd');
  }
}

// 将毫秒timestamp转换为格式时间字符串
export function formatDateString (ts, format, cn) {
  const d = new Date(ts);
  switch (format) {
    case 'HHmm':
      // return d.getHours().toString().padStart(2, '0') + ":" + Math.round( (d.getMinutes()*60 + d.getSeconds())/60 ).toString().padStart(2, '0');
      return d.getHours().toString().padStart(2, '0') + ':' + d.getMinutes().toString().padStart(2, '0');
    case 'HHmmss':
      return d.getHours().toString().padStart(2, '0') + ':' + d.getMinutes().toString().padStart(2, '0') + ':' + d.getSeconds().toString().padStart(2, '0');
    case 'yyyyMMddHHmmss':
      return d.getFullYear().toString() +
                '-' + (d.getMonth() + 1).toString().padStart(2, '0') +
                '-' + d.getDate().toString().padStart(2, '0') +
                ' ' + d.getHours().toString().padStart(2, '0') +
                ':' + d.getMinutes().toString().padStart(2, '0') +
                ':' + d.getSeconds().toString().padStart(2, '0');
    case 'w':
      var wname = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
      if (cn) { wname = ['日', '一', '二', '三', '四', '五', '六']; }
      return wname[d.getDay()];
    case 'MMdd':
      if (cn) {
        return (d.getMonth() + 1).toString() + '月' + d.getDate() + '日';
      } else {
        return (d.getMonth() + 1).toString() + '/' + d.getDate().toString();
      }
    case 'yyyyMMdd':
      if (cn) {
        return d.getFullYear().toString() + '年' + (d.getMonth() + 1).toString() + '月' + d.getDate() + '日';
      } else {
        return d.getFullYear().toString() + '-' + (d.getMonth() + 1).toString().padStart(2, '0') + '-' + d.getDate().toString().padStart(2, '0');
      }
    case 'yyyyMMddxx':
      var ampm = d.getHours() > 12 ? 'PM' : 'AM';
      return d.getFullYear().toString() + '-' + (d.getMonth() + 1).toString().padStart(2, '0') + '-' + d.getDate().toString().padStart(2, '0') + ampm;
    default:
      return '';
  }
}
export function getRankColor (report, type, value, age) {
  let rankV2 = null;

  // 如果有后台算好的rank信息，直接使用
  if (report?.new_ranks) {
    switch (type) {
      case 'tst':
        rankV2 = report.new_ranks?.tst;
        break;
      case 'se':
        rankV2 = report.new_ranks?.se;
        break;
      case 'sol':
        rankV2 = report.new_ranks?.sol;
        break;
      case 'waso':
        rankV2 = report.new_ranks?.waso;
        break;
      case 'deep':
        rankV2 = report.new_ranks?.dst;
        break;
      case 'rem':
        rankV2 = report.new_ranks?.rem;
        break;
      case 'sri':
        rankV2 = report.new_ranks?.sri;
        break;
    }
    if (type === 'sri' && rankV2?.score < 0) {
      return ledColor[4];
    }

    const rank = rankV2?.rank;
    switch (rank) {
      case 0:
      case 1:
        return ledColor[0];
      case -1:
      case -2:
        return ledColor[1];
      case -3:
      case -4:
        return ledColor[3];
    }
  }

  if (value != null) {
    age = age || 38;

    switch (type) {
      case 'se':
        if (value >= 85) return ledColor[0];
        if (value >= 75) return ledColor[1];
        if (value > 0) return ledColor[3];
        break;

      case 'tst': {
        const c = 445 - 1.68 * (age - 20);
        if (value >= c) return ledColor[0];
        if (value >= c * 0.67) return ledColor[1];
        return ledColor[3];
      }

      case 'sol':
        if (value > 40) return ledColor[3];
        if (value > 25) return ledColor[1];
        if (value < 5) return ledColor[1];
        return ledColor[0];

      case 'waso':
        if (value > 45) return ledColor[3];
        if (value > 20) return ledColor[1];
        return ledColor[0];

      case 'rem': {
        const c = 480 * (0.2 - 0.001 * (age - 20));
        if (value > 0.85 * c) return ledColor[0];
        if (value > 0.5 * c) return ledColor[1];
        return ledColor[3];
      }

      case 'deep': {
        const c = 480 * (0.2 - 0.002 * (age - 20));
        if (value > 0.85 * c) return ledColor[0];
        if (value > 0.5 * c) return ledColor[1];
        return ledColor[3];
      }

      case 'sri':
        if (value >= 75) return ledColor[0];
        if (value >= 50) return ledColor[1];
        return ledColor[3];
    }
  }

  return ledColor[4]; // gray
}
