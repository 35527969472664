<template>
  <div>
    <div class="container">
      <div class="column">
        <h3>选择数据源</h3>
        <el-select v-model="serverType" placeholder="选择数据源" @change="handleServerTypeChange" style="width: 20%;">
          <el-option label="数据服务器" value="数据服务器"></el-option>
          <el-option label="计算服务器1" value="计算服务器1"></el-option>
        </el-select>
      </div>
    </div>
    <br/>
    <UploadFile :serveType="serverType"/>
    <br/>
    <div style="width: 80%  ; margin: 0 auto;
">
      <table>
        <thead>
        <tr>
          <th>文件名</th>
          <th>大小 (MB)</th>
          <th>上传时间</th>
          <th>操作</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="file in fileData.files" :key="file.name">
          <td>{{ file.name }}</td>
          <td>{{ (file.size).toFixed(2) }}</td>
          <td>{{ file.modified_time }}</td>
          <td>
            <button @click="downloadFile(file.name)">下载</button>
            <button @click="confirmDelete(file.name)">删除</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import UploadFile from "@/components/UploadFile.vue";
import {onMounted, onUnmounted, ref} from 'vue';
import {createAxiosInstance} from "@/plugins/axios";

let fetching = 0; // 变量控制数据获取操作
const fileData = ref({files: []});
const serverType = ref('数据服务器');
// 处理服务器类型变化
const handleServerTypeChange = () => {
  fetchData();
};
// 获取文件列表数据
const fetchData = async () => {
  if (fetching) return
  try {
    fetching = 1;
    const axiosInstance = createAxiosInstance(serverType.value);

    const response = await axiosInstance.get('/file/list/');
    fetching = 0;
    fileData.value = response.data;
  } catch (error) {
    console.error('获取数据失败:', error);
    fetching = 0;
  }
};

// 下载文件
const downloadFile = async (fileName) => {
  try {
    const axiosInstance = createAxiosInstance(serverType.value);

    const response = await axiosInstance.get(`/file/download/${fileName}`, {responseType: 'blob'});
    const blob = new Blob([response.data], {type: 'application/zip'});
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName.endsWith('.zip') ? fileName : `${fileName}.zip`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('下载文件失败:', error);
    alert('下载文件失败，请稍后再试。');
  }
};
const confirmDelete = async (fileName) => {
  const confirmed = window.confirm(`确定要删除文件 "${fileName}" 吗？`);
  if (confirmed) {
    deleteFile(fileName);
  }
};


// 删除文件
const deleteFile = async (fileName) => {
  try {
    const axiosInstance = createAxiosInstance(serverType.value);

    await axiosInstance.get(`/file/delete/${fileName}`);
    fileData.value.files = fileData.value.files.filter(file => file.name !== fileName);
  } catch (error) {
    console.error('删除文件失败:', error);
  }
};
let intervalId = null; // 用于存储定时器ID

onMounted(async () => {
  intervalId = setInterval(fetchData, 1000);

});
onUnmounted(() => {

  if (intervalId) {
    clearInterval(intervalId); // 清除定时器
  }
});
</script>

<style scoped>
button {
  margin-left: 15px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
  text-align: left;
}
</style>
