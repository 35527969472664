import { createStore } from 'vuex';
import { createAxiosInstance, request_data, wdb_get } from './axios';
import { eventBus } from '@/plugins/websocket';

export function loadLocalStorage (d) {
  const res = localStorage.getItem(d) || '{}';
  return JSON.parse(res);
}

export function saveLocalStorage (d, data) {
  const s  = JSON.stringify(data)
  localStorage.setItem(d, s);
}

export const store = createStore({
  state: {
    user: {}, // 用户信息
    isUpdating: false, // 新增：控制批量更新的加载状态
    filter: '', // 首页跳转筛选条件
    systemState: {}, // 系统状态信息
    net_error: '',
    net_err_time: 0,
    timerage: {},
    pageinfo: {},
    jumpData: {},
    orgCache: {}, // 使用普通对象作为缓存
    orgListCache: []// 缓存 org 列表
  },
  mutations: {
    setUser (state, user) {
      if (user && Object.keys(user).length > 0) {
        user.isAdmin = user && user.role === '管理';
        user.isOrg = user && user.role === '机构';
        user.isOrgManager = user && user.role === '机构管理';
        user.isShenzhenHospital = user && user.special > 0;
      }
      state.user = user;
      localStorage.setItem('role',  user.role);

    },
    clearUser (state) {
      state.user = {};
    },
    setSystemState (state, systemState) {
      state.systemState = systemState;
    },
    setNetError (state, net_error) {
      state.net_error = net_error;
      state.net_err_time = new Date().getTime();
    },
    setTimerange(state, start,end) {
      if (!start) state.start = start;
      if (!end) state.end = end;
    },
    // 新增mutations来更新isUpdating状态
    startUpdating (state) {
      state.isUpdating = true;
    },
    stopUpdating (state) {
      state.isUpdating = false;
    },
    setFilter (state, filter) {
      state.filter = filter;
    },
    setJumpData (state, data) {
      state.jumpData = data;
    },
    setOrgCache (state, { org, orgInfo }) {
      state.orgCache[org] = orgInfo;
    },
    setOrgListCache (state, orgList) {
      state.orgListCache = orgList;
    },
    clearOrgCache (state) {
      state.orgCache = {};
      state.orgListCache = [];
    }
  },
  actions: {
    async fetchUser ({ commit }) {
      const response = await request_data('/currentuser/');
      if (response) {
        commit('setUser', response);
      }
    },
    async logout ({ commit }) {
      try {
        const axiosInstance = createAxiosInstance('数据服务器');
        await axiosInstance.post('/logout/'); // 发送登出请求到后端
        commit('clearUser'); // 清除本地用户信息
        // 重定向到登录页面或进行其他操作
      } catch (error) {
        console.error('Error during logout:', error);
      }
    },
    async get_org_list ({ state, commit }) {
      // 检查是否已有缓存的 orgList
      if (state.orgListCache.length > 0) {
        return state.orgListCache;
      }

      // 下载 org_list 并缓存
      const org_list = await wdb_get('org');
      const orgs = [];
      const userRole = state.user.role;

      for (const org of org_list) {
        // 检查 orgCache 中是否已有数据
        if (!state.orgCache[org]) {
          const org_info = await wdb_get('org', org);
          commit('setOrgCache', { org, orgInfo: org_info });
        }
        const org_info = state.orgCache[org];

        // 根据用户角色过滤 org
        if (userRole === '管理' || org_info.type === '队列') {
          orgs.push(org_info.name);
        }
      }

      // 缓存结果并返回
      commit('setOrgListCache', orgs);
      return orgs;
    }
    // 可以在此处添加更多actions
  }
});

function sysinfo (data) {
  const response = data.message.data;
  store.commit('setSystemState', response);
}

eventBus.on('sysinfo', sysinfo);
