import { getColor, getRankColor, seconds2HHmm } from '../utils/utils';

import { locales } from '../utils/locales';

import {
  chart_dates, // 图表横轴的日期与报告数据之间的映射关系
  getInterval
} from './variables.js';

const L = locales['zh-Hans']
const monthNameArray = [L.jan, L.feb, L.mar, L.apr, L.may, L.jun, L.jul, L.aug, L.sep, L.oct, L.nov, L.dec];
export function setDeepChartOption (reports, deep_chart) {
  if (!deep_chart) {
    return;
  }

  const dataREM = [];
  const dataDeep = [];
  const dataXAxis = [];

  // 从日期序列转换到图表数据
  let weekmode = false;
  if (Object.keys(reports).length > 0) {
    const total = Object.keys(chart_dates).length;
    // 如果周期太长，以周为单位计算均值
    if (total <= 60) {
      for (const key in chart_dates) {
        const [month, day] = key.split('/');
        const abbrKey = month + '-' + day;
        const rem = Math.round(reports[key].sleep_dur_2);
        const deep = Math.round(reports[key].sleep_dur_5);
        dataREM.push({ name: abbrKey, value: rem, itemStyle: { color: '#73c0de' } });
        dataDeep.push({ name: abbrKey, value: deep, itemStyle: { color: '#73c0de' } });
        dataXAxis.push(abbrKey);
      }
    } else {
      weekmode = true;
      let [rem_avg, deep_avg, count] = [0, 0, 0];
      let valid = false; // 跳过开头的“半周”
      let weekend = '';
      let birthdate = null;
      for (const key in chart_dates) {
        const bd = chart_dates[key] >= 0 ? reports[chart_dates[key]].birthdate : null;
        if (bd) {
          const bdarr = bd.split('-');
          if (bdarr.length === 2) {
            const d = new Date();
            d.setFullYear(bdarr[0]);
            d.setMonth(bdarr[1] - 1);
            d.setDate(15);
            d.setHours(0, 0, 0, 0);
            birthdate = d;
          }
          break;
        }
      }

      for (let key in chart_dates) {
        const [year, month, day] = key.split('-');
        const d = new Date();
        d.setFullYear(year);
        d.setMonth(month - 1);
        d.setDate(day);
        d.setHours(0, 0, 0, 0);
        const weekday = d.getDay();

        const rem = chart_dates[key] >= 0 ? Math.round(reports[chart_dates[key]].sleep_dur_2) : null;
        const deep = chart_dates[key] >= 0 ? Math.round(reports[chart_dates[key]].sleep_dur_5) : null;
        if (rem != null && deep != null && valid) {
          rem_avg += rem;
          deep_avg += deep;
          count++;
        }
        if (weekday === 6) { // 每周最后一天
          weekend = month + '-' + day;
        }
        if (weekday === 0) { // 每周第一天
          if (valid) {
            rem_avg = count ? Math.round(rem_avg / count) : null;
            deep_avg = count ? Math.round(deep_avg / count) : null;
            key = month + '-' + day + L.to + weekend;

            const age = birthdate ? Math.round(100 * (d - birthdate) / (86400 * 1000 * 365)) / 100 : 38;
            const cr = getRankColor(null, 'rem', rem_avg, age);
            const cd = getRankColor(null, 'deep', deep_avg, age);
            dataREM.push({ name: key, value: rem_avg, itemStyle: { color: cr } });
            dataDeep.push({ name: key, value: deep_avg, itemStyle: { color: cd } });
            dataXAxis.push(key);
            [rem_avg, deep_avg, count] = [0, 0, 0];
            weekend = '';
          } else {
            valid = true;
          }
        }
      }
    }
  }
  dataREM.reverse();
  dataDeep.reverse();
  dataXAxis.reverse();

  const option = {
    animationDuration: 500,
    backgroundColor: 'transparent',
    tooltip: {
      trigger: 'axis',
      confine: true,
      order: 'seriesDesc',
      shadowBlur: 4,
      backgroundColor: getColor('background'),
      textStyle: {
        fontSize: 12,
        lineHeight: 9,
        textShadowBlur: 2,
        textShadowColor: 'transparent',
        color: getColor('text')
      },
      axisPointer: {
        type: 'line',
        label: {
          show: true,
          backgroundColor: '#6a7985'
        }
      },
      valueFormatter: (value) => {
        if (value) {
          return seconds2HHmm(value * 60, true);
        } else {
          return '-';
        }
      }
    },
    legend: {
      data: [{ name: L.rem_duration, icon: 'triangle' }, { name: L.deep_sleep_duration }],
      orient: 'horizontal',
      bottom: 1,
      selectedMode: true,
      icon: 'circle',
      itemHeight: 10,
      itemWidth: 12,
      itemGap: 6,
      textStyle: {
        fontSize: 12,
        padding: [0, 0, 0, -2]
      }
    },
    grid: {
      top: '5%',
      left: '3%',
      right: '3%',
      bottom: weekmode ? '18%' : '12%',
      containLabel: true
    },
    xAxis: [{
      type: 'category',
      boundaryGap: true,
      axisTick: { alignWithLabel: true },
      axisLabel: {
        showMinLabel: true,
        showMaxLabel: true,
        fontSize: 10,
        rotate: weekmode ? 0 : 45,
        interval: weekmode ? 0 : getInterval,
        formatter: (value) => {
          if (weekmode) {
            const [weekstart, weekend] = value.split(L.to);
            const [startMonth, startDay] = weekstart.split('-');
            const [endMonth] = weekend.split('-');
            if (startMonth !== endMonth || startDay === 1) { // 月初
              return monthNameArray[parseInt(endMonth) - 1];
            } else { return '' }
          } else { return value; }
        }
      },
      data: dataXAxis
    }],
    yAxis: {
      type: 'value',
      interval: 60,
      min: function (value) { return Math.floor(value.min / 60) * 60 },
      axisLabel: {
        show: true,
        formatter: (value) => {
          if (value % 60 !== 0) // 非整小时数坐标轴则不显示
          { return null; }
          return Math.round(value / 60) + L.time_hr;
        }
      }
    },
    series: [{
      name: L.rem_duration,
      type: 'line',
      smooth: true,
      // symbol: weekmode ? 'emptyCircle' : (value, params)=>{ return getSymbol(value, params, dataTIB); },
      symbol: 'triangle',
      symbolSize: 6,
      lineStyle: { color: '#73c0de' /* getColor('linegray2') */ },
      itemStyle: { color: '#73c0de' /* getColor('linegray2') */ },
      data: dataREM
    }, {
      name: L.deep_sleep_duration,
      type: 'line',
      smooth: true,
      // symbol: weekmode ? 'emptyCircle' : (value, params)=>{ return getSymbol(value, params, dataTST); },
      symbol: 'circle',
      symbolSize: 5,
      lineStyle: { color: getColor('linegray1') },
      itemStyle: { color: getColor('linegray1') },
      data: dataDeep
    }]
  };
  deep_chart.setOption(option);
}
