<template>
    <el-select v-model="selectedValue" @change="handleChange" :style="{ width: props.width }">
        <el-option v-for="option in options.data"
                   :key="option"
                   :label="option"
                   :value="option">
        </el-option>
    </el-select>

</template>

<script setup>
import { wdb_get_new, request_data } from '@/plugins/axios'; // 假设这是获取axios实例的composable

import { ref, watch, defineProps, defineEmits, watchEffect } from 'vue';
import { ElSelect, ElOption } from 'element-plus';

const props = defineProps({
  selected: {
    type: String,
    required: true
  },
  tag: {
    type: String,
    default: null
  },
  item: {
    type: String,
    default: null
  },
  db: {
    type: String,
    default: null
  },
  url: {
    type: String,
    default: null
  },
  width: {
    type: String,
    default: '200px'
  },
  server: {
    type: String,
    default: '数据服务器'
  }
});

const emit = defineEmits(['update:selected', 'change']);

const selectedValue = ref(props.selected);
const options = ref({ data: [] });

watch(() => props.selected, (newValue) => {
  selectedValue.value = newValue;
});

const handleChange = (value) => {
  emit('update:selected', value);
  emit('change', value)
};

const fetchData = async () => {
  if (props.url) {
    if (props.url !== '-') { options.value.data = await request_data(props.url, props.server); }
    return
  }
  let values = await wdb_get_new(props.server, props.db, props.item, props.tag);
  if (props.item) {
    const newvalues = []
    for (const i in values) {
      if (i !== 'refresh_time' && i !== 'time_diff' && i !== 'modified' && !values[i].hide) { newvalues.push(i) }
    }
    options.value.data = newvalues
  } else {
    if (typeof values === 'object' && values !== null && !Array.isArray(values)) {
      values = Object.keys(values);
    }
    options.value.data = values
    // 如果value是一个对象，需要转换为数组
  }
};

const fetchDataEffect = async () => {
  try {
    const data = await fetchData();
    console.log(data);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

watchEffect(() => {
  fetchDataEffect();
});

</script>
