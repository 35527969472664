import { getColor, seconds2HHmm, formatDateString, ledColor } from '../utils/utils';

let hours_chart = null;
let hours_data = null;

function initDayHoursChart(canvas, width, height, dpr) {
    var theme = null;
    try{
        let info = wx.getAppBaseInfo();
        theme = info.theme || null;
    }catch(e) {}

    const chart = echarts.init(canvas, theme, {
        width: width,
        height: height,
        devicePixelRatio: dpr,
        renderer: 'svg',
    });
    hours_chart = chart;
    // 自动关闭tooltip
    let timer = null;
    chart.getZr().handler.on('mouseup', function(e){
        timer = setTimeout(()=>{
            chart.dispatchAction({
                type: 'hideTip'
            })
            chart.dispatchAction({
                type: 'updateAxisPointer',
                currTrigger: 'leave',
            })
        }, 4000);
    })
    chart.getZr().handler.on('mousedown', function(e){
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    })
    
    setDayHoursChartOption();
    return chart;
}

export function setDayHoursChartOption(hours_chart, hours_data) {
     if (!hours_chart || !hours_data) {
        return;
    }

    let data = [];
    let cdata = [];
    let indexHours = ['未', '15', '申', '17', '酉', '19', '戌', '21', '亥', '23', '子', '1', '丑', '3', '寅', '5', '卯', '7', '辰', '9', '巳', '11', '午', '13'];

    if (hours_data && hours_data.hours && hours_data.hours.length>0) {
        for (let i = 0; i<hours_data.hours.length; i++) {
            for (let j=0; j<indexHours.length; j++) {
                if (indexHours[j] in hours_data.hours[i]) {
                    let idx = Math.round(hours_data.hours[i][indexHours[j]] / 2);
                    let table = ['子', '丑', '寅', '卯', '辰', '巳', '午', '未', '申', '酉', '戌', '亥'];
                    if (idx>-1 && idx<12) {
                        data.push([ indexHours[j], table[idx] ]);
                        break;
                    }
                }
            }
        }
    }
    if (hours_data && hours_data.chours && hours_data.chours.length>0) {
        for (let i = 0; i<hours_data.chours.length; i++) {
            let keys = Object.keys(hours_data.chours[i]);
            if (keys.length==1) {
                let key = keys[0];
                let cnKey = null;
                if (key%2==0) {
                    let table = ['子', '丑', '寅', '卯', '辰', '巳', '午', '未', '申', '酉', '戌', '亥'];
                    cnKey = table[key/2];
                }
                let p = 10 + hours_data.chours[i][ key ];
                if (p>24) { p = p - 24; }
                cdata.push([ cnKey ? cnKey : key, p ]);
            }
        }
    }
    //console.log(hours_data);

    var option = {
        backgroundColor: 'transparent',
        animationDuration: 500,
        title: {
          text: '时辰偏差',
          padding: [0, 0, 0, 32],
            textStyle: {
                fontWeight: 'normal',
                fontSize: 12,
            }
        },
        grid: {
          left: '6%',
          right: '3%',
          top: '8%',
          bottom: '6%',
          containLabel: true
        },
        xAxis: [{
            type: 'category',
            name: '真实时辰',
            nameLocation: 'middle',
            nameGap: 24,
            data: indexHours,
            axisLabel: { interval: 1, },
            axisTick: { interval: 0, },
            boundaryGap: false,
            splitLine: { show: true },
        }, {
            type: 'category',
            data: ['小肠', 0, '膀胱', 0, '肾', 0, '心包', 0, '三焦', 0, '胆', 0, '肝', 0, '肺', 0, '大肠', 0, '胃', 0, '脾', 0, '心', 0],
            position: 'top',
            axisLabel: {
                interval: 1,
                rotate: -45,
                fontSize: 8,
            },
            boundaryGap: false,
        }],
        yAxis: [{
            type: 'category',
            name: '生理时辰',
            nameLocation: 'middle',
            nameGap: 24,
            data: indexHours,
            axisLabel: { interval: 1, },
            axisTick: { interval: 0, },
            boundaryGap: false,
            splitLine: { show: true },
        }, {
            type: 'category',
            data: ['小肠', 0, '膀胱', 0, '肾', 0, '心包', 0, '三焦', 0, '胆', 0, '肝', 0, '肺', 0, '大肠', 0, '胃', 0, '脾', 0, '心', 0],
            position: 'right',
            axisLabel: {
                interval: 1,
                rotate: 45,
                fontSize: 8,
            },
            boundaryGap: false,
        }],
        series: [{
            name: '时辰',
            type: 'custom',
            data: data,
            renderItem: (params, api)=>{
                const ptTarget = api.coord([api.value(0), api.value(1)]);
                const ptBase = api.coord([api.value(0), api.value(0)]);
                const circleSize = api.size([0, 1])[0] * 0.3;
                const vBase = api.value(0);
                const vTarget = api.value(1);

                return {
                    type: 'group',
                    children: [ { // 目标
                        type: 'circle',
                        shape: {
                            cx: ptTarget[0],
                            cy: ptTarget[1],
                            r: circleSize,
                        },
                        z2: 10,
                        style: api.style({
                            fill: '#73c0de',
                        })
                    },{ // 基点
                        type: 'line',
                        shape: {
                            x1: ptBase[0] - circleSize,
                            y1: ptBase[1],
                            x2: ptBase[0] + circleSize,
                            y2: ptBase[1],
                        },
                        z2: 10,
                        style: api.style({
                            stroke: '#73c0de',
                            lineWidth: 2,
                        })
                    },{ // 目标与基点的连线
                        type: 'line',
                        shape: {
                          x1: ptBase[0],
                          y1: ptBase[1],
                          x2: ptTarget[0],
                          y2: ptTarget[1],
                        },
                        z2: 10,
                        style: api.style({
                            stroke: '#73c0de',
                            lineWidth: 2,
                        })
                    },{ // 指向目标的箭头左侧
                        type: 'line',
                        shape: {
                          x1: ptTarget[0] - circleSize,
                          y1: ptTarget[1] - 3*circleSize * ( vTarget>vBase ? -1 : (vTarget<vBase ? 1 : 0) ),
                          x2: ptTarget[0],
                          y2: ptTarget[1] - circleSize * ( vTarget>vBase ? -1 : (vTarget<vBase ? 1 : 0) ),
                        },
                        z2: 10,
                        style: api.style({
                            stroke: '#73c0de',
                        })
                    },{ // 指向目标的箭头右侧
                        type: 'line',
                        shape: {
                          x1: ptTarget[0] + circleSize,
                          y1: ptTarget[1] - 3*circleSize * ( vTarget>vBase ? -1 : (vTarget<vBase ? 1 : 0) ),
                          x2: ptTarget[0],
                          y2: ptTarget[1] - circleSize * ( vTarget>vBase ? -1 : (vTarget<vBase ? 1 : 0) ),
                        },
                        z2: 10,
                        style: api.style({
                            stroke: '#73c0de',
                        })
                    }]
                }
            },
            //itemStyle: { color: '#73c0de' },
            markLine: {
                silent: true,
                symbol: 'none',
                data: [ [{ coord:[0, 0] }, { coord:[23, 23] }] ],
                lineStyle: { type: 'dashed', opacity: 0.5, color: '#CCC' },
                label: { show: false }
            },
            encode: {
                x: 0,
                y: 1,
            },
        }, {
            name: '小时',
            type: 'custom',
            data: cdata,
            renderItem: (params, api)=>{
                let ptTarget = api.coord([api.value(0), 0]);
                ptTarget[1] = ptTarget[1] - api.size([0, 1])[1]*api.value(1);
                const ptBase = api.coord([api.value(0), api.value(0)]);
                const circleSize = api.size([0, 1])[0] * 0.2;
                const vBase = api.value(0);
                const vTarget = api.value(1);

                return {
                    type: 'group',
                    children: [ {
                        type: 'line',
                        shape: {
                          x1: ptTarget[0] - circleSize,
                          y1: ptTarget[1] - circleSize,
                          x2: ptTarget[0] + circleSize,
                          y2: ptTarget[1] + circleSize,
                        },
                        z2: 12,
                        style: api.style({
                            stroke: ledColor[3],
                        })
                    },{
                        type: 'line',
                        shape: {
                          x1: ptTarget[0] - circleSize,
                          y1: ptTarget[1] + circleSize,
                          x2: ptTarget[0] + circleSize,
                          y2: ptTarget[1] - circleSize,
                        },
                        z2: 12,
                        style: api.style({
                            stroke: ledColor[3],
                        })
                    } ]
                }
            },
            encode: {
                x: 0,
                y: 1,
            },
        }]
      };

      hours_chart.setOption(option);
}