<template>
  <el-card class="filter-card">
    <div class="filter-container">
      <!-- 下拉选择器的容器 -->
      <div class="selects-container">
        <div class="filter-select">
          <el-input v-model="inputFilter" @input="onInputFilterChange" placeholder="关键字" clearable></el-input>
        </div>
        <div v-if="!isOrg">
          <div v-for="(options, key) in selects" :key="`select-${key}`" class="filter-select">
            <el-text>{{ names[key] }}:</el-text>
            <el-select v-model="selectedFilters[key]" :placeholder="`请选择${names[key]}`"
                       :disabled="key === disable_key ? isOrgbindDisabled : false">
              <el-option label="不限" value=""></el-option>
              <el-option v-for="option in options" :key="option" :label="option" :value="option"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="tag-group-container">
        <div v-for="(tags, groupName) in taggroups" :key="`group-${groupName}`" class="tags-group">
          <el-text>{{ groupName }}</el-text>
          <div class="tags-container">
            <div v-for="(tagValue, tagKey) in tags" :key="`tag-${tagKey}`" class="filter-tag">
              <el-tag :type="selectedTags[tagKey] ? 'success' : 'primary'" @click="toggleTag(tagKey)">
                {{ tagKey }}
              </el-tag>
            </div>
          </div>
        </div>
      </div>

    </div>
  </el-card>
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits, computed, watch } from 'vue';
import { ElCard, ElSelect, ElOption, ElInput, ElTag } from 'element-plus';
import { useStore } from 'vuex'

import { request_data } from '@/plugins/axios';
import { useRoute } from 'vue-router';
import { wdb_get } from '@/plugins/axios.js';

const user = computed(() => store.state.user)

const isOrg = computed(() => user.value && user.value.role === '机构')
const emit = defineEmits(['filter-changed', 'input-changed']);
// 定义props接收item
const props = defineProps({
  item: String
});
const names = ref({})
const filters = ref({});
const selects = ref({});
const taggroups = ref({});
const inputFilter = ref('')
const selectedFilters = ref({});
const selectedTags = ref({});
const route = useRoute();
const isOrgbindDisabled = ref()
const store = useStore();
const userOrg = store.state.user.org
const userRole = store.state.user.role
const disable_key = ref('')

/*
async function get_org_list () {
  const org_list = await wdb_get('org'); //
  const orgs = []; //

  for (const org of org_list) {
    const org_info = await wdb_get('org', org); //
    const userRole = store.state.user.role

    if (userRole === '管理') {
      orgs.push(org_info.name); //
    } else {
      if (org_info.type === '队列') {
        orgs.push(org_info.name); //
      }
    }
  }
  return orgs; // 返回符合条件的 orgs 数组
}
*/
// const userOrg = '上海六院'
function removeKeyRecursive (obj, keyToRemove) {
  for (const key in obj) {
    if (key === keyToRemove) {
      delete obj[key];
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      removeKeyRecursive(obj[key], keyToRemove);
    }
  }
}

function applyFilters () {
  let filterStr = Object.entries(selectedFilters.value)
  // eslint-disable-next-line no-unused-vars
    .filter(([key, value]) => value !== null && value !== '')
    .map(([key, value]) => `${key}=${value}`)
    .join(' AND ');

  const selectedTagStr = Object.entries(selectedTags.value)
  // eslint-disable-next-line no-unused-vars
    .filter(([key, value]) => value)
    .map(([key]) => 'all_tags.' + key)
    .join(' AND ');

  if (filterStr && selectedTagStr) {
    filterStr += ' AND ' + selectedTagStr;
  } else if (!filterStr && selectedTagStr) {
    filterStr = selectedTagStr;
  }

  if (inputFilter.value) {
    if (filterStr) { filterStr = 'search*' + inputFilter.value + ' AND ' + filterStr; } else { filterStr = 'search*' + inputFilter.value; }
  }
  // 在用户修改输入框时修改跳转缓存
  const jumpData = store.state.jumpData

  if (jumpData.value && jumpData.filter_type === 'input' && jumpData.path === route.path) {
    store.commit('setJumpData', {
      type: 'device',
      value: inputFilter.value,
      filter_type: 'input',
      path: route.path
    })
  }
  // 使用emit触发一个自定义事件，将筛选条件作为参数传递
  emit('filter-changed', filterStr);
}

async function fetchFilters (item) {
  try {
    const data = await request_data(`/sysfilter/${item}/`);

    filters.value = data.fiters;
    // 初始化selects和tags

    for (const key in data.selects) {
      const n = key.split('=');

      if (key === 'deviceinfo.orgbind=机构') {
        selects.value[n[0]] = await store.dispatch('get_org_list');
        console.log(selects.value)
      } else {
        selects.value[n[0]] = data.selects[key];
      }
      names.value[n[0]] = n[1];
      selectedFilters.value[key] = null;
    }
    for (const tp in data.filters) {
      const t = {};
      for (const key in data.filters[tp]) {
        selectedTags.value[key] = false;
        t[data.filters[tp][key]] = data.filters[tp][key];
      }
      taggroups.value[tp] = t;
      if (route.path === '/device-management') {
        removeKeyRecursive(taggroups, '机构标签');
        removeKeyRecursive(taggroups, '特殊标签');
      }
    }

    // 设置默认的 filter 值
    const orgs = data.selects['deviceinfo.orgbind=机构']

    if (userOrg && orgs && orgs.includes(userOrg) && !isOrg.value) {
      selectedFilters.value['deviceinfo.orgbind'] = userOrg;
      disable_key.value = 'deviceinfo.orgbind'
      isOrgbindDisabled.value = true; // 设置为禁用
    }
    if (userRole && userRole === '干预枕运营') {
      selectedFilters.value['deviceinfo.type'] = '干预枕';
      disable_key.value = 'deviceinfo.type'

      isOrgbindDisabled.value = true; // 设置为禁用
    }
  } catch (error) {
    console.error('Error fetching filters:', error);
  }
}

function toggleTag (key) {
  selectedTags.value[key] = !selectedTags.value[key];

  if (route.path === '/device-management') {
    if (!selectedTags.value[key]) {
      store.commit('setJumpData', '');
    }
  }
}

onMounted(() => {
  const jump_data = store.state.jumpData
  const path = jump_data.path
  if (route.path === path) {
    const filter_type = jump_data.filter_type
    let filter
    if (jump_data) {
      if (filter_type === 'input') {
        inputFilter.value = jump_data.value
        applyFilters();
      } else {
        filter = jump_data.value;

        if (filter_type === 'tag') {
          selectedTags.value[filter] = true;
        }
        selectedFilters.value['deviceinfo.workstate'] = '可用';
        disable_key.value = 'deviceinfo.workstate'
        selectedTags.value['近期在线'] = true;

        if (filter.includes('VIP')) {
          selectedFilters.value['deviceinfo.level'] = 'VIP';
          disable_key.value = 'deviceinfo.level'
        }
      }
    }
  }

  fetchFilters(props.item);
});

// 监视selectedFilters的变化
watch(selectedFilters, () => {
  store.commit('setJumpData', '');

  applyFilters();
}, { deep: true }); // 使用deep选项来深度监视对象内部的变化

// 监视selectedTags的变化
watch(selectedTags, () => {
  applyFilters();
}, { deep: true });

function onInputFilterChange () {
  // 当inputFilter变更时，向父组件发起变更事件
  applyFilters();
}

</script>

<style scoped>
.filter-card {
  display: flex;
  flex-direction: column;
  height: auto
}

.filter-container {

  display: flex;
  flex-direction: row;
}

.selects-container {
  /* 根据需要调整，这里只是示例 */
  flex: 0 1 auto; /* 让这个容器根据内容调整大小，但不超过其内容的大小 */
  padding: 10px;

}

.tag-group-container {
  flex: 1; /* 让标签组容器占据剩余空间 */
  overflow: auto; /* 如果内容超出容器高度，则允许滚动 */
  display: flex;
  flex-direction: row;
  padding: 10px;

}

.tags-group {
  flex: 1; /* 确保每个标签组平均分配空间 */
  display: flex;
  flex-direction: column; /* 使得 groupName 和 tags 垂直排列 */
  margin-bottom: 3px; /* 最后一个标签组可能不需要底部边距 */
  border-left: 2px solid #ccc; /* 示例边框样式，根据需要调整颜色和宽度 */
  padding-right: 20px; /* 添加一些内边距以避免文本紧贴边框 */
  margin-right: 20px; /* 如果需要在分组之间增加一些外边距 */
}

.tags-container {
  flex: 1; /* 确保 tags-container 占据剩余的空间 */
  display: flex;
  flex-wrap: wrap; /* 允许标签换行 */
  align-content: flex-start; /* 从顶部开始排列标签 */
}

.filter-tag {
  margin-right: 5px; /* 标签之间的右边距 */
  margin-bottom: 5px; /* 标签之间的底部边距 */
}

</style>
