<template>

    <el-card>
        <h1>组件：TreeViewObj</h1>
        <pre>
                    显示树形结构的组件。
                    输入参数：
                    1) data : 树形结构json。格式样例：[{label: 'xxx', children: [{label: 'yyy', children: []}]}]
                    2) expand：是否展开所有节点，默认为false

                    一个细节：如果内容是timestamp，会自动转换为日期时间格式。
                </pre>
        <h2>使用样例</h2>
        <pre>
        <code>
                        &lt;template&gt;
                            &lt;TreeViewObj :data="data" :expand="true" /&gt;
                        &lt;/template&gt;

                        &lt;script setup&gt;
                            import { ref } from 'vue';
                            const data = ref([
                                {
                                    label: '一级节点1',
                                    children: [
                                        {
                                            label: '二级节点1',
                                            children: [
                                                {
                                                    label: '三级节点1'
                                                },
                                                {
                                                    label: '三级节点2'
                                                }
                                            ]
                                        },
                                        {
                                            label: '二级节点2'
                                        }
                                    ]
                                },
                                {
                                    label: '一级节点2'
                                }
                            ]);
                        &lt;/script&gt;
                    </code>
                </pre>
        效果如下：
        <TreeViewObj :data="data" :expand="true" />
    </el-card>
    <el-card>
        <h1>方法: request_data @ axios.js(</h1>
        <pre>
                    用于向后端请求数据。
                    输入参数：
                    url：请求的url，不包括前缀，例如 device/
                    返回：结果中的request.data.data部分。自带异常处理
                </pre>
        <h2>使用样例</h2>
        <pre>
        <code>
                        &lt;script setup&gt;
                            import { request_data } from '@/plugins/axios.js';
                            const data = request_data('device/');
                        &lt;/script&gt;
                    </code>
                </pre>

    </el-card>
    <el-card>
        <h1>方法: wdb_get @ axios.js(</h1>
        <pre>
                    用于向后端请求数据，获取wdb库。
                    输入参数：
                    db：数据库名。
                    item：条目名
                    tag：标签名

                    返回：根据参数不同，返回不同的结果。
                    1）当db为空时，返回所有数据库名Array
                    2）当db不为空，item为空时，返回该数据库的所有条目Array。
                            相当于执行wdb_list_item(db)
                    3）当db、item不会空，tag为空时，返回该数据库的该item。
                            相当于执行wdb_load_item(db,item)
                    4）当db、item、tag均不为空时，相当于执行wdb_get_tag(db,item,tag)
                </pre>
        <h2>使用样例</h2>
        <pre>
        <code>
                        &lt;script setup&gt;
                            import { wdb_get } from '@/plugins/axios.js';
                            const data = wdb_get('device',"2311223");
                        &lt;/script&gt;
                    </code>
                </pre>
    </el-card>
    <el-card>
        <h1>方法: wdb_get_history @ axios.js(</h1>
        <pre>
            用于向后端请求数据，获取wdb库历史信息。
            输入参数：
            db：数据库名。
            item：条目名
            history：历史范围，默认0

            返回：相当于执行wdb_load_item(db,item,history)

        </pre>
        <h2>使用样例</h2>
        <pre>
            <code>
                &lt;script setup&gt;
                import { wdb_get_history } from '@/plugins/axios.js';
                const data = wdb_get_history('device',"2311223",60);
                &lt;/script&gt;
            </code>
        </pre>
    </el-card>
    <el-card>
        <h1>组件：RemoteSelect</h1>
    </el-card>
    <el-card>
        <h1>组件：DownloadDialog</h1>
    </el-card>
    <el-card>
        <h1>组件：TagViewer</h1>
    </el-card>

</template>

<script setup>

import { ref } from 'vue';

import TreeViewObj from '@/common/TreeViewObj.vue';
import {
  add_time_buffer,
  create_empty_time_buffer,
  create_time_buffer
} from '@/plugins/timebuffer';

const data = ref([
  {
    label: '一级节点1',
    children: [
      {
        label: '二级节点1',
        children: [
          {
            label: '三级节点1'
          },
          {
            label: '三级节点2'
          }
        ]
      },
      {
        label: '二级节点2'
      }
    ]
  },
  {
    label: '一级节点2'
  }
]);

const starttime = 1698865200.5; // 示例开始时间，Unix 时间戳，以秒为单位，可以有小数部分
const datalen = 3600 * 20; // 示例数据长度，3600 的整数倍
const dataArray = new Float32Array(datalen).map(() => Math.random() * 100); // 示例数据
const dataBlock = create_time_buffer(starttime, dataArray, datalen);

const emptyDataBlock = create_empty_time_buffer(starttime, datalen, dataBlock);

// 示例调用 add_time_buffer
const sourceStarttime = 1698865200.5; // 示例开始时间，Unix 时间戳
const sourceDataArray = new Float32Array(3600 * 5).map(() => Math.random() * 50); // 5 小时的数据
const sourceDataBlock = create_time_buffer(sourceStarttime, sourceDataArray, 3600 * 5);

add_time_buffer(emptyDataBlock, sourceDataBlock);

console.log(emptyDataBlock);

// 示例调用 create_time_buffer_fragment
</script>
<style scoped>
    .el-header, .el-footer {
        background-color: #B3C0D1;
        color: #333;
        text-align: center;
        line-height: 60px;
    }

    .box-card {
        margin-bottom: 20px;
        transition: 0.3s ease box-shadow;
    }

    .charts-container {
        display: flex;
        justify-content: space-around; /* 使图表之间有一些间隔 */
        flex-wrap: wrap; /* 允许图表在小屏幕上换行 */
    }

    .fault-types-chart, .device-chart {
        flex: 1; /* 使两个图表平等地分配空间 */
        min-width: 300px; /* 设置最小宽度，防止图表变得太小 */
    }

    .stat-item {
        text-align: center;
    }

        .stat-item h3 {
            margin: 0;
            font-size: 24px;
            color: #333;
        }

        .stat-item p {
            margin: 0;
            font-size: 14px;
            color: #666;
        }

    .event-list-card {
        margin-bottom: 20px;
    }

    .history-chart-card {
        margin-top: 30px;
    }
</style>
