import { getColor, ledColor } from '../utils/utils.js';

export function setAHIChartOption (reports, ahi_chart, chart_dates) {
  if (!ahi_chart) {
    return;
  }

  const dataAHI = [];
  const dataXAxis = [];

  let minAHI = 50;
  let maxAHI = 0;
  let intervalAHI = 10;
  // 从日期序列转换到图表数据
  if (reports && Object.keys(reports).length > 0) {
    for (const key in chart_dates) {
      var ahi;

      if (reports[key] && Object.keys(reports[key]).length > 0) {
        ahi = parseFloat((reports[key].ahi).toFixed(1));
      } else {
        ahi = null;
      }
      dataAHI.push({ name: key, value: ahi });
      dataXAxis.push(key);
      if (ahi < minAHI) {
        minAHI = ahi;
      }
      if (ahi > maxAHI) {
        maxAHI = ahi;
      }
    }
  }
  dataAHI.reverse();
  dataXAxis.reverse();
  if ((maxAHI - minAHI) / 10 > 5) {
    intervalAHI = 20;
  }

  const endPos = dataXAxis.length - 1;
  const startPos = (endPos - 29) >= 0 ? (endPos - 29) : 0;

  const option = {
    animationDuration: 500,
    backgroundColor: 'transparent',
    title: {
      text: '近期趋势',
      padding: [2, 0, 0, 24],
      textStyle: {
        fontWeight: 'normal',
        fontSize: 12
      }
    },
    tooltip: {
      trigger: 'axis',
      // triggerOn: 'click',
      confine: true,
      order: 'seriesDesc',
      shadowBlur: 4,
      backgroundColor: getColor('background'),
      textStyle: {
        fontSize: 12,
        lineHeight: 9,
        textShadowBlur: 2,
        textShadowColor: 'transparent',
        color: getColor('text')
      },
      axisPointer: {
        type: 'line',
        label: {
          show: true,
          backgroundColor: '#6a7985'
        }
      }
    },
    grid: {
      top: '16%',
      left: '3%',
      right: '3%',
      bottom: '0%',
      containLabel: true
    },
    xAxis: [{
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        showMinLabel: true,
        showMaxLabel: true,
        fontSize: 10,
        rotate: 45,
        interval: 0
      },
      data: dataXAxis
    }],
    yAxis: {
      type: 'value',
      min: 0,
      minInterval: intervalAHI,
      axisLabel: {
        show: true
      }
      // z: 10,
    },
    dataZoom: {
      type: 'inside',
      zoomLock: true,
      rangeMode: 'value',
      startValue: startPos,
      endValue: endPos,
      minValueSpan: 30
    },
    visualMap: {
      show: false,
      dimension: 1,
      pieces: [
        { lt: 5, color: ledColor[0] },
        { gte: 5, lt: 15, color: ledColor[1] },
        { gte: 15, lt: 30, color: ledColor[2] },
        { gte: 30, color: ledColor[3] }
      ]
    },
    series: [{
      name: 'AHI',
      type: 'line',
      itemStyle: { color: '#df6047' },
      symbol: 'emptyCircle',
      // showAllSymbol: 'true',
      smooth: true,
      data: dataAHI,
      markArea: {
        silent: true,
        label: {
          position: 'insideTopLeft',
          color: getColor('title'),
          fontSize: intervalAHI > 10 ? 8 : 12,
          distance: intervalAHI > 10 ? 2 : 5
        },
        itemStyle: {
          color: ledColor[0],
          opacity: 0.2
        },
        data: [[{ name: '正常', yAxis: 0 }, { yAxis: 5 }]]
      }
    }, {
      name: '轻度',
      type: 'line',
      itemStyle: { color: ledColor[1] },
      markArea: {
        silent: true,
        label: {
          position: 'insideTopLeft',
          color: getColor('title'),
          fontSize: intervalAHI > 10 ? 8 : 12,
          distance: intervalAHI > 10 ? 2 : 5
        },
        itemStyle: {
          color: ledColor[1],
          opacity: 0.2
        },
        data: [[{ name: '轻度', yAxis: 5.1 }, { yAxis: 15 }]]
      }
    }, {
      name: '中度',
      type: 'line',
      itemStyle: { color: ledColor[2] },
      markArea: {
        silent: true,
        label: {
          position: 'insideTopLeft',
          color: getColor('title'),
          fontSize: intervalAHI > 10 ? 8 : 12,
          distance: intervalAHI > 10 ? 2 : 5
        },
        itemStyle: {
          color: ledColor[2],
          opacity: 0.2
        },
        data: [[{ name: '中度', yAxis: 15.1 }, { yAxis: 30 }]]
      }
    }, {
      name: '重度',
      type: 'line',
      itemStyle: { color: ledColor[3] },
      markArea: {
        silent: true,
        label: {
          position: 'insideTopLeft',
          color: getColor('title'),
          fontSize: intervalAHI > 10 ? 8 : 12,
          distance: intervalAHI > 10 ? 2 : 5
        },
        itemStyle: {
          color: ledColor[3],
          opacity: 0.2
        },
        data: [[{ name: '重度', yAxis: 30.1 }, { yAxis: 200 }]]
      }
    }]
  };
  ahi_chart.setOption(option);
  // console.log("AHI chart optionized with: " + reports.length + " reports");
}
