<template>
  <div>
    <!-- 检查是否是对象或数组，不是则直接显示 -->
    <div v-if="!isObject(data) && !isArray(data)">
      {{ formatValue(data) }}
    </div>
    <!-- 是对象或数组时，按原有逻辑显示 -->
    <ul v-else>
      <li v-for="(value, key) in data" :key="key">
        <!-- 对象类型的处理 -->
        <template v-if="isObject(value) && !isEmpty(value)">
          <strong @click="toggleExpand(key)">
            <span>{{ isExpanded(key) ? '-' : '+' }}</span>
            {{ key }}:
          </strong>
          <div v-if="isExpanded(key)">
            <TreeViewObj :data="value" :expand="props.expand"/>
          </div>
        </template>

        <!-- 数组类型的处理 -->
        <template v-if="isArray(value) && value.length">
          <strong @click="toggleExpand(key)">
            <span>{{ isExpanded(key) ? '-' : '+' }}</span>
            {{ key }}: ({{ value.length }} 个元素)
          </strong>
          <ul v-if="isExpanded(key)">
            <li v-for="(item, index) in value" :key="index">
              [{{ index }}]
              <TreeViewObj :data="item" :expand="props.expand"/>
            </li>
          </ul>
        </template>

        <!-- 其他类型的处理 -->
        <template v-if="!isObject(value) && !isArray(value)">
          {{ key }}: {{ formatValue(value) }}
        </template>
      </li>
    </ul>
  </div>
</template>


<script setup>
import {reactive, defineProps} from 'vue';
import {formatDateTime} from '@/plugins/timetool.js';

// props
const props = defineProps({
  data: Object,
  expand: {
    type: Boolean,
    default: false,
  }
});


// 用于跟踪每个节点的展开状态
const expandState = reactive({});

function isObject(value) {
  return value && typeof value === 'object' && !Array.isArray(value);
}

function isArray(value) {
  return Array.isArray(value);
}

function isEmpty(value) {
  // 检查对象或数组是否为空
  return isObject(value) ? Object.keys(value).length === 0 : isArray(value) ? value.length === 0 : true;
}

function isNumber(str) {
  return /^-?\d+(\.\d+)?$/.test(str);
}

const formatValue = (value) => {
  // 检查是否为浮点数时间戳
  if (typeof value == "string" && value.length < 15 && isNumber(value)) {
    const v = parseFloat(value);
    if (!isNaN(v)) {
      value = v;
    }
  }

  // 如果是浮点数时间戳
  if (typeof value === 'number' && !isNaN(value) && value > 1672530000 && value < 2032704000) {
    const date = new Date(Math.round(value * 1000)); // 将秒转换为毫秒
    const startOf2022 = new Date('2022-01-01T00:00:00Z');
    if (date >= startOf2022) {
      // 使用中文日期格式
      return formatDateTime(date / 1000);
    } else {
      return value.toFixed(3); // 时间戳表示的日期早于2022年，显示为原始浮点数，保留三位小数
    }
  }

  // 如果是其他浮点数类型
  if (typeof value === 'number' && !isNaN(value) && value % 1 !== 0) {
    return value.toFixed(2); // 保留两位小数
  }

  return value;
};

function toggleExpand(key) {
  // 切换节点的展开/折叠状态
  expandState[key] = !expandState[key];
}

function isExpanded(key) {
  // 返回节点是否展开
  if (expandState[key] === undefined) {
    expandState[key] = props.expand;
  }
  return expandState[key];
}

</script>

<style scoped>
ul {
  list-style-type: none;
  padding-left: 20px;
}

strong {

}
</style>
