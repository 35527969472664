<template>
  <div :id="chartId" style="width: 100%; height: 400px;"></div>
</template>

<script setup>
import {onMounted, watch,ref,defineProps} from 'vue';
import * as echarts from 'echarts';

const props = defineProps({
  chartData: {
    type: Object,
    required: true
  },
  chartId: {
    type: String,
    required: true
  },
    chartName: {
    type: Object,
    required: true
  }
});

function repaint() {
  const chartId = props.chartId;
  if (!chartId) {
    return;
  }
  const chartDom = document.getElementById(chartId);
  const myChart = echarts.init(chartDom);
  myChart.setOption({
    // 设置柱状图的配置，使用传入的 chartData
    xAxis: {
      type: 'category',
      data: Object.keys(props.chartData)
    },
    yAxis: {
      type: 'value',
      name: props.chartName['yAxisName']
    },
    series: [
      {
        name:props.chartName['seriesName'],
        type: 'bar',
        data: Object.values(props.chartData)
      }
    ],
    tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    },
    markLine: null // 移除标记线

  },
  });
}
const chartData = ref(props.chartData);
onMounted(() => {

  repaint();
});
watch(() => props.chartData, (newVal) => {
  chartData.value = newVal;
  repaint();  //这里不是响应式的，所以需要手动调用repaint

});
</script>
