<template>
  <div class="login-overlay" v-show="isVisible">
    <div class="login-box">
      <!-- 直接使用 @submit.prevent 监听表单提交 -->
      <el-form ref="loginForm" @submit.prevent="handleLogin">
        <h3 class="login-title">登录</h3>
        <el-form-item label="账号">
          <el-input v-model="loginForm.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input type="password" v-model="loginForm.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item class="login-action">
          <!-- 设置按钮类型为 submit -->
          <el-button type="primary" native-type="submit">登录</el-button>
        </el-form-item>
        <div v-if="loginError" class="login-error">{{ loginError }}</div>
      </el-form>
    </div>
  </div>
</template>

<script>

import {clearAxiosInstances, createAxiosInstance} from '@/plugins/axios';
import md5 from 'md5';

export default {
  props: {
    isVisible: Boolean
  },
  data () {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginError: false
    };
  },
  methods: {
    async handleLogin () {
      event.preventDefault(); // 阻止表单默认提交行为
      this.loginError = false; // 重置登录错误状态
      try {
        const axios = createAxiosInstance('数据服务器');

        // 创建一个新的对象来存储要发送的数据，避免修改原始表单数据
        const loginData = {
          username: this.loginForm.username,
          password: md5(this.loginForm.password) // 对密码进行 MD5 加密
        };

        const response = await axios.post('/login/', loginData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        console.log(response.data)
        if (response.status === 200) {
          const token = response.data.data.token; // 提取令牌
          console.log(token)
          // 将 token 存入 localStorage
          localStorage.setItem('authToken', token);
          clearAxiosInstances(); // 清除所有 Axios 实例
          this.loginSuccessMessage = '登录成功！正在加载用户信息...';
          this.loginError = false;
          this.$store.dispatch('fetchUser'); // 调用Vuex action更新用户信息
          this.$router.push('/') // 假设路由名称为 'DeviceManager'
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // 特定处理401错误
          this.loginError = '请输入正确的用户或密码，在非微信浏览器中使用'; // 登录失败，显示错误信息
        } else {
          // 处理其他错误
          this.loginError = '登录失败：' + error.message
        }
        console.log('用户或密码错误');
      }
    }
  }
}
</script>
<style scoped>
.login-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* 半透明背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* 设置大一些的z-index值 */
  /* 其他样式保持不变 */
}

.login-box {
  padding: 20px;
  background: white;
  border: 1px solid #ccc; /* 边框 */
  border-radius: 8px; /* 圆角 */
  width: 300px; /* 根据需要调整 */
}

.login-title {
  text-align: center;
  margin-bottom: 20px;
}

.login-action {
  display: flex;
  justify-content: center;
}

.login-error {
  color: red;
  text-align: center;
  margin-top: 10px;
}
</style>
