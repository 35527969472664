import mitt from 'mitt';
import { extract_packet } from './timebuffer';
import { toRaw } from 'vue';

const eventBus = mitt();
const websocketMap = {}; // 存储多个 WebSocket 连接
const reconnectInterval = 1000; // 重连间隔（毫秒）
const maxReconnectAttempts = 10; // 最大重连次数

/**
 * 异步等待函数，直到目标函数返回 true 或超时
 * @param {Function} func - 要检查的非异步函数，返回 true 或 false
 * @param {number} timeout - 超时时间（毫秒），默认为 5000 毫秒
 * @param {number} interval - 轮询间隔时间（毫秒），默认为 100 毫秒
 * @returns {Promise<void>} - 如果目标函数返回 true，Promise resolve；如果超时，Promise reject
 */
function waitFor (func, timeout = 5000, interval = 100) {
  return new Promise((resolve, reject) => {
    const startTime = Date.now();

    // 定义轮询检查函数
    const checkCondition = () => {
      // 如果目标函数返回 true，则 resolve
      if (func()) {
        resolve();
      }
      // 如果超时，则 reject
      else if (Date.now() - startTime >= timeout) {
        reject(new Error('等待超时'));
      }
      // 否则，继续等待下一个轮询
      else {
        setTimeout(checkCondition, interval);
      }
    };

    // 开始轮询检查
    checkCondition();
  });
}

// 配置服务器列表
let serverUrls = {
  数据服务器: 'wss://data.wuji.com/ws/a/commandstream',
  计算服务器1: 'wss://data.wuji.com/ws/b/commandstream',
  计算服务器2: 'wss://data.wuji.com/ws/c/commandstream'
};
const proxy = import.meta.env.VITE_PROXY;
if (import.meta.env.VITE_ENVIRONMENT === 'development' && proxy) {
  serverUrls = {
    数据服务器: proxy,
    计算服务器1: proxy,
    计算服务器2: proxy
  };
}
// 连接所有服务器
function connectWebSockets () {
  for (const [name, url] of Object.entries(serverUrls)) {
    connectWebSocket(name, url);
  }
}

// 创建 WebSocket 连接
function connectWebSocket (name, url) {
  if (!url) {
    console.error(`服务器名称 ${name} 未定义 URL`);
    return;
  }

  // 检查 WebSocket 是否已经连接
  if (websocketMap[name] && websocketMap[name].readyState === WebSocket.OPEN) {
    console.log(`WebSocket 已连接到 ${name}`);
    return;
  }

  const websocket = new WebSocket(url);
  websocketMap[name] = websocket;
  let reconnectAttempts = 0;

  websocket.onopen = () => {
    console.log(`WebSocket 连接成功: ${name}`);

    reconnectAttempts = 0;
    eventBus.emit(`${name}-open`);
  };

  websocket.onmessage = async (event) => {
    try {
    // 判断接收到的数据类型
      if (typeof event.data === 'string') {
      // 文本消息，尝试解析为 JSON
        const message = JSON.parse(event.data);
        console.log("event "+message.name)
        eventBus.emit(message.name, { message, from: name });
      } else if (event.data instanceof ArrayBuffer) {
      // 直接处理为 ArrayBuffer
        const parsedData = extract_packet(event.data);
        for (const i in parsedData) {
          const data = parsedData[i];
          console.log("event "+data.name)
          eventBus.emit(data.name, { message: data, from: name });
        }
      } else if (event.data instanceof Blob) {
      // 直接处理为 ArrayBuffer
        const parsedData = extract_packet(await event.data.arrayBuffer());
        for (const i in parsedData) {
          const data = parsedData[i];
          console.log("event "+data.name + " from "+name)
          eventBus.emit(data.name, { message: data, from: name });
        }
      } else {
        console.error('未知的数据类型:', event.data);
      }
    } catch (error) {
      console.error(`解析 ${name} 的 WebSocket 消息失败:`, error);
    }
  };

  websocket.onclose = () => {
    console.log(`WebSocket 连接关闭: ${name}，尝试重连...`);

    if (reconnectAttempts < maxReconnectAttempts) {
      setTimeout(() => {
        reconnectAttempts++;
        connectWebSocket(name, url);
      }, reconnectInterval);
    } else {
      console.error(`达到最大重连次数，停止重连: ${name}`);
    }
  };

  websocket.onerror = (error) => {
    console.error(`WebSocket 错误: ${name}`, error);
  };
}

// 发送消息到指定服务器
async function sendMessage (message, server = '数据服务器') {
  const websocket = websocketMap[server];
  const token = localStorage.getItem('authToken');
  message.token = token;
  if (!websocket) {
    console.error(`WebSocket 未找到: ${server}`);
    return;
  }

  // 等待 WebSocket 连接成功
  await waitForWebSocketOpen(websocket);

  // 发送消息
  if (websocket.readyState === WebSocket.OPEN) {
    websocket.send(JSON.stringify(message));
  } else {
    console.error(`WebSocket 连接失败: ${server}`);
  }
}

function waitForWebSocketOpen (websocket, timeout = 5000) {
  return new Promise((resolve, reject) => {
    const startTime = Date.now();

    // 轮询检查 WebSocket 是否处于 OPEN 状态
    const checkOpen = () => {
      if (websocket.readyState === WebSocket.OPEN) {
        resolve();
      } else if (Date.now() - startTime > timeout) {
        reject(new Error('等待 WebSocket 连接超时'));
      } else {
        // 使用 setTimeout 进行非阻塞等待
        setTimeout(checkOpen, 100);
      }
    };

    checkOpen();
  });
}

async function sendCmd (cmd, param, server = '数据服务器') {
  const tosend = { type: 'command', action: cmd };
  Object.assign(tosend, toRaw(param));

  try {
    // 等待 WebSocket 连接成功后发送消息
    await sendMessage(tosend, server);
    console.log(`命令发送成功: ${cmd} -> ${server}`);
    return true;
  } catch (error) {
    console.error(`发送命令失败: ${cmd} -> ${server}`, error);
    return false;
  }
}

// 关闭所有 WebSocket 连接
function closeAllWebSockets () {
  for (const name in websocketMap) {
    if (websocketMap[name]) {
      websocketMap[name].close();
      console.log(`关闭 WebSocket 连接: ${name}`);
    }
  }
}

export { eventBus, connectWebSockets, sendMessage, closeAllWebSockets, sendCmd };
