import {getColor, formatDateString} from '../utils/utils';

export function setSpO2ChartOption(spo2_chart, spo2_data) {
    console.log(spo2_chart)
    console.log(spo2_data)
    if (!spo2_data) {
        console.log("Empty data, set chart fail.")
        return;
    }
    if (!spo2_chart) { //图表组件尚未初始化
        return;
    }

    var dataSPO = [];
    var dataMask = [];

    var sleepStart = spo2_data.start;
    var sleepEnd = spo2_data.end;

    if (spo2_data.spo2 && spo2_data.spo2.length > 0) {
        let offset = spo2_data.start;
        let end = spo2_data.end;
        let lastend = 0;

        //let windowLen = spo2_data.spo2[0].e - spo2_data.spo2[0].s;
        for (let i = 0; i < spo2_data.spo2.length; i++) {
            if (i == 0 && spo2_data.spo2[i].s != 0) { // 用于x轴开头的对齐
                dataSPO.push([offset, null]);
            }
            let v = spo2_data.spo2[i].v;
            //console.log(spo2_data.spo2[i].s, spo2_data.spo2[i].e, v);
            let s = offset + spo2_data.spo2[i].s;
            let e = offset + spo2_data.spo2[i].e;
            if (i > 0 && spo2_data.spo2[i].s != spo2_data.spo2[i - 1].e) { // 中间有空洞的情况，需要把曲线断开
                dataSPO.push([offset + spo2_data.spo2[i - 1].e, null]);
            }
            dataSPO.push([(s + e) / 2, v]);
            lastend = offset + (spo2_data.spo2[i].s + spo2_data.spo2[i].e) / 2;
        }
        if (lastend < end) { //用于x轴结尾的对齐
            dataSPO.push([end, null]);
        }
    } else {
        dataSPO.push([sleepStart, null]);
        dataSPO.push([sleepEnd, null]);
    }

    for (let i = 0; i < spo2_data.stage?.length; i++) {
        let seg = spo2_data.stage[i];
        if (seg.value < 2)
            dataMask.push([{
                xAxis: spo2_data.start + seg.start
            }, {xAxis: spo2_data.start + seg.end}]);
    }
    //console.log(dataMask);

    var screenWidth = 0;


    var option = {
        backgroundColor: 'transparent',
        tooltip: {
            trigger: 'axis',
            backgroundColor: getColor('background'),
            textStyle: {
                fontSize: 12,
                lineHeight: 9,
                textShadowBlur: 2,
                textShadowColor: 'transparent',
                color: getColor('text'),
            },
            confine: true,
            shadowBlur: 4,
            formatter: function (params) {
                let type = '无';

                if (params[0].seriesIndex == 0) {
                    return params[0].marker + formatDateString(params[0].value[0] * 1000, "HHmm") + '  ' + type;
                } else {
                    return '无';
                }
            },
        },
        title: {
            text: '整晚估算血氧下降百分比',
            padding: [2, 0, 0, 24],
            textStyle: {
                fontWeight: 'normal',
                fontSize: 12,
            }
        },
        legend: {
            orient: 'horizontal',
            bottom: 1,
            selectedMode: false,
            icon: 'circle',
            itemHeight: screenWidth <= 320 ? 10 : 12,
            itemWidth: 14,
            textStyle: {
                fontSize: screenWidth <= 320 ? 10 : 12,
            }
        },
        grid: {
            left: 24,
            right: '7%',
            top: 18,
            bottom: 22,
            containLabel: true
        },
        xAxis: {
            boundaryGap: false,
            min: 'dataMin',
            max: 'dataMax',
            minInterval: 120 * 60,
            maxInterval: 120 * 60,
            splitLine: {
                lineStyle: {type: 'dashed'},
            },
            axisPointer: {
                snap: false,
                triggerEmphasis: false,
            },
            axisLabel: {
                showMinLabel: true,
                showMaxLabel: true,
                itemStyle: {
                    opacity: 0.2,
                },
                formatter: function (val) {
                    var d = new Date(val * 1000);
                    var tag = d.getHours() + ":" + d.getMinutes().toString().padStart(2, '0');
                    //if (val % 3600000 != 0)
                    if (val === sleepStart || val === sleepEnd) {
                        return '{endtag|' + tag + '}';
                    } else
                        return '{all|' + tag + '}';
                },
                rich: {
                    all: {
                        padding: 2,
                    },
                    endtag: {
                        color: getColor('text'),
                        backgroundColor: getColor('tag'),
                        padding: 2,
                        borderRadius: 2,
                    }
                }
            }
        },
        yAxis: {
            type: 'value',
            show: true,
            axisTick: {show: false,},
            splitLine: {show: false,},
            max: 0,
            min: (value) => {
                if (value.min > -9) {
                    return -10;
                } else {
                    return null;
                }
            },
            axisLine: {show: false,},
            axisLabel: {
                show: false,
                inside: false,
                verticalAlign: 'top',
            },
        },
        /*dataZoom: {
            type: 'inside',
            start: 0,
            end: 100,
            filterMode: 'weakFilter', // empty会exception
            minSpan: 40,
            maxSpan: 100,
            zoomLock: false,
            orient: 'horizontal',
            xAxisIndex: [0, 1]
        },*/
        /*visualMap: { // 以 3% 为界分绿、黄、红三挡
            show: false,
            dimension: 1,
            seriesIndex: 0,
            pieces: [
              { gte: -3.1, color: ledColor[0] },
              { lt: -3.1, gte: -6.1, color: ledColor[1] },
              { lt: -6.1, color: ledColor[3] }
            ]
        },*/
        visualMap: { // 以 5% 为界分绿、黄两挡
            show: false,
            dimension: 1,
            seriesIndex: 0,
            pieces: [
                {gte: -3.1, color: '#e7a370'},
                {lt: -3.1, gte: -4.1, color: '#e7a370'},
                {lt: -4.1, color: '#e7a370'}
            ]
        },
        series: [{
            type: 'line',
            name: '血氧波动',
            symbol: 'none',
            smooth: false,
            markLine: {
                silent: true,
                symbol: 'none',
                label: {
                    show: true,
                    position: 'start',
                    distance: 2,
                    formatter: (params) => {
                        return params.value + '%';
                    }
                },
                data: [{yAxis: -4}, {yAxis: -8}],
                lineStyle: {
                    type: 'solid',
                    color: '#CCC',
                    opacity: 0.5,
                }
            },
            markArea: {
                silent: false,
                label: {show: false},
                itemStyle: {
                    color: getColor('tag'),
                    opacity: 0.4,
                },
                emphasis: {
                    label: {
                        show: true,
                        position: 'insideTop',
                        color: getColor('text'),
                    }
                },
                data: dataMask,
            },
            lineStyle: {
                width: 2,
            },
            encode: {
                x: 0,
                y: 1,
            },
            data: dataSPO,
        }, {
            type: 'line',
            itemStyle: {
                color: '#e7a370'
            }
        }, {
            type: 'line',
            itemStyle: {
                color: '#e7a370'
            }
        }]
    };
    spo2_chart.setOption(option);
}


