<template>
    <div shadow="always" style="width:200px">

        <div class="info">

            <div v-if="info.info">
                <strong>用户：</strong>{{ info.info.username }} {{ maskPhoneNumber(info.info.phoneNumber) }}
            </div>
            <div v-if="info.info.org">
                <strong>机构：</strong>{{ info.info.org }}
            </div>
            <div v-if="info.info.username">
                <strong>姓名：</strong>{{ info.info.username }}
            </div>
            <div v-if="info.info.sex">
                <strong>性别：</strong>{{ info.info.sex}}
            </div>
            <div v-if="info.info.birthdate">
                <strong>其他：</strong>{{ info.info.birthdate }}
            </div>
        </div>

    </div>
</template>

<script setup>
import { defineProps } from 'vue';

defineProps({
  info: {
    type: Object,
    required: true
  }

});
function maskPhoneNumber (phoneNumber) {
  // 使用正则表达式替换中间四位数字为星号
  if (!phoneNumber) { return '' }
  return phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
}

</script>

<style scoped>
    .card {
        display: flex;
        background-color: #fff;
        box-shadow: 0 2px 12px 0 rgb(128, 128, 128);
        padding: 5px;
        border-radius: 8px;
        white-space: nowrap; /* 防止内部文本换行 */
        height: 100px; /* 固定高度 */
    }

    .dashboard, .info {
        display: flex;
        flex-direction: column;
        justify-content: center; /* 使内容在垂直方向上居中 */
        /* 对于.dashboard，你可能需要调整或移除padding，或者确保它不会影响到内部元素的垂直居中 */
    }
    .dashboard {
        background-color: black;
        color: lime;
        padding: 5px 20px; /* 增加左右padding使其看起来更宽 */
        border-radius: 8px;
        align-items: flex-start;
        box-shadow: revert 0 0 10px rgb(128, 128, 128); /* 内凹阴影效果 */
        margin-bottom: 8px;
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: center; /* 垂直居中 */

    }

</style>
