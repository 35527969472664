<template>
    <el-table :data="rows" table-layout="auto">
      <el-table-column type="selection" width="30"/>

      <el-table-column prop="name" label="名称" resizable sortable :sort-method="sortNames">
      </el-table-column>
      <el-table-column prop="status" label="基础信息">
      </el-table-column>
      <el-table-column prop="device" label="设备">

      </el-table-column>
      <el-table-column prop=" report" label="报告">

      </el-table-column>

    </el-table>

</template>
<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
const { proxy } = getCurrentInstance(); // 获取组件实例的代理对象
//
const rows =ref([])
// const total = ref(0);
//
const fetchData = async () => {
  try {
    const response = await proxy.$axios.get('/orgs/')
        console.log(response.data.data)

    rows.value = response.data.data
    console.log(response.data)

  } catch (error) {
    console.error('获取数据失败:', error);
  }
};
onMounted(fetchData)
</script>


<style scoped>

</style>