<<template>
  <div class="logo"></div>
  <el-menu :default-active="$route.path" router>
    <el-menu-item index="/">
      <el-icon><Cpu/></el-icon>
      <span>首页</span>
    </el-menu-item>

    <el-menu-item index="/device-management" v-if="canAccessDeviceManagement">
      <el-icon><Cpu/></el-icon>
      <span>设备管理</span>
    </el-menu-item>

    <el-menu-item index="/org-device-management" v-if="user.isOrg">
      <el-icon><Cpu/></el-icon>
      <span>机构设备管理</span>
    </el-menu-item>

    <el-menu-item index="/org-device-management" v-if="canAccessOrgReport">
      <el-icon><Cpu/></el-icon>
      <span>机构报告</span>
    </el-menu-item>

    <el-menu-item index="/data-management" v-if="canAccessDataManagement">
      <el-icon><Document/></el-icon>
      <span>平行数据</span>
    </el-menu-item>

    <el-menu-item index="/file_management" v-if="canAccessFileManagement">
      <el-icon><Document/></el-icon>
      <span>文件管理</span>
    </el-menu-item>

    <el-menu-item index="/user-management" v-if="canAccessUserManagement">
      <el-icon><User/></el-icon>
      <span>用户管理</span>
    </el-menu-item>

     <el-menu-item index="/report-management" v-if="canAccessDataManagement">
      <el-icon><Document/></el-icon>
      <span>报告管理</span>
    </el-menu-item>
    <el-sub-menu index="data-management" v-if="user.isAdmin">
      <template #title>
        <el-icon>
          <Tools/>
        </el-icon>
        <span>数据评估</span>
      </template>
      <el-menu-item index="/data-detail" v-if="user.isAdmin">
        <el-icon>
          <Document/>
        </el-icon>
        <span>完整性</span>
      </el-menu-item>
      <el-menu-item index="/report-performance" v-if="user.isAdmin">
        <el-icon>
          <Document/>
        </el-icon>
        <span>报告性能</span>
      </el-menu-item>
      <el-menu-item index="/rri-detail" v-if="user.isAdmin">
        <el-icon>
          <Document/>
        </el-icon>
        <span>批量筛选</span>
      </el-menu-item>
      <!-- <el-menu-item index="/rrv-detail" v-if="user.isAdmin">
        <el-icon>
          <Document/>
        </el-icon>
        <span>rrv展示</span>
      </el-menu-item> -->
      <el-menu-item index="/device-stability" v-if="user.isAdmin">
        <el-icon>
          <Document/>
        </el-icon>
        <span>稳定性</span>
      </el-menu-item>
    </el-sub-menu>

    <el-menu-item index="/iccard-viewer" v-if="user.isAdmin">
      <el-icon>
        <Tools/>
      </el-icon>
      <span>SIM卡管理</span>
    </el-menu-item>
    <el-sub-menu index="system-management" v-if="canAccessSystemManagement">
      <template #title>
        <el-icon><Tools/></el-icon>
        <span>系统管理</span>
      </template>
      <el-menu-item index="/wdb-viewer"><span>WDB</span></el-menu-item>
      <el-menu-item index="/sys-detail"><span>系统详情</span></el-menu-item>
      <el-menu-item index="/sys-config"><span>配置页面</span></el-menu-item>
      <el-menu-item index="/batch"><span>批量处理</span></el-menu-item>
      <el-menu-item index="/documentdemo"><span>开发手册</span></el-menu-item>
    </el-sub-menu>
  </el-menu>
</template>

<script setup>
import {computed} from 'vue';
import { useStore } from 'vuex';
import { Cpu, Document, User, Tools } from '@element-plus/icons-vue';

const store = useStore();
const user = computed(() => store.state.user);
// 使用计算属性简化权限判断
const canAccessDeviceManagement = computed(() => user.value.isAdmin || user.value.isOrgManager);
const canAccessOrgReport = computed(() => user.value.isAdmin || user.value.isOrgManager);
const canAccessDataManagement = computed(() =>
  !user.value.isShenzhenHospital && (user.value.isOrgManager || user.value.isAdmin || user.value.isOrg)
);
const canAccessFileManagement = computed(() => user.value.isAdmin);
const canAccessUserManagement = computed(() => user.value.isAdmin);
const canAccessSystemManagement = computed(() => user.value.isAdmin);
</script>

<style>
.logo {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

/* 覆盖 el-sub-menu 的默认样式，使其标题和箭头为白色 */
.el-sub-menu__title {
  color: white !important;
}

.el-sub-menu__title .el-icon-arrow-down {
  color: white;
}

/* 悬停时的样式 */
.el-sub-menu__title:hover {
  color: green !important;
}

.el-sub-menu__title:hover .el-icon-arrow-down {
  color: green;
}

/* 鼠标移到菜单上的样式 */
.el-menu-item {
  color: white;
}

.el-menu-item:hover {
  color: green;
}
</style>
