<template>

        <el-card class="header-card">
            <div>补发任务</div>
            <TreeViewObj :data="totaltask" :expand="true" />

        </el-card>

</template>

<script setup>
import {defineProps, onMounted, onUnmounted, ref} from 'vue';
    //import { request_data,wdb_get } from '@/plugins/axios.js';

    //import { getDateTimestamp, isToday } from '@/plugins/timetool.js';
    import TreeViewObj from "@/common/TreeViewObj.vue";
  import {wdb_get} from "@/plugins/axios";

    // 接收prop
    const props = defineProps({
        device: String,
        date: String,
    });

    const totaltask = ref([])

    async function getTasks() {

        if (!props.device ) return
        const th = await wdb_get('uploadtask', props.device)
        totaltask.value = th
    }

    let intervalId = null;
    onMounted(async () => {
        await getTasks()
        intervalId = setInterval(getTasks, 10000);
    });
    onUnmounted(() => {
        if (intervalId) {
            clearInterval(intervalId); // 清除定时器
        }
    });



</script>
<style scoped>
    /* 添加到你的组件的<style>标签内 */
    .header-card {
        overflow-y: auto;
        width: 600px;
        font-size: 0.5em; /* 调整为适当的大小，如果0.5em太小 */
    }
</style>
