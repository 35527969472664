import { getColor, ledColor } from '../utils/utils.js';

import { locales } from '../utils/locales';

import {
  chart_dates, // 图表横轴的日期与报告数据之间的映射关系
  getInterval
} from './variables.js';

const L = locales['zh-Hans']

const monthNameArray = [L.jan, L.feb, L.mar, L.apr, L.may, L.jun, L.jul, L.aug, L.sep, L.oct, L.nov, L.dec];
function getSymbol (value, params, data) { // 单点数据需要显示symbol，能连成线则不显示
  const left = params.dataIndex - 1;
  const right = params.dataIndex + 1;
  let symbol = 'none';
  if (left >= 0 && right < data.length) { // 中间单点
    if (data[left][1] == null && data[right][1] == null) {
      symbol = 'circle';
    }
  } else if (right < data.length) { // 最左边缘
    if (data[right][1] == null) symbol = 'circle';
  } else if (left >= 0) { // 最右边缘
    if (data[left][1] == null) symbol = 'circle';
  }
  return symbol;
}

export function setRRChartOption (reports, rr_chart) {
  if (!rr_chart) {
    return;
  }

  const dataRRavg = [];
  const dataUpper = [];
  const dataLower = [];
  const dataXAxis = [];

  // 从日期序列转换到图表数据
  let weekmode = false;
  if (Object.keys(reports).length > 0) {
    const total = Object.keys(chart_dates).length;
    // 如果周期太长，以周为单位计算均值
    if (total <= 60) {
      let totalHole = 0;
      for (const key in chart_dates) {
        const [month, day] = key.split('/');
        const abbrKey = month + '-' + day;
        // 添加安全检查
        let avg = null; let upper = null; let lower = null;
        if (reports[key] && typeof reports[key].avg_resp !== 'undefined') {
          avg = Math.round(reports[key].avg_resp * 10) / 10;
          upper = Math.round(reports[key].resp_limit_max * 10) / 10;
          lower = Math.round(reports[key].resp_limit_min * 10) / 10;
        }

        let avgColor = ledColor[0];
        if (avg > upper || avg < lower) {
          avgColor = ledColor[1];
        }
        dataRRavg.push({ name: abbrKey, value: avg, itemStyle: { color: avgColor } });
        dataUpper.push([abbrKey, upper ? (upper - lower) : null]);
        dataLower.push([abbrKey, lower]);
        if (!upper || !lower) {
          totalHole++;
        }
        if (totalHole) {
          let holeStart = 0;
          // 如果空洞不超过4天，不要断开空洞的部分，平滑过渡
          for (let i = 0; i < dataUpper.length; i++) {
            if (dataUpper[i][1]) {
              if (holeStart && (i - holeStart) <= 4) {
                dataUpper.splice(holeStart, i - holeStart);
                dataLower.splice(holeStart, i - holeStart);
                i = holeStart;
              }
              holeStart = 0;
            } else if (!holeStart) {
              holeStart = i;
            }
          }
        }
        dataXAxis.push(abbrKey);
      }
    } else {
      weekmode = true;
      let [avg_avg, upper_avg, lower_avg, count, countBase] = [0, 0, 0, 0, 0];
      let valid = false; // 跳过开头的“半周”
      let weekend = '';
      for (let key in chart_dates) {
        const [year, month, day] = key.split('-');
        const d = new Date();
        d.setFullYear(year);
        d.setMonth(month - 1);
        d.setDate(day);
        d.setHours(0, 0, 0, 0);
        const weekday = d.getDay();

        let avg = chart_dates[key] >= 0 ? Math.round(reports[chart_dates[key]].avg_resp * 10) / 10 : null;
        const upper = chart_dates[key] >= 0 ? Math.round(reports[chart_dates[key]].resp_limit_max * 10) / 10 : null;
        const lower = chart_dates[key] >= 0 ? Math.round(reports[chart_dates[key]].resp_limit_min * 10) / 10 : null;
        if (!avg) avg = null;
        if (avg != null && valid) {
          avg_avg += avg;
          count++;
        }
        if (upper != null && lower != null && valid) {
          upper_avg += upper;
          lower_avg += lower;
          countBase++;
        }
        if (weekday === 6) { // 每周最后一天
          weekend = month + '-' + day;
        }
        if (weekday === 0) { // 每周第一天
          if (valid) {
            avg_avg = count ? Math.round(avg_avg / count * 10) / 10 : null;
            upper_avg = countBase ? Math.round(upper_avg / countBase * 10) / 10 : null;
            lower_avg = countBase ? Math.round(lower_avg / countBase * 10) / 10 : null;

            let avgColor = ledColor[0];
            if (avg_avg > upper_avg || avg_avg < lower_avg) {
              avgColor = ledColor[1];
            }

            key = month + '-' + day + L.to + weekend;
            dataRRavg.push({ name: key, value: avg_avg, itemStyle: { color: avgColor } });
            dataUpper.push([key, upper_avg ? (upper_avg - lower_avg) : null]);
            dataLower.push([key, lower_avg]);
            dataXAxis.push(key);
            [avg_avg, upper_avg, lower_avg, count, countBase] = [0, 0, 0, 0, 0, 0];
            weekend = '';
          } else {
            valid = true;
          }
        }
      }
    }
  }
  dataRRavg.reverse();
  dataUpper.reverse();
  dataLower.reverse();
  dataXAxis.reverse();

  const option = {
    backgroundColor: 'transparent',
    animationDuration: 500,
    /* title: {
            text: '睡眠呼吸频率 (次/分)',
            padding: [0, 0, 0, 32],
            textStyle: {
                fontWeight: 'normal',
                fontSize: 14
            }
        }, */
    tooltip: {
      trigger: 'axis',
      confine: true,
      order: 'seriesAsc',
      shadowBlur: 4,
      backgroundColor: getColor('background'),
      textStyle: {
        fontSize: 12,
        lineHeight: 9,
        textShadowBlur: 2,
        textShadowColor: 'transparent',
        color: getColor('text')
      },
      axisPointer: {
        type: 'line',
        label: {
          show: true,
          backgroundColor: '#6a7985'
        }
      },
      // valueFormatter: (value) => value ? value + '次/分' : '-',
      formatter: (params) => {
        if (params.length !== 3) { return null; }
        return params[0].name + '\n' +
                    params[0].marker + params[0].seriesName + '    ' + (params[0].value ? params[0].value + L.bpm : L.nodata_abbr) + '\n' +
                    params[1].marker + params[1].seriesName + '    ' + (params[1].value[1] ? (params[1].value[1] + '-' + (params[1].value[1] + params[2].value[1]) + L.bpm) : L.nodata_abbr);
      }
    },
    legend: {
      data: [{ name: L.avg_respiratory_rate }, { name: L.baseline, icon: 'roundRect', itemStyle: { opacity: 0.8 } }],
      orient: 'horizontal',
      bottom: 1,
      selectedMode: false,
      icon: 'circle',
      itemHeight: 10,
      itemWidth: 10,
      itemGap: 6,
      textStyle: {
        fontSize: 12,
        padding: [0, 0, 0, -2]
      }
    },
    grid: {
      top: '6%',
      left: '3%',
      right: '3%',
      bottom: weekmode ? '18%' : '12%',
      containLabel: true
    },
    xAxis: [{
      type: 'category',
      boundaryGap: true,
      axisTick: { alignWithLabel: true },
      min: 'dataMin',
      max: 'dataMax',
      axisLabel: {
        showMinLabel: true,
        showMaxLabel: true,
        fontSize: 10,
        rotate: weekmode ? 0 : 45,
        interval: weekmode ? 0 : getInterval,
        formatter: (value) => {
          if (weekmode) {
            const [weekstart, weekend] = value.split(L.to);
            const [startMonth, startDay] = weekstart.split('-');
            const [endMonth] = weekend.split('-');
            if (startMonth !== endMonth || startDay === 1) { // 月初
              return monthNameArray[parseInt(endMonth) - 1];
            } else {
              return ''
            }
          } else {
            return value;
          }
        }
      },
      data: dataXAxis
    }],
    yAxis: [{
      type: 'value',
      minInterval: 1,
      min: function (value) {
        return Math.floor(value.min / 2) * 2
      },
      max: function (value) {
        return Math.ceil(value.max / 2) * 2
      },
      axisLabel: {
        show: true
      }
    }],
    series: [{
      name: L.avg_respiratory_rate,
      type: 'line',
      lineStyle: { color: getColor('linegray1') },
      itemStyle: { color: getColor('linegray1') },
      symbol: 'circle',
      symbolSize: 5,
      smooth: true,
      emphasis: { disabled: true },
      data: dataRRavg
    }, {
      name: L.baseline,
      type: 'line',
      stack: 'band',
      silent: true,
      emphasis: { disabled: true },
      itemStyle: { color: getColor('tag') },
      lineStyle: { opacity: 0 },
      symbol: (value, params) => {
        return getSymbol(value, params, dataLower);
      },
      smooth: true,
      data: dataLower
    }, {
      name: '基线上限',
      type: 'line',
      stack: 'band',
      silent: true,
      emphasis: { disabled: true },
      itemStyle: { color: getColor('tag') },
      lineStyle: { opacity: 0 },
      symbol: (value, params) => {
        return getSymbol(value, params, dataUpper);
      },
      smooth: true,
      areaStyle: { color: getColor('tag'), opacity: 0.8 },
      data: dataUpper
    }]
  };
  rr_chart.setOption(option);
}
