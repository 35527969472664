<template>
  <div class="device-report-view">
    <!-- 顶部栏 -->
    <DeviceDateSelect
        v-model:selectedDevice="selectedDevice"
        v-model:selectedReportDate="selectedReportDate"
        :selections="props.selections"
    />
    <!-- 设备详细数据 -->
    <el-tabs v-model="selectedTask">
      <el-tab-pane name="设备-数据" label="数据（收集）"></el-tab-pane>
      <el-tab-pane name="任务-数据" label="任务（数据）"></el-tab-pane>
      <el-tab-pane name="设备日志" label="设备日志"></el-tab-pane>
      <el-tab-pane name="补发任务" label="补发"></el-tab-pane>

      <el-tab-pane name="设备-计算1" label="数据1"></el-tab-pane>
      <el-tab-pane name="报告-计算1" label="报告1"></el-tab-pane>
      <el-tab-pane name="用户报告" label="用户报告"></el-tab-pane>
      <el-tab-pane name="历史1" label="历史1"></el-tab-pane>
      <el-tab-pane name="任务-计算1" label="任务1"></el-tab-pane>
       <el-tab-pane name="设备-计算2" label="数据2"></el-tab-pane>
      <el-tab-pane name="报告-计算2" label="报告2"></el-tab-pane>
      <el-tab-pane name="任务-计算2" label="任务2"></el-tab-pane>
       <el-tab-pane name="历史2" label="历史2"></el-tab-pane>
    </el-tabs>
    <div class="container" v-if=" selectedTask==='设备-数据'" >
      <div  class="snapshot-wrapper">
        <el-card class="header-card" v-if="activetask && activetask.length>0">
          <div>进行中任务</div>
          <TreeViewObj :data="activetask"/>
        </el-card>
        <device-snapshot  :key="taskkey" :device="selectedDevice" :date="selectedReportDate" v-model:globalstart="globalStart"
                         v-model:globalend="globalEnd" v-model:hf="hf" v-model:selected="selectedChannels" :server="server"
                         ></device-snapshot>
        <el-card class="header-card">
          <div>设备快照</div>
          <TreeViewObj :data="current_device_detail"/>
        </el-card>
      </div>
      <div class="waveform-wrapper">
        <device-waveform :key="taskkey"  :device="selectedDevice" mode="device" :date="selectedReportDate" :globalstart="globalStart"
                         :globalend="globalEnd" :visible="selectedChannels" :hf="hf"  :server="server"></device-waveform>
      </div>
    </div>
    <div class="container" v-if="selectedTask=='设备-计算1'">
      <div class="snapshot-wrapper">
        <el-card class="header-card" v-if="activetask && activetask.length>0">
          <div>进行中任务</div>
          <TreeViewObj :data="activetask"/>
        </el-card>
        <device-snapshot   :key="taskkey" :device="selectedDevice" :date="selectedReportDate" v-model:globalstart="globalStart"
                         v-model:globalend="globalEnd"  v-model:hf="hf" v-model:selected="selectedChannels" :server="server"
                         ></device-snapshot>
        <el-card class="header-card">
          <div>设备快照</div>
          <TreeViewObj :data="current_device_detail"/>
        </el-card>
      </div>
      <div class="waveform-wrapper">
        <device-waveform :key="taskkey"  :device="selectedDevice" mode="device" :date="selectedReportDate" :globalstart="globalStart"
                         :globalend="globalEnd" :visible="selectedChannels" :hf="hf"  :server="server"></device-waveform>
      </div>
    </div>
    <div class="container" v-if=" selectedTask=='设备-计算2'" >
      <div class="snapshot-wrapper">
        <el-card class="header-card" v-if="activetask && activetask.length>0">
          <div>进行中任务</div>
          <TreeViewObj :data="activetask"/>
        </el-card>
        <device-snapshot  :key="taskkey" :device="selectedDevice" :date="selectedReportDate" v-model:globalstart="globalStart"
                         v-model:globalend="globalEnd"  v-model:hf="hf" v-model:selected="selectedChannels" :server="server"
                         ></device-snapshot>
        <el-card class="header-card">
          <div>设备快照</div>
          <TreeViewObj :data="current_device_detail"/>
        </el-card>
      </div>
      <div class="waveform-wrapper">
        <device-waveform  :key="taskkey" :device="selectedDevice" mode="device" :date="selectedReportDate" :globalstart="globalStart"
                         :globalend="globalEnd" :visible="selectedChannels" :hf="hf" :server="server"></device-waveform>
      </div>
    </div>


    <div class="container" v-if="selectedTask=='报告-计算2'">

      <div class="snapshot-wrapper">
        <el-card class="header-card" v-if="activetask && activetask.length>0">
          <div>进行中任务</div>
          <TreeViewObj :data="activetask"/>
        </el-card>
        <report-view  :key="taskkey" :device="selectedDevice" :date="selectedReportDate" :server="server"/>

        <el-card class="header-card">
          <div>设备快照</div>
          <TreeViewObj :data="current_device_detail"/>
        </el-card>
      </div>

      <div class="waveform-wrapper">
        <device-waveform :key="taskkey"  :device="selectedDevice" :date="selectedReportDate" mode="report" :server="server"></device-waveform>
      </div>

    </div>
    <div class="container" v-if="selectedTask=='报告-计算1'">

      <div class="snapshot-wrapper">
        <el-card class="header-card" v-if="activetask && activetask.length>0">
          <div>进行中任务</div>
          <TreeViewObj :data="activetask"/>
        </el-card>
        <report-view :key="taskkey" :device="selectedDevice" :date="selectedReportDate" :server="server"/>

        <el-card class="header-card">
          <div>设备快照</div>
          <TreeViewObj :data="current_device_detail"/>
        </el-card>
      </div>

      <div class="waveform-wrapper">
        <device-waveform :key="taskkey"  :device="selectedDevice" :date="selectedReportDate" mode="report" :server="server"></device-waveform>
      </div>

    </div>
    <div class="container" v-if="selectedTask=='历史1'">
      <div>
        <ReportHistory
            :device="selectedDevice"
            :date="selectedReportDate"
            :server="server"
        />
      </div>
    </div>
    <div class="container" v-if="selectedTask=='任务-数据'">
      <div class="tasks-view">
        <task-view
            :device="selectedDevice"
            :date="selectedReportDate"
            :serverType="server"
        />
      </div>
    </div>
    <div class="container" v-if="selectedTask=='任务-计算1'">
      <div class="tasks-view">
        <task-view
            :device="selectedDevice"
            :date="selectedReportDate"
            :serverType="server"
        />
      </div>
    </div>
    <div class="container" v-if="selectedTask=='任务-计算2'">
      <div class="tasks-view">
        <task-view
            :device="selectedDevice"
            :date="selectedReportDate"
            :serverType="server"
        />
      </div>
    </div>
    <div class="container" v-if="selectedTask=='历史2'">
      <div>
        <ReportHistory
            :device="selectedDevice"
            :date="selectedReportDate"
            :server="server"
        />
      </div>
    </div>
    <div class="container" v-if="selectedTask=='设备日志'">
      <div class="logs-view" style="width: 100%">
        <logs-view
            :device="selectedDevice"
            :date="selectedReportDate"
        />
      </div>
    </div>
    <div class="userReport" v-if="selectedTask=='用户报告'">
      <div>
        <ReportDetail v-model:device="selectedDevice" :selections="current_device_detail" :date="selectedReportDate"/>
      </div>
    </div>
    <div class="container" v-if="selectedTask=='补发任务'">
      <div>
        <ReuploadDetail v-model:device="selectedDevice"/>
      </div>
    </div>

  </div>
</template>

<script setup>
import { ref, defineProps, watch, onMounted, onUnmounted, defineEmits } from 'vue';

import DeviceDateSelect from '@/common/DeviceDateSelect.vue';
import DeviceSnapshot from './DeviceSnapshot.vue';
import ReportView from './ReportView.vue';
import ReportHistory from './ReportHistory.vue';
import TaskView from './TaskView.vue';
import DeviceWaveform from './DeviceWaveform.vue';
import LogsView from '@/components/LogsView.vue';
import { request_data, wdb_get_new } from '@/plugins/axios.js';
import { getDateTimestamp } from '@/plugins/timetool.js';
import TreeViewObj from '@/common/TreeViewObj.vue';
import ReportDetail from '@/components/ReportDetail.vue';
import ReuploadDetail from '@/components/ReuploadDetail.vue';

// 接收prop
const props = defineProps({
  device: String,
  selections: Array
});
const selectedChannels = ref([]);

const emit = defineEmits(['update:device'])

// 定义 emit 函数，用于触发事件
const servers = {
  '设备-计算1': '计算服务器1',
  '设备-计算2': '计算服务器2',
  '报告-计算1': '计算服务器1',
  '报告-计算2': '计算服务器2',
  '任务-计算1': '计算服务器1',
  '任务-计算2': '计算服务器2',
  '历史1': '计算服务器1',
  '历史2': '计算服务器2'
};

const selectedDevice = ref(props.device)
const selectedReportDate = ref(''); // 选中的报告日期
const selectedTask = ref('设备-数据')
const globalStart = ref(0)
const globalEnd = ref(0)
const hf = ref(0)
const current_device_detail = ref({})
const server = ref('数据服务器');
watch([() => selectedReportDate.value, () => selectedDevice.value], async ([newDate, newDevice], [oldDate, oldDevice]) => {
  if (newDevice === '' || newDevice !== oldDevice) {
    emit('update:device', newDevice); // 确保这段代码在正确的上下文中
  } else if (oldDate !== newDate) {
    globalStart.value = new Date(newDate + ' 00:00:00').getTime() / 1000;
    globalEnd.value = globalStart.value + 3600; // 假设是增加60
  }
}, { immediate: true });

watch([() => selectedTask.value], () => {
  server.value = servers[selectedTask.value]
  if (server.value == null) {
    server.value = '数据服务器'
  }
  console.log('selectedTask', selectedTask.value, 'server', server.value)
});

let task_current = null
const activetask = ref([])

let requesting_task = 0
let last_active_task = 0;
const taskkey = ref(0)

async function getTasks () {
  if (!props.device || requesting_task) return
  requesting_task = 1

  const time = selectedReportDate.value ? getDateTimestamp(selectedReportDate.value) : null
  const detail = []
  current_device_detail.value = await request_data(`/devicedetail/${props.device}/`, server.value)
  task_current = await wdb_get_new(server.value, 'task', props.device, null, time)
  for (const task in task_current) {
    if (task_current[task] && task_current[task].status === 'running') {
      detail.push(task_current[task])
    }
  }
  activetask.value = detail
  if (detail.length === 0 && last_active_task > 0) {
    taskkey.value += 1;
  }
  last_active_task = detail.length
  requesting_task = 0
}

let intervalId = null;
onMounted(async () => {
  intervalId = setInterval(getTasks, 3000);
});
onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId); // 清除定时器
  }
});

</script>

<style scoped>

.container {
  display: flex;
  flex-direction: row; /* 水平布局 */
  width: 100%; /* 确保容器占满整个父元素宽度 */
}

.snapshot-wrapper {
  flex: 0 0 20%; /* flex-grow: 0, flex-shrink: 0, flex-basis: 20% */
  margin: 4px;
  height: 600px;
  /* 这里表示snapshot-wrapper不会随着容器大小的变化而增长或缩小，其基础大小为容器宽度的20% */
}

.waveform-wrapper {
  flex-grow: 1; /* 使用flex-grow: 1，意味着这个元素会占据剩余的空间 */
  /* 这里不需要设置flex-basis，因为默认值是0%，flex-grow会让它自动填满剩余空间 */
  margin: 4px;
}

.tasks-view {
  flex-grow: 1; /* 使用flex-grow: 1，意味着这个元素会占据剩余的空间 */
  /* 这里不需要设置flex-basis，因为默认值是0%，flex-grow会让它自动填满剩余空间 */
  margin: 4px;
}

.userReport {
  flex-direction: row; /* 水平布局 */
}

.spacer {
  height: 50px; /* 添加一个空白填充的高度，可以根据需要调整 */
}

</style>
