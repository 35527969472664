import {getColor, ledColor} from '../utils/utils';

function getSymbol(value, params, data) { //单点数据需要显示symbol，能连成线则不显示
    var left = params.dataIndex - 1;
    var right = params.dataIndex + 1;
    var symbol = 'none';
    if (left >= 0 && right < data.length) { //中间单点
        if (data[left].value == null && data[right].value == null) {
            symbol = 'circle';
        }
    } else if (right < data.length) { //最左边缘
        if (data[right].value == null) symbol = 'circle';
    } else if (left >= 0) { //最右边缘
        if (data[left].value == null) symbol = 'circle';
    }
    return symbol;
}

export function setHRBaselineChartOption(reports, hr_chart, chart_dates) {
    if (!hr_chart) {
        return;
    }

    var dataHRmin = [];
    var dataHRavg = [];
    var dataUpper = [];
    var dataLower = [];
    var dataXAxis = [];

    //从日期序列转换到图表数据
    if (Object.keys(reports).length > 0) {
        for (var key in chart_dates) {
            var avg, min, upper, lower;
            if (reports[key] && Object.keys(reports[key]).length > 0) {
                avg = Math.round(reports[key].bpm_avg);
                min = Math.round(reports[key].bpm_min);
                upper = Math.round(reports[key].bpm_limit_max);
                lower = Math.round(reports[key].bpm_limit_min);
            } else {
                avg = null;
                min = null;
                upper = null;
                lower = null;
            }

            if (!min) min = null;
            if (!avg) avg = null;
            let minColor = ledColor[0]; //'#9dce61';
            if (min > upper || min < lower) {
                minColor = ledColor[1];
            }
            dataHRmin.push({name: key, value: min, itemStyle: {color: minColor,}});
            dataHRavg.push({name: key, value: avg});
            dataUpper.push({name: key, value: upper ? (upper - lower) : null});
            dataLower.push({name: key, value: lower});
            dataXAxis.push(key);
        }
    }
    dataHRmin.reverse();
    dataHRavg.reverse();
    dataUpper.reverse();
    dataLower.reverse();
    dataXAxis.reverse();

    var endPos = dataXAxis.length - 1;
    var startPos = (endPos - 29) >= 0 ? (endPos - 29) : 0;

    var screenWidth = 0;

    var option = {
        backgroundColor: 'transparent',
        animationDuration: 500,
        title: {
            text: '近期最低心率趋势',
            padding: [0, 0, 0, 32],
            textStyle: {
                fontWeight: 'normal',
                fontSize: 12,
            }
        },
        tooltip: {
            trigger: 'axis',
            //triggerOn: 'click',
            confine: true,
            order: 'seriesAsc',
            shadowBlur: 4,
            backgroundColor: getColor('background'),
            textStyle: {
                fontSize: 12,
                lineHeight: 9,
                textShadowBlur: 2,
                textShadowColor: 'transparent',
                color: getColor('text'),
            },
            axisPointer: {
                type: 'line',
                label: {
                    show: true,
                    backgroundColor: '#6a7985'
                }
            },
            //valueFormatter: (value) => value ? value + '次/分' : '-',
            formatter: (params) => {
                return params[0].name + '\n'
                    + params[0].marker + params[0].seriesName + '    ' + (params[0].value ? Math.round(params[0].value) + '次/分' : '无') + '\n'
                    + params[1].marker + params[1].seriesName + '    ' + (params[1].value ? (params[1].value + '-' + (params[1].value + params[2].value) + '次/分') : '无');
            }
        },
        legend: {
            data: ['个人基线区间', '超出基线区间'],
            orient: 'horizontal',
            bottom: 1,
            selectedMode: false,
            icon: 'circle',
            itemHeight: screenWidth <= 320 ? 10 : 12,
            itemWidth: 14,
            textStyle: {
                fontSize: screenWidth <= 320 ? 10 : 12,
            }
        },
        grid: {
            top: '16%',
            left: '3%',
            right: '3%',
            bottom: '12%',
            containLabel: true,
        },
        xAxis: [{
            type: 'category',
            boundaryGap: false,
            min: 'dataMin',
            max: 'dataMax',
            axisLabel: {
                showMinLabel: true,
                showMaxLabel: true,
                fontSize: 10,
                rotate: 45,
                interval: 0,
            },
            data: dataXAxis,
        }],
        yAxis: [{
            type: 'value',
            interval: 5,
            min: function (value) {
                return Math.floor(value.min / 10) * 10
            },
            max: function (value) {
                return Math.ceil(value.max / 10) * 10
            },
            axisLabel: {
                show: true,
            }
        }],
        dataZoom: {
            type: 'inside',
            zoomLock: true,
            rangeMode: 'value',
            startValue: startPos,
            endValue: endPos,
            minValueSpan: 30,
        },
        series: [{
            name: '最低心率',
            type: 'line',
            lineStyle: {color: getColor('linegray1')},
            itemStyle: {color: ledColor[0]},
            symbol: 'circle',
            symbolSize: 5,
            smooth: true,
            z: 5,
            data: dataHRmin,
        }, {
            name: '个人基线区间',
            type: 'line',
            stack: 'band',
            silent: true,
            itemStyle: {color: getColor('tag')},
            lineStyle: {opacity: 0},
            symbol: (value, params) => {
                return getSymbol(value, params, dataLower);
            },
            smooth: true,
            data: dataLower,
        }, {
            name: '基线上限',
            type: 'line',
            stack: 'band',
            silent: true,
            itemStyle: {color: getColor('tag')},
            lineStyle: {opacity: 0},
            symbol: (value, params) => {
                return getSymbol(value, params, dataUpper);
            },
            smooth: true,
            areaStyle: {color: getColor('tag'), opacity: 0.8},
            data: dataUpper,
        }, {
            name: '超出基线区间',
            type: 'line',
            itemStyle: {color: ledColor[1]},
        }]
    };
    hr_chart.setOption(option);
}
