<template>
  <div>
    <!-- 上传Excel按钮 -->
    <div class="upload-area">
      <el-upload :on-success="handleUploadSuccess" :on-error="handleUploadError" accept=".xlsx" :headers="headers"
        :http-request="customUpload">
        <template v-slot:trigger>
          <el-button type="primary">选择Excel文件</el-button>
        </template>
        <template v-slot:tip>
          <div class="el-upload__tip">只能上传.xlsx文件</div>
        </template>
      </el-upload>
    </div>

    <!-- 批量更新按钮 -->
    <div class="update-area">
    <el-button type="success" :loading="$store.state.isUpdating" @click="batchUpdate">
      批量更新
    </el-button>
    </div>

    <!-- 搜索输入 -->
    <div class="search-area">
      <el-input v-model="search" placeholder="搜索ICCID"></el-input>
    </div>
    <!-- SIM卡信息展示 -->
    <el-table :data="simData" style="width: 100%">
      <el-table-column prop="iccid" label="ICCID"></el-table-column>
      <el-table-column prop="剩余流量(M)" label="剩余流量(M)"></el-table-column>
      <el-table-column prop="状态" label="状态"></el-table-column>
      <el-table-column prop="到期时间" label="到期时间"></el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="primary" size="small" @click="refreshRowData(scope.row)">更新信息</el-button>

        </template>
      </el-table-column>
      <!-- 添加更多列 -->
    </el-table>
    <!-- 分页组件 -->
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>


  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
// import axios from 'axios';
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';
import {useJumpData} from "@/plugins/checkJumpData";
import {createAxiosInstance} from "@/plugins/axios";

const {checkJumpData} = useJumpData();
const simData = ref([]);
const total = ref(0);
const currentPage = ref(1);
const pageSize = ref(10);
const search = ref('');
const headers = ref({}); // 如果需要的话，可以在这里设置请求头
// 其他响应式数据定义
const axiosInstance = createAxiosInstance("数据服务器");



const fetchSimData = async () => {
  try {
    const response = await axiosInstance.get('/sim_card/list/', {
      params: {
        page: currentPage.value,
        page_size: pageSize.value,
        iccid: search.value,
      },
    });
    simData.value = response.data.data;
    total.value = response.data.total;
  } catch (error) {
    console.error('Failed to fetch SIM data:', error);
  }
};

const refreshRowData = async (row) => {
  try {
    // 发起请求到后端API获取最新数据，这里API通过`iccid`获取数据
    const response = await axiosInstance.get(`/sim_card/${row.iccid}/`);
    const updatedRowData = response.data;

    // 查找当前行在simData中的索引，然后更新这一行的数据
    const rowIndex = simData.value.findIndex(item => item.iccid === row.iccid);
    if (rowIndex !== -1) {
      simData.value[rowIndex] = updatedRowData;
    }

    ElMessage({
      message: '信息更新成功',
      type: 'success',
    });
  } catch (error) {
    console.error('Failed to refresh row data:', error);
    ElMessage({
      message: '信息更新失败',
      type: 'error',
    });
  }
};
const customUpload = async (file) => {
  // 创建FormData对象用于构建表单数据
  let formData = new FormData();
  formData.append('file', file.file); // 'file'是后端期待的字段名

  try {
    // 使用axios发送POST请求
    const response = await axiosInstance.post('/sim_card/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        // 在这里添加任何其他需要的请求头
        ...headers.value,
      },
    });

    // 如果上传成功，手动调用handleUploadSuccess
    handleUploadSuccess(response);
  } catch (error) {
    // 如果上传失败，手动调用handleUploadError
    handleUploadError(error);
  }
};

const handleUploadSuccess = (response) => {
  console.log(response)
  ElMessage({
    message: '上传成功',
    type: 'success',
  });
  fetchSimData(); // 重新获取SIM卡信息以更新列表
};

const handleUploadError = () => {
  ElMessage({
    message: '上传失败',
    type: 'error',
  });
};

// 批量更新方法
// const isUpdating = ref(false); // 控制按钮的加载状态
const store = useStore(); // 使用store

// 批量更新方法
const batchUpdate = async () => {
  store.commit('startUpdating', true); // 将按钮置为加载状态，使用store
  let intervalId; // 在这里定义intervalId，确保它在整个函数的作用域内可用
  try {
    await sendBatchUpdateRequest();
    if (store.state.isUpdating) { // 只有在按钮仍处于加载状态时才启动轮询
      // 启动轮询查询更新状态
      intervalId = setInterval(async () => {
        const status = await queryUpdateStatus(); // 假设这是查询更新状态的函数
        if (status.status === 'completed') { // 假设'completed'表示更新完成
          clearInterval(intervalId); // 停止轮询
          store.commit('stopUpdating', false); // 恢复按钮状态，使用store
        }
      }, 5000); // 每2秒查询一次
    }
  } catch (error) {
    console.error('更新失败:', error);
    if (intervalId) clearInterval(intervalId);
    store.commit('stopUpdating', false); // 使用store
  }
};

const queryUpdateStatus = async () => {

  try {
    const response = await axiosInstance.get('/sim_card/update_status/');
    return response.data;

  } catch (error) {
    console.error(error);
  }
}
const sendBatchUpdateRequest = async () => {
  try {
    // 向后端发送请求进行批量更新
    const response = await axiosInstance.get('/sim_card/batch_update/');
    // 根据响应更新前端显示或通知用户更新请求成功
    console.log(response)
    ElMessage({
      message: '正在更新中',
      type: 'success',
    });
    fetchSimData(); // 重新获取数据以更新列表
  } catch (error) {
    console.error('Failed to batch update:', error);
    ElMessage({
      message: '批量更新失败',
      type: 'error',
    });
  }
};
const handleSizeChange = (newSize) => {
  pageSize.value = newSize;
  fetchSimData();
};

const handleCurrentChange = (newPage) => {
  currentPage.value = newPage;
  fetchSimData();
};
onMounted(() => {
  checkJumpData()
  fetchSimData();
});
watch(search, fetchSimData);
</script>

<style scoped>
.search-input {
  margin-bottom: 20px;
}

.upload-area,
.update-area,
.search-area {
  margin-bottom: 20px;
  /* 设置区块之间的固定距离 */
}
</style>
