import { getColor, seconds2HHmm, formatDateString } from '../utils/utils';
function initDayProbChart(canvas, width, height, dpr) {
    var theme = null;
    try{
        let info = wx.getAppBaseInfo();
        theme = info.theme || null;
    }catch(e) {}

    const chart = echarts.init(canvas, theme, {
        width: width,
        height: height,
        devicePixelRatio: dpr,
        renderer: 'svg',
    });
    prob_chart = chart;
    // 自动关闭tooltip
    let timer = null;
    chart.getZr().handler.on('mouseup', function(e){
        timer = setTimeout(()=>{
            chart.dispatchAction({
                type: 'hideTip'
            })
            chart.dispatchAction({
                type: 'updateAxisPointer',
                currTrigger: 'leave',
            })
        }, 4000);
    })
    chart.getZr().handler.on('mousedown', function(e){
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    })
    
    setDayProbChartOption();
    return chart;
}

export function setDayProbChartOption(prob_chart,prob_data) {
    if (!prob_chart || !prob_data) {
        return;
    }

    var data = [];

    if (prob_data && prob_data.segments && prob_data.segments.length>0) {
        var offset = prob_data.start + prob_data.prob_start;
        for (let i = 0; i<prob_data.segments.length; i++) {
            data.push([offset + i*30, prob_data.segments[i]<=0 ? null : prob_data.segments[i]]);
        }
    }

    var option = {
        backgroundColor: 'transparent',
        animationDuration: 500,
        title: {
          text: '入睡速度曲线',
          padding: [0, 0, 0, 32],
            textStyle: {
                fontWeight: 'normal',
                fontSize: 12,
            }
        },
        grid: {
          left: '2%',
          right: '7%',
          top: '16%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
            min: 'dataMin',
            max: 'dataMax',
            minInterval: 5*60,
            maxInterval: 5*60,
            axisLabel: {
                showMinLabel: true,
                showMaxLabel: true,
                formatter: function (val) {
                    var d = new Date(val*1000);
                    return d.getHours()+":"+d.getMinutes().toString().padStart(2, '0');
                }
            },
            splitLine: {
                lineStyle: { type: 'dashed' },
            },
        },
        yAxis: {
            type: 'value',
            min: 0,
            max: 1,
        },
        tooltip: {
            trigger: 'axis',
            confine: true,
            shadowBlur: 4,
            backgroundColor: getColor('background'),
            textStyle: {
                fontSize: 12,
                lineHeight: 9,
                textShadowBlur: 2,
                textShadowColor: 'transparent',
                color: getColor('text'),
            },
            formatter: function (params) {
                return params[0].marker 
                    + formatDateString(params[0].value[0]*1000, "HHmm")
                    + '  ' + (params[0].value[1]!=null ? Math.round(params[0].value[1]*100)/100 : L.nodata_abbr);
            },
        },
        series: [
          {
            type: 'line',
            symbol: 'none',
            data: data,
            itemStyle: { color: '#73c0de' },
            encode: {
                x: 0,
                y: 1,
                tooltip: [0, 1],
            },
            markLine: {
                silent: true,
                symbol: 'none',
                label: { 
                    show: true,
                    formatter: (params) => {
                        return params.name;
                    }
                },
                data: [{ name: '清醒', label: { position: 'insideStartBottom' }, lineStyle: { color: 'rgba(0,0,0,0)' }, yAxis: 1 }, { name: '睡着', label: { position: 'insideStartTop' }, lineStyle: { color: 'rgba(0,0,0,0)' }, yAxis: 0 }],
                lineStyle: { opacity: 1 }
            },
          }
        ]
      };

    prob_chart.setOption(option);
}
