<template>
  <el-container>
    <el-main>

      <div class="welcome-page">
        <el-card class="box-card">
          <template v-slot:header>
            <div class="clearfix">
              <span>欢迎页</span>
            </div>
          </template>
          <div v-if="user">
            <h1>欢迎, {{ user.username }}!</h1>
          </div>
          <div v-else>
            <h1>正在加载用户信息...</h1>
          </div>
        </el-card>
      </div>
      <!-- 显示异常事件聚合 -->
     <el-row :gutter="20" v-if="isAdmin">
       <el-col :span="12">
        <el-card class="event-list-card" v-if="isAdmin">
          <template v-slot:header>
            <div class="clearfix">
              <span>设备故障类型分布</span>
            </div>
          </template>
          <div>
            <span v-for="item in filteredFaultData" :key="item.name" @click="navigateToDevicePage(item.name)">
              <el-tag :style="{ color:'red',cursor: 'pointer' }">{{ item.name }} ({{ item.value }})</el-tag>
            </span>
          </div>
        </el-card>
        </el-col>
      <!--关键信息实时统计 -->

        <el-col :span="6" v-for="stat in stats" :key="stat.label" @click="navigateToDevicePage(stat.name)">
          <el-card>
            <div class="stat-item">
              <h3>{{ stat.value }}</h3>
              <p>{{ stat.label }}</p>
            </div>
          </el-card>
        </el-col>
      </el-row >
         <el-row :gutter="20" v-if="isAdmin">
        <el-col :span="24" >
          <el-card class="stat-card">

            <el-table :data="deviceList" border stripe :fit="true">
              <!-- 设备名称 -->
              <el-table-column prop="name" label="设备名称" width="80">
                <template #default="scope">
                  {{ scope.row.name }}
                </template>
              </el-table-column>

              <!-- 在线设备数量 -->
              <el-table-column prop="onlineDevices" label="在线设备" width="80">
                <template #default="scope">
                  {{ scope.row.onlineDevices }} 台
                </template>
              </el-table-column>

              <!-- CPU 信息 -->
              <el-table-column prop="cpu" label="CPU使用率" width="60">
                <template #default="scope">
                  {{ scope.row.cpu }}%
                </template>
              </el-table-column>

              <!-- 内存信息 -->
              <el-table-column prop="memory" label="内存使用量" width="60">
                <template #default="scope">
                  {{ scope.row.memory }}%
                </template>
              </el-table-column>

              <!-- 磁盘信息 -->
              <el-table-column prop="disk" label="磁盘使用率" width="60">
                <template #default="scope">
                  {{ scope.row.disk }}%
                </template>
              </el-table-column>

              <!-- 运行任务 -->
              <el-table-column prop="task_running" label="运行任务" width="60">
                <template #default="scope">
                  {{ scope.row.task_running }}
                </template>
              </el-table-column>

              <!-- 等待任务 -->
              <el-table-column prop="task_waiting" label="等待任务" width="60">
                <template #default="scope">
                  {{ scope.row.task_waiting }}
                </template>
              </el-table-column>

              <!-- WDB 发送状态 -->
              <el-table-column prop="wdb_send" label="WDB发送" width="80">
                <template #default="scope">
                  <el-tag :type="scope.row.wdb_send === '正常' ? 'success' : 'danger'">
                    {{ scope.row.wdb_send }}
                  </el-tag>
                </template>
              </el-table-column>

              <!-- WDB 接收状态 -->
              <el-table-column prop="wdb_recv" label="WDB接收" width="80">
                <template #default="scope">
                  <el-tag :type="scope.row.wdb_recv === '正常' ? 'success' : 'danger'">
                    {{ scope.row.wdb_recv }}
                  </el-tag>
                </template>
              </el-table-column>

              <!-- 设备同步状态 -->
              <el-table-column prop="deviceSync" label="设备同步" width="80">
                <template #default="scope">
                  <el-tag :type="scope.row.deviceSync === '正常' ? 'success' : 'danger'">
                    {{ scope.row.deviceSync }}
                  </el-tag>
                </template>
              </el-table-column>

              <!-- Kafka 接收状态 -->
              <el-table-column prop="kafkaReceive" label="Kafka接收" width="80">
                 <template #default="scope">
                <el-tag :type="scope.row.kafkaReceive === '正常' ? 'success' : 'danger'">
                    {{ scope.row.kafkaReceive }}
                  </el-tag>
                 </template>
              </el-table-column>

              <!-- Kafka Bin2Packet 状态 -->
              <el-table-column prop="kafkaBin2Packet" label="Binpack发送" width="80">
                 <template #default="scope">
                <el-tag :type="scope.row.kafkaBin2Packet === '正常' ? 'success' : 'danger'">
                    {{ scope.row.kafkaBin2Packet }}
                  </el-tag>
                 </template>
              </el-table-column>

              <el-table-column prop="kafkaful" label="kafka阻塞" width="80">
                 <template #default="scope">
                <el-tag :type="scope.row.kafkaful === '正常' ? 'success' : 'danger'">
                    {{ scope.row.kafkaful }}
                  </el-tag>
                 </template>
              </el-table-column>
            </el-table>
          </el-card>

        </el-col>
        <!-- 系统状态信息卡片 -->
      </el-row>
      <el-row :gutter="20" v-if="isAdmin" style="margin-top: 20px">
        <el-col :span="6" @click="navigateToDataPage('data')">
          <el-card class="stat-card">
            <div class="stat-item count">
              <div class="column">
                <div v-if="counts && counts['首次报告']">
                  <h5>首次报告</h5>
                  <div>
                    <p>总体数据完整性：{{ counts['首次报告']['start_per_str'] || 'N/A' }}</p>
                    <p>100%: {{ counts['首次报告']['100'] || 'N/A' }}</p>
                    <p>99%-100%: {{ counts['首次报告']['99'] || 'N/A' }}</p>
                    <p>90%-99%: {{ counts['首次报告']['90'] || 'N/A' }}</p>
                    <p>0-90%: {{ counts['首次报告']['89'] || 'N/A' }}</p>
                    <p>总结收数据: {{ counts['首次报告']['start_count'] || 'N/A' }}秒</p>
                    <p>数据缺失: {{ counts['首次报告']['start_miss_count'] || 'N/A' }}秒</p>
                  </div>
                </div>
              </div>

            </div>
          </el-card>
        </el-col>
        <el-col :span="6" @click="navigateToDataPage('data')">
          <el-card class="stat-card">
            <div class="stat-item count">

              <div class="column">
                <div v-if="counts && counts['末次报告']">
                  <h5>末次报告</h5>
                  <p>数据完整性：{{ counts['末次报告']['end_per_str'] || 'N/A' }}</p>
                  <p>100%: {{ counts['末次报告']['100'] || 'N/A' }}</p>
                  <p>99%-100%: {{ counts['末次报告']['99'] || 'N/A' }}</p>
                  <p>90%-99%:: {{ counts['末次报告']['90'] || 'N/A' }}</p>
                  <p>0-90%: {{ counts['末次报告']['89'] || 'N/A' }}</p>
                  <p>总结收数据: {{ counts['末次报告']['end_count'] || 'N/A' }}秒</p>
                  <p>数据缺失: {{ counts['末次报告']['end_miss_count'] || 'N/A' }}秒</p>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6" @click="navigateToDataPage('report')">
          <el-card class="stat-card">
            <div class="stat-item count">
              <div class="column">
                <div v-if="report_per_counts && report_per_counts['首次报告']">
                  <h5>首次报告性能</h5>
                  <div>
                    <p>平均耗时：{{ report_per_counts['首次报告']['avg'] || 'N/A' }}秒</p>
                    <p>小于10秒: {{ report_per_counts['首次报告']['小于10秒'] || 'N/A' }}份</p>
                    <p>10-20秒: {{ report_per_counts['首次报告']['10-20秒'] || 'N/A' }}份</p>
                    <p>20-30秒: {{ report_per_counts['首次报告']['20-30秒'] || 'N/A' }}份</p>
                    <p>大于30秒: {{ report_per_counts['首次报告']['大于30秒'] || 'N/A' }}份</p>
                  </div>
                </div>
              </div>

            </div>
          </el-card>
        </el-col>
        <el-col :span="6" @click="navigateToDataPage('report')">
          <el-card class="stat-card">
            <div class="stat-item count">

              <div class="column">
                <div v-if="report_per_counts && report_per_counts['末次报告']">
                  <h5>末次报告性能</h5>
                  <p>平均耗时：{{ report_per_counts['末次报告']['avg'] || 'N/A' }}秒</p>
                  <p>小于10秒: {{ report_per_counts['末次报告']['小于10秒'] || 'N/A' }}份</p>
                  <p>10-20秒: {{ report_per_counts['末次报告']['10-20秒'] || 'N/A' }}份</p>
                  <p>20-30秒: {{ report_per_counts['末次报告']['20-30秒'] || 'N/A' }}份</p>
                  <p>大于30秒: {{ report_per_counts['末次报告']['大于30秒'] || 'N/A' }}份</p>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>

      </el-row>
      <!-- 历史曲线图 -->
      <el-card class="box-card history-chart-card">
        <template v-slot:header>
          <div class="clearfix">
            <span>报告数量与设备信息统计-30日</span>
          </div>
          <el-row :gutter="20">

            <el-col :span="12">
              <BarChartBase :chartData="reportCount" chartId="chart3" :chartName="reportCountChartName"
                            v-if="reportCount"/>
            </el-col>

            <el-col :span="12">
              <BarChartBase :chartData="deviceCount" chartId="chart2" :chartName="deviceCountChartName"
                            v-if="deviceCount"/>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12" v-if="isAdmin">
              <BarChartBase :chartData="deviceDataSize" chartId="chart1" :chartName="deviceDataSizeChartName"
                            v-if="deviceDataSize"/>
            </el-col>
            <el-col :span="12" v-if="isAdmin">
              <BarChartBase :chartData="visitCount" chartId="chart4" :chartName="userVisitChartName"
                            v-if="visitCount"/>
            </el-col>
          </el-row>

        </template>

      </el-card>

    </el-main>

  </el-container>

</template>

<script setup>

import { onMounted, computed, ref, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import BarChartBase from './BarChartBase.vue';
import { useJumpData } from '@/plugins/checkJumpData';
import { createAxiosInstance } from '@/plugins/axios';
import { eventBus, sendCmd } from '@/plugins/websocket';
import { VueDraggableNext as draggable } from 'vue-draggable-next';
const widgets = ref([
  { id: 1, content: '模块 1', style: { width: '300px', height: '200px' } },
  { id: 2, content: '模块 2', style: { width: '300px', height: '200px' } },
  { id: 3, content: '模块 3', style: { width: '300px', height: '200px' } }
]);

const { checkJumpData } = useJumpData();
const router = useRouter();
const store = useStore();
const user = computed(() => store.state.user);
const faultData = ref([]);
const deviceDataSize = ref(null);
const deviceCount = ref(null);
const reportCount = ref(null);
const visitCount = ref(null);
const isAdmin = computed(() => user.value && user.value.role === '管理')
const counts = ref({})
const report_per_counts = ref({})
// const systemDetails = computed(() => {
//   const info = {}
//   const now = new Date().getTime()
//   Object.assign(info, store.state.systemState);
//   if (store.state.net_err_time + 10000 > now)
//     info["网络异常"] = store.state.net_error
//   return info
// });

const systemdevices = ref({});
const deviceList = computed(() => {
  return Object.entries(systemdevices.value).map(([name, info]) => ({
    name,
    ...info,
  }));
});

const deviceDataSizeChartName = {
  yAxisName: '数据总量（MB)',
  seriesName: '数据总量'
}
const deviceCountChartName = {
  yAxisName: '设备数量（台）',
  seriesName: '设备数量'
}
const reportCountChartName = {
  yAxisName: '报告数量（份）',
  seriesName: '报告数量'
}
const userVisitChartName = {
  yAxisName: '查看报告（人）',
  seriesName: '总人数'
}
// 设备在线数量以及每日上传数据统计
const stats = ref([
  // {label: '同时在线设备数', value: 0, name: "在线"},
  { label: '生成报告数量', value: 0, name: '报告成功' },
  { label: 'VIP', value: 0, name: 'deviceinfo.level=VIP' }
])

function formatToTwoDecimalPlaces (num) {
  return num.toFixed(2);
}

function on_statistics_device (data) {
  const response = data.message.data;
  reportCount.value = response[2];
  deviceDataSize.value = response[0]
  deviceCount.value = response[1];
  visitCount.value = response[3];
}

// 通用的异步函数，根据传入的过滤条件获取相应数据

async function fetchData () {
  const filters = ['sd卡故障', 'SIM卡已停用', 'SIM卡已过期', '基线异常', '整日连接异常', '报告失败', '打鼾指数通道异常', 'imu_head异常', 'fsm异常', 'VIP设备异常', '算法模块异常'];
  sendCmd('fetchlabel', { fault: filters, normal: ['报告成功', 'deviceinfo.level=VIP'] }).then(() => {
    console.log('fetchlabel from data');
  });
}

const filteredFaultData = computed(() => {
  return faultData.value.filter(item => item.value !== 0);
});

// 跳转到设备详情页

async function navigateToDevicePage (filter) {
  let tag = 'tag'
  if (filter === 'deviceinfo.level=VIP') {
    tag = 'selected'
  }
  store.commit('setJumpData', { type: 'device', value: filter, filter_type: tag, path: '/device-management' }); //
  router.push('/device-management');
}

async function navigateToDataPage (type) {
  if (type === 'data') {
    router.push('/data-detail');
  } else if (type === 'sys') {
    router.push('/sys-detail');
  } else {
    router.push('/report-performance');
  }
}

let intervalId;
const featchCount = async () => {
  try {
    const axiosInstance = createAxiosInstance('计算服务器1');

    const response = await axiosInstance.get('/data_detail/list/', {
      params: {
        data_type: 'count'
      }
    });
    counts.value = response.data;
  } catch (error) {
    console.error('Failed to fetch devices data:', error);
  }
};
const featchRepoetPerConut = async () => {
  try {
    const axiosInstance = createAxiosInstance('计算服务器1');
    const response = await axiosInstance.get('/report_performance/list/', {
      params: {
        data_type: 'count'
      }
    });

    report_per_counts.value = response.data;
  } catch (error) {
    console.error('Failed to fetch devices data:', error);
  }
};

function on_sysinfo (data) {
  const response = data.message.data;
  const serverName = data.from;
  systemdevices.value[serverName] = response;
}

function on_fetchlabel (data) {
  const responses = data.message.data;
  try {
    faultData.value = responses.fault
    stats.value[0].value = responses.normal[0].value;
    stats.value[1].value = responses.normal[1].value
  } catch (error) {
    console.error('Failed to fetch data:', error);
  }
}

onMounted(() => {
  eventBus.on('statistics_device', on_statistics_device);
  eventBus.on('sysinfo', on_sysinfo)
  eventBus.on('fetchlabel', on_fetchlabel)
  checkJumpData();

  fetchData();
  sendCmd('statistics_device').then(() => {
    console.log('statistics_device from data');
  });
  sendCmd('sysinfo').then(() => {
    console.log('sysinfo from data');
  });
  sendCmd('currentuser', {}, '计算服务器1').then(() => {
    console.log('currentuser from gpu1');
  });
  sendCmd('currentuser', {}, '计算服务器2').then(() => {
    console.log('currentuser from data');
  });

  featchCount()
  featchRepoetPerConut()
  intervalId = setInterval(fetchData, 2000); // 每2秒获取一次数据
});

onUnmounted(() => {
  clearInterval(intervalId); // 清理定时任务
});
</script>
<style scoped>

.box-card {
  margin-bottom: 20px;
  transition: 0.3s ease box-shadow;
}

.stat-item {
  text-align: center;
}

.stat-item h3 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.stat-item p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.event-list-card {
  margin-bottom: 20px;
}

.history-chart-card {
  margin-top: 30px;
}

</style>
